import React from "react";
import { Theme, createStyles, withTheme, withStyles, WithStyles, Button, Dialog, DialogContent, Grid, List, ListItem, ListItemText, DialogActions, ListItemAvatar, CardHeader, CardContent, Card, FormGroup, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import httpClient from "../../../util/HttpClient";

import { TransitionProps } from "@material-ui/core/transitions/transition";
import Slide from '@material-ui/core/Slide';
import { openNotifier } from "../../../util/ui/dialog/notifier/Notifier";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";

import { format, subMonths } from 'date-fns';
import { FORMAPI_BASE } from "../BaseUtil";
import { ClientAppConfig } from "../../../util/AppUtil";
import _ from "lodash";
import { Check } from "@material-ui/icons";

interface BankConnectSite {

    bankName: string;
    providerId: string;
    providerAccountId: string;
    status: string;
    statusCode: string

}

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    interstitialContainer: {
        justifyContent: 'center',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogContent: {
        [theme.breakpoints.down('sm')]: {
            width: theme.breakpoints.values.sm
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.breakpoints.values.xs
        },
        [theme.breakpoints.down('md')]: {
            width: theme.breakpoints.values.md
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.breakpoints.values.lg
        },
    }
});

interface BankConnectStartProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    formToken: string;
    clientAppConfig: ClientAppConfig;
    yodleeToken: string;
    yodleeUrl: string;

}

class BankConnectStartState {
    errorFields: Map<string, ErrorInfo> = new Map();

    agreed: boolean = false;
    linkingInitiated: boolean = false;
    bankDialogOpen: boolean = false;
    fullScreen: boolean = false;
    agreements: {
        [s: number]: boolean
    } = {};

}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class BankConnectStart extends React.Component<BankConnectStartProps, BankConnectStartState> {

    constructor(props: BankConnectStartProps) {
        super(props);

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.initiateLinkClick = this.initiateLinkClick.bind(this);
        this.handleAgreementChange = this.handleAgreementChange.bind(this);


        this.handleBankDialogClose = this.handleBankDialogClose.bind(this);
        this.handleBankDialogEnter = this.handleBankDialogEnter.bind(this);

        this.onYodleeExit = this.onYodleeExit.bind(this);
        this.onYodleeSuccess = this.onYodleeSuccess.bind(this);
        this.onYodleeEvent = this.onYodleeEvent.bind(this);
        this.onYodleeError = this.onYodleeError.bind(this);

        let state = new BankConnectStartState();
        for (let i in this.props.clientAppConfig.esignConditions) {
            state.agreements[i] = false;
        }
        this.state = state;

    }
    handleAgreementChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let agreements = _.clone(this.state.agreements);
        agreements[parseInt(e.currentTarget.getAttribute('data-agreement-index') || '0')] = e.currentTarget.checked;
        this.setState({
            agreements: agreements
        })
    }

    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        this.props.onCompleteForm();

    }

    initiateLinkClick() {

        this.setState({

            bankDialogOpen: true
        });
    }


    handleBankDialogClose() {
        this.setState({
            bankDialogOpen: false
        });
    }

    saveBankConnectSite(site: BankConnectSite) {

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/bank-connect', site)
            .then((r) => {

                this.props.onCompleteForm();
            }).finally(() => {
                openLoader(false);
            });

    }


    onYodleeExit(data: {
        action: string,
        sites: BankConnectSite[]
    }): void {
        console.log('onYodleeExit');
        console.log(data);
        this.setState({
            bankDialogOpen: false
        });

        if (typeof data.sites[0] === 'undefined') {
            openNotifier("Error! Please reload the page and try again");
            return;
        }

        let site = data.sites[0];

        this.saveBankConnectSite(site);

        if (site.status === 'FAILED') {
            openNotifier("Error! Please reload the page and try again");
            return;
        }

        openNotifier("Sucessfully Connected!");
    }

    onYodleeSuccess(data: any): void {
        console.log('onYodleeSuccess');
        console.log(data);

    }


    onYodleeError(data: any): void {
        console.log('onYodleeError');
        console.log(data);
    }


    onYodleeEvent(data: any): void {
        console.log('onYodleeEvent');
        console.log(data);
        if (typeof data.containerFinapp !== 'undefined') {
            let iframes = window.document.getElementsByTagName('iframe');
            if (typeof iframes[0] !== 'undefined') {
                iframes[0].style.minHeight = '1000px';
            }
            console.log(iframes);
        }
    }

    handleBankDialogEnter() {
        this.setState({
            linkingInitiated: true,
        });
        let now = new Date();
        let fromDate = format(subMonths(now, 3), 'yyyy-MM-dd');
        let fromDate12 = format(subMonths(now, 3), 'yyyy-MM-dd');
        let toDate = format(new Date(), 'yyyy-MM-dd');
        console.log({
            fastLinkURL: this.props.yodleeUrl,
            jwtToken: this.props.yodleeToken,
            params: {

                dataset: [
                    {
                        'name': 'BASIC_AGG_DATA',
                        'attribute': [
                            { 'name': 'ACCOUNT_DETAILS' },
                            { 'name': 'TRANSACTIONS', 'fromDate': fromDate12, 'toDate': toDate },
                            { 'name': 'BASIC_ACCOUNT_INFO' },
                            { 'name': 'HOLDINGS' }
                        ]
                    },

                    {
                        'name': 'DOCUMENT',
                        'attribute': [
                            {
                                'name': 'STATEMENTS',
                                'fromDate': fromDate,
                                'toDate': toDate
                            }
                        ]
                    }
                ]

            }
        });
        window.fastlink.open({
            fastLinkURL: this.props.yodleeUrl,
            jwtToken: this.props.yodleeToken,
            params: {

                dataset: [
                    {
                        'name': 'BASIC_AGG_DATA',
                        'attribute': [
                            { 'name': 'ACCOUNT_DETAILS' },
                            { 'name': 'TRANSACTIONS', 'fromDate': fromDate12, 'toDate': toDate },
                            { 'name': 'BASIC_ACCOUNT_INFO' },
                            { 'name': 'HOLDINGS' }
                        ]
                    },

                    {
                        'name': 'DOCUMENT',
                        'attribute': [
                            {
                                'name': 'STATEMENTS',
                                'fromDate': fromDate,
                                'toDate': toDate
                            }
                        ]
                    }
                ]

            },
            onSuccess: this.onYodleeSuccess,
            onError: this.onYodleeError,
            onExit: this.onYodleeExit,
            onEvent: this.onYodleeEvent
        }, 'fastlink_container');

    }



    getMainNav(): JSX.Element {

        return <Card elevation={0}>
            <CardContent>
                <Grid container spacing={2} >


                    <Grid item xs={12}>
                        <Button variant='contained' color='primary' onClick={this.handleContinueClick} fullWidth>
                            Continue
                    </Button>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>;
    }

    getBankConnectRender() {


        let allowedSubmission = true;
        for (var i in this.props.clientAppConfig.esignConditions) {
            if (!this.state.agreements[i]) {
                allowedSubmission = false;
                break;
            }
        }


        return <Grid container spacing={2} >
            <Grid item sm={12}>
                <Card elevation={0} style={{

                    paddingBottom: '0'
                }}>
                    <CardHeader title="Setting up Read-Only Bank Account Link"

                        subheader=
                        {<React.Fragment>
                            Securely Grant Bank Account Access. {this.props.clientAppConfig.companyName} will be granted access to:
                        </React.Fragment>
                        }
                        style={{
                            paddingTop: '0',
                            paddingBottom: '0'
                        }}

                    >
                    </CardHeader>
                    <CardContent>

                        <Grid container spacing={2} className={this.props.classes.interstitialContainer}>

                            <Grid item xs={12}>

                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Check />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            Verify your Bank Account(s)
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Check />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            Pull Bank Statements
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Check />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            Monitor Daily Bank Account Transaction Activity
                                        </ListItemText>
                                    </ListItem>
                                </List>

                            </Grid>

                        </Grid>


                        <Grid container spacing={2} className={this.props.classes.interstitialContainer}>
                            {this.props.clientAppConfig.esignConditions.length > 0 &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        {this.props.clientAppConfig.esignConditions.map((s, i) => {
                                            return <React.Fragment key={i}>
                                                <FormGroup row>

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                inputProps={{
                                                                    'data-permission-to-contact-index': i
                                                                } as any}
                                                                checked={this.state.agreements[i]}
                                                                onChange={this.handleAgreementChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<Typography variant='body2'>
                                                            {s}
                                                        </Typography>}
                                                    />

                                                </FormGroup>
                                            </React.Fragment>;
                                        })}
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={12}  >
                                <Button fullWidth color='primary' variant='contained' onClick={this.initiateLinkClick} disabled={!allowedSubmission}>
                                    Continue
                                </Button>
                            </Grid>

                        </Grid>

                    </CardContent>

                </Card>
            </Grid>
        </Grid>;
    }

    render() {


        return <React.Fragment>
            {this.getBankConnectRender()}

            <Dialog
                open={this.state.bankDialogOpen} onEntered={this.handleBankDialogEnter} onClose={this.handleBankDialogClose} TransitionComponent={Transition}
                fullScreen>
                <DialogActions>
                    <Button autoFocus onClick={this.handleBankDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
                <DialogContent >
                    <div id='fastlink_container' style={{ height: '100%', minHeight: '550px', overflow: 'hidden' }}>

                    </div>
                </DialogContent>
            </Dialog>

        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(BankConnectStart));