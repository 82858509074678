import React from "react";
import { AppBar, Toolbar, Typography, withStyles, Theme, createStyles, WithStyles, Hidden, Link } from "@material-ui/core";

import { Person, Phone, Email } from "@material-ui/icons";
import { ClientAppConfig } from "../util/AppUtil";

const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'static',
        alignItems: 'center',
        // [theme.breakpoints.up('md')]: {
        //     padding: theme.spacing(2)
        // }
    },
    subBar: {
        position: 'static',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            position: 'static',
            // top: 'auto',
            // bottom: 0,
            alignItems: 'center',
        }
    },
    subToolbar: {
        minHeight: '45px'
    }
});


interface HeaderProps extends WithStyles<typeof styles> {
    clientAppConfig: ClientAppConfig;
}

class Header extends React.Component<HeaderProps> {

    render() {

        return (
            <React.Fragment>
                {this.props.clientAppConfig.formType === 'ppp' ?
                    <React.Fragment>
                        <AppBar className={this.props.classes.appBar} >
                            <Toolbar >

                                <Typography variant="h4" color="inherit" >
                                    CARES Act Paycheck Protection Program
                                </Typography>

                            </Toolbar>
                        </AppBar>
                        <AppBar color="default" className={this.props.classes.subBar} elevation={1}>
                            <Toolbar className={this.props.classes.subToolbar}>
                                <Typography color="inherit" variant="subtitle1">
                                    {this.props.clientAppConfig.companyName}
                                </Typography>
                            </Toolbar>
                            <Toolbar className={this.props.classes.subToolbar}>
                                <Person fontSize='medium'></Person>&nbsp;
                                <Typography>
                                    {this.props.clientAppConfig.repName}
                                </Typography>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Phone fontSize='medium'></Phone>&nbsp;
                                <Typography>
                                    <Link href={"tel:" + this.props.clientAppConfig.repPhone} >
                                        {this.props.clientAppConfig.repPhone}
                                    </Link>
                                </Typography>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Email fontSize='medium'></Email>&nbsp;
                            <Typography>{this.props.clientAppConfig.repEmail}</Typography>
                            </Toolbar>
                        </AppBar>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <AppBar className={this.props.classes.appBar} >
                            <Toolbar >

                                <Typography variant="h4" color="inherit" style={{ textAlign: 'center' }}>
                                    {this.props.clientAppConfig.companyName}
                                </Typography>

                            </Toolbar>
                        </AppBar>
                        <AppBar color="default" className={this.props.classes.subBar} elevation={1}>
                            <Toolbar className={this.props.classes.subToolbar}>
                                <Person fontSize='medium'></Person>&nbsp;
                                <Typography>
                                    {this.props.clientAppConfig.repName}
                                </Typography>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Phone fontSize='medium'></Phone>&nbsp;

                                <Typography>
                                    <Link href={"tel:" + this.props.clientAppConfig.repPhone} color="inherit">
                                        {this.props.clientAppConfig.repPhone}
                                    </Link>
                                </Typography>
                                <Hidden xsDown>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Email fontSize='medium'></Email>&nbsp;
                                    <Typography>
                                        <Link href={"mailto:" + this.props.clientAppConfig.repEmail} color="inherit">
                                            {this.props.clientAppConfig.repEmail}
                                        </Link>
                                    </Typography>
                                </Hidden>
                            </Toolbar>
                        </AppBar>
                    </React.Fragment>
                }

            </React.Fragment>

        )
    }
}

export default withStyles(styles, { withTheme: true })(Header);