import Axios, { AxiosResponse } from "axios";
import { openNotifier } from "./ui/dialog/notifier/Notifier";
import { url_identifier } from "./FormApiUtils";

export interface HttpClientResponse extends AxiosResponse {

}

const httpClient = Axios.create({
    headers: { 'X-Rep': url_identifier }
});

httpClient.interceptors.response.use(success => {
    return success
}, error => {
    if (typeof error.response === 'undefined') {
        openNotifier("Error");
    } else if (typeof error.response.data['message'] !== 'undefined') {
        if (error.response.data['message'] === 'Invalid Form') {
            window.location.replace('/');
        } else {
            openNotifier(error.response.data['message']);
        }
    }
    return Promise.reject({ ...error });
})

export default httpClient;