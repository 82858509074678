import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, withTheme, withStyles, WithStyles } from '@material-ui/core/styles';


const styles = (theme: Theme) => createStyles({

    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',

    }
});


export interface LoaderProps extends WithStyles<typeof styles> {
    idPrefix: string;
}

class LoaderState {
    open: boolean = false;
}

let openLoaderFn: (open: boolean) => void;

class Loader extends React.Component<LoaderProps, LoaderState> {

    constructor(props: LoaderProps) {
        super(props);
        this.state = new LoaderState();
        this.openLoader = this.openLoader.bind(this);
    }

    componentDidMount() {
        openLoaderFn = this.openLoader;
    }

    openLoader(open: boolean): void {
        this.setState({
            open: open
        });
    }


    static defaultProps = {

        idPrefix: "id-" + Math.round(Math.random() * 10000),

    };

    render() {

        return (
            <div>
                <Backdrop
                    className={this.props.classes.backdrop}
                    open={this.state.open} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export function openLoader(open: boolean): void {
    openLoaderFn(open);
};


export default withTheme(withStyles(styles)(Loader));