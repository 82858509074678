import React from "react";
import { Stepper, Step, StepLabel, Theme, createStyles, withStyles, WithStyles, withTheme, LinearProgress, Hidden, Avatar } from "@material-ui/core";

import BusinessInfoForm from "./components/BusinessInfoForm";

import OwnerInfoForm from "./components/OwnerInfoForm";
import { InputData } from "../../util/ui/form/InputData";
import httpClient from "../../util/HttpClient";
import BankInfoForm from "./components/BankInfoForm";
import EsignForm from "./components/EsignForm";
import { openLoader } from "../../util/ui/dialog/loader/Loader";
import { FORMAPI_BASE } from "./BaseUtil";
import { ClientAppConfig } from "../../util/AppUtil";
import EndForm from "./components/EndForm";

const styles = (theme: Theme) => createStyles({

    stepper: {
        padding: theme.spacing(2, 0, 3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    square: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        width: '100%'
    },

});

interface ERCFormProps extends WithStyles<typeof styles> {
    formToken: string;
    clientAppConfig: ClientAppConfig;
    loading: boolean;
    onFormTokenUpdate(formToken: string): void;
    onValidTokens(formToken: string): void;
    onInvalidTokens(): void;
}

class ERCFormState {
    activeStep: number = -1;
    amountRequested: InputData = new InputData();
    useOfProceeds: InputData = new InputData();
    ssn: string = '';
    ownerIndex: number = -1;
    bankFormStatus: "complete" | "incomplete" = 'incomplete';
    fileToken: string = '';
}

class FormStep {
    label: string = '';
    element: JSX.Element = <React.Fragment></React.Fragment>;

    constructor(label: string, element: JSX.Element) {
        this.label = label;
        this.element = element;
    }
}

enum ActiveStep {
    Initial = -1, Owner = 0, Business = 1, Esign = 2, Bank = 3, Complete = 4
}

class ERCForm extends React.Component<ERCFormProps, ERCFormState> {


    constructor(props: ERCFormProps) {
        super(props);

        this.handleBusinessCompleteForm = this.handleBusinessCompleteForm.bind(this);
        this.handleOwnerCompleteForm = this.handleOwnerCompleteForm.bind(this);
        this.handleAmountRequestedChange = this.handleAmountRequestedChange.bind(this);
        this.handleFormTokenUpdate = this.handleFormTokenUpdate.bind(this);
        this.handleBusinessInfoGoBack = this.handleBusinessInfoGoBack.bind(this);
        this.handleBankCompleteForm = this.handleBankCompleteForm.bind(this);
        this.handleBankInfoGoBack = this.handleBankInfoGoBack.bind(this);
        this.handleBusinessEdit = this.handleBusinessEdit.bind(this);
        this.handleBankInfoEdit = this.handleBankInfoEdit.bind(this);
        this.handleOwnerEdit = this.handleOwnerEdit.bind(this);
        this.handleEsignCompleteForm = this.handleEsignCompleteForm.bind(this);
        this.handleUseOfProceedsChange = this.handleUseOfProceedsChange.bind(this);
        this.skipDocs = this.skipDocs.bind(this);

        this.state = new ERCFormState();
    }

    componentDidMount() {

        if (this.props.formToken !== 'new') {

            window.setTimeout(()=>{

                httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep').then((r) => {
                    this.setState({
                        activeStep: r.data.data.s,
                        ownerIndex: r.data.data.i,
                        bankFormStatus: r.data.data.b,
                        fileToken: r.data.data.f
                    });

                    this.props.onValidTokens(this.props.formToken);

                }).catch(() => {

                    this.props.onInvalidTokens();

                });
            },10)

        } else {
            this.setState({
                activeStep: this.props.loading ? ActiveStep.Initial : ActiveStep.Owner,
                ownerIndex: 0
            });
        }
    }

    handleOwnerCompleteForm() {
        if (this.state.ownerIndex === 0) {
            this.changeStep(ActiveStep.Business);
        } else {
            // if (!this.skipDocs()) {
            //     this.changeStep(ActiveStep.Bank);
            // } else {
            this.changeStep(ActiveStep.Esign);
            // }
        }

    }

    handleBusinessCompleteForm() {
        this.changeStep(ActiveStep.Esign);
    }

    handleAmountRequestedChange(amountRequested: InputData) {
        this.setState({
            amountRequested: amountRequested
        });
    }

    handleUseOfProceedsChange(useOfProceeds: InputData) {
        this.setState({
            useOfProceeds: useOfProceeds
        });
    }

    handleFormTokenUpdate(token: string) {
        this.props.onFormTokenUpdate(token);
    }

    handleBusinessInfoGoBack(): void {

        this.changeStep(ActiveStep.Owner);
    }

    handleBankCompleteForm(): void {
        this.changeStep(ActiveStep.Complete);
    }

    handleEsignCompleteForm(): void {
        if (this.skipDocs()) {
            this.changeStep(ActiveStep.Complete);
        } else {
            this.changeStep(ActiveStep.Bank);
        }

    }

    handleBankInfoGoBack(): void {
        // if (this.state.ownerIndex === 0) {
        //     this.changeStep(ActiveStep.Business);
        // } else {
        //     this.changeStep(ActiveStep.Owner);
        // }
    }


    changeStep(step: number) {
        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep?activeStep=' + step);

        this.setState({
            activeStep: step
        });
    }

    handleBusinessEdit() {
        this.changeStep(ActiveStep.Business);
    }

    handleBankInfoEdit() {
        this.changeStep(ActiveStep.Bank);
    }

    handleOwnerEdit() {

        openLoader(true);

        this.changeStep(ActiveStep.Owner);

    }

    skipDocs(): boolean {
        return (this.state.ownerIndex > 0 && this.state.bankFormStatus === 'complete');
    }

    render() {

        if (this.state.ownerIndex === -1) {
            return <LinearProgress></LinearProgress>;
        }

        let steps: FormStep[] = [];

        steps.push(new FormStep("Owners", <OwnerInfoForm
            key={steps.length}
            onCompleteForm={this.handleOwnerCompleteForm}
            onAmountRequestedChange={this.handleAmountRequestedChange}
            onUseOfProceedsChange={this.handleUseOfProceedsChange}
            amountRequested={this.state.amountRequested}
            formToken={this.props.formToken}
            onFormTokenUpdate={this.handleFormTokenUpdate}
            activeOwnerIndex={this.state.ownerIndex}
            clientAppConfig={this.props.clientAppConfig}
            useOfProceeds={this.state.useOfProceeds}
        ></OwnerInfoForm>));

        let bslabel = 'Business';
        if (this.state.ownerIndex > 0) {
            bslabel = 'Business (Completed)';
        }

        steps.push(new FormStep(bslabel, <BusinessInfoForm
            key={steps.length}
            onCompleteForm={this.handleBusinessCompleteForm}
            formToken={this.props.formToken}
            onGoBack={this.handleBusinessInfoGoBack}
        ></BusinessInfoForm>));

        steps.push(new FormStep("E-Sign", <EsignForm
            key={steps.length}
            formToken={this.props.formToken}
            onCompleteForm={this.handleEsignCompleteForm}
            onEditBusiness={this.handleBusinessEdit}
            onEditDocuments={this.handleBankInfoEdit}
            onEditOwner={this.handleOwnerEdit}
            ownerIndex={this.state.ownerIndex}
            urlToken={this.props.clientAppConfig.repURL}
            clientAppConfig={this.props.clientAppConfig}
        ></EsignForm>));

        let docsLabel = 'Documents';
        if (this.skipDocs()) {
            docsLabel = "Documents (Completed)";
        }

        steps.push(new FormStep(docsLabel, <BankInfoForm
            key={steps.length}
            formToken={this.props.formToken}
            onCompleteForm={this.handleBankCompleteForm}
            bankConnectEnabled={this.props.clientAppConfig.features.bankConnect}
            ownerIndex={this.state.ownerIndex}
            urlToken={this.props.clientAppConfig.repURL}
            fileToken={this.state.fileToken}
        ></BankInfoForm>));

        if (this.state.activeStep === ActiveStep.Initial) {
            return <React.Fragment>
                <LinearProgress />
            </React.Fragment>
        }

        return <React.Fragment>


            <React.Fragment>
                {this.state.activeStep === steps.length ? (
                    <React.Fragment>
                        <EndForm
                            formToken={this.props.formToken}
                            clientAppConfig={this.props.clientAppConfig}
                            ownerIndex={this.state.ownerIndex}
                            urlToken={this.props.clientAppConfig.repURL}
                            fileToken={this.state.fileToken}>
                        </EndForm>
                    </React.Fragment>
                ) : (

                        <React.Fragment>
                            <Stepper className={this.props.classes.stepper} activeStep={this.state.activeStep} orientation="horizontal">
                                {steps.map(s => {

                                    return (
                                        <Step key={s.label}>
                                            <StepLabel>
                                                <Hidden xsDown={true}>
                                                    {s.label}
                                                </Hidden>
                                            </StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                            <Hidden smUp>
                                <Avatar variant="rounded" className={this.props.classes.square}>
                                    {this.state.activeStep === 0 && this.props.formToken === 'new' ?
                                        "Get Started"
                                        :
                                        steps[this.state.activeStep].label
                                    }
                                </Avatar>
                                <br />
                            </Hidden>
                            {steps[this.state.activeStep].element}
                        </React.Fragment>
                    )}
            </React.Fragment>
        </React.Fragment >

    }
}

export default withTheme(withStyles(styles)(ERCForm));