import React from "react";
import { InputLabelProps, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../FormInputProperties";
import FeinInput from "./FeinInput";


interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: string;
    onInputChange(inputInfo: InputInfo): void;
    recommendationName: string;
    recommendationValue: string;
    formOrder?: number;
}

class BasicInputState {
    copyChecked: boolean = false;
    value: string = '';
}

export class InputInfo {
    name: string = '';
    value: string = '';
    label: string = '';
}

export class CopyRecommendedInputTaxId extends React.Component<BasicInputProps, BasicInputState> {

    protected inputInfo: InputInfo = new InputInfo();

    constructor(props: BasicInputProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleCopyCheckChange = this.handleCopyCheckChange.bind(this);
        let state = new BasicInputState();
        state.copyChecked = this.props.recommendationValue === this.props.value;
        this.state = state;
    }

    handleChange(inputInfo: InputInfo) {

        this.props.onInputChange(inputInfo);

    };

    handleCopyCheckChange(event: React.ChangeEvent<HTMLInputElement>) {

        if (event.currentTarget.checked) {

            this.inputInfo.name = this.props.inputProps.name;
            this.inputInfo.value = this.props.recommendationValue;
            this.inputInfo.label = this.props.inputProps.label;
            this.props.onInputChange(this.inputInfo);

        }

        this.setState({
            copyChecked: event.currentTarget.checked,
        });
    }

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;

        let value = this.props.value;

        return <React.Fragment>

            <Grid item>

                <FeinInput
                    inputProps={this.props.inputProps}
                    error={this.props.error ? true : false}
                    onInputChange={this.handleChange}
                    value={value || ''}
                    formOrder={this.props.formOrder}
                />

                <br />

                <FormControlLabel
                    control={<Checkbox checked={this.state.copyChecked} onChange={this.handleCopyCheckChange} />}
                    label={this.props.recommendationName}
                />
            </Grid>


        </React.Fragment >;
    }
}

export default CopyRecommendedInputTaxId;