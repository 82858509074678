import React from "react";

import { Grid, Theme, createStyles, WithStyles, withStyles, Card, CardContent, CardHeader } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../../../../util/ui/form/FormInputProperties";
import { InputData } from "../../../../util/ui/form/InputData";
import BasicInput from "../../../../util/ui/form/inputs/BasicInput";
import { ErrorInfo } from "../../../../util/ui/form/ErrorInfo";

import { InputInfo } from "../../../../util/ui/form/AbstractBasicInput";
import { purposeSelectOptions } from "../../../../util/SelectOptions";

import CurrencyInput from "../../../../util/ui/form/inputs/CurrencyInput";
import { PayrollInfo } from "../PPPStartForm";
import OtherSelect from "../../../../util/ui/form/OtherSelect";

export enum PayrollInfoFormNames {
    payrollAvg = "payrollAvg",
    loanRequest = "loanRequest",
    employeeCount = "employeeCount",
    purpose = "purpose"
}

export const PAYROLL_INFO_FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: PayrollInfoFormNames.payrollAvg,
    label: 'Average Monthly Payroll',
    placeholder: 'Average Monthly Payroll',
    required: true,
    type: 'text',
    width: 6
}, {
    name: PayrollInfoFormNames.employeeCount,
    label: 'Number of Employees',
    placeholder: 'Number of Employees',
    required: true,
    type: 'text',
    width: 6
}, {
    name: PayrollInfoFormNames.loanRequest,
    label: 'Loan Request Amount',
    placeholder: 'Loan Request Amount',
    required: true,
    type: 'text',
    width: 6
}, {
    name: PayrollInfoFormNames.purpose,
    label: 'Purpose of the Loan',
    placeholder: 'Explain',
    required: true,
    type: 'text',
    width: 6
}];

let PAYROLL_INFO_FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in PAYROLL_INFO_FORM_PROPERTY_ARRAY) {
    PAYROLL_INFO_FORM_PROPERTY_LIST[PAYROLL_INFO_FORM_PROPERTY_ARRAY[i].name] = PAYROLL_INFO_FORM_PROPERTY_ARRAY[i];
}

export const PAYROLLINFO_FORM_PROPERTY_MAP = PAYROLL_INFO_FORM_PROPERTY_LIST;

const styles = (theme: Theme) => createStyles({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    navigator: {

    },
});

export interface PayrollInfoFormProps extends WithStyles<typeof styles> {

    /**
     * identifiers
     */
    data: PayrollInfo;
    errorFields: Map<string, ErrorInfo>;
    onBasicInputChange(inputInfo: InputData): void;
}

export class PayrollInfoFormState {

}

class PayrollInfoForm extends React.Component<PayrollInfoFormProps, PayrollInfoFormState> {

    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: PayrollInfoFormProps) {
        super(props);

        this.state = new PayrollInfoFormState();
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);

    }

    handleBasicInputChange(input: InputInfo) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        this.props.onBasicInputChange(inputData);

    }

    getRequiredFields(): string[] {

        let required: string[] = [];

        for (var i in PAYROLL_INFO_FORM_PROPERTY_ARRAY) {
            if (PAYROLL_INFO_FORM_PROPERTY_ARRAY[i].required) {
                required.push(PAYROLL_INFO_FORM_PROPERTY_ARRAY[i].name);
            }
        }

        return required;
    }

    render() {

        return <React.Fragment>
            <Card variant="elevation" elevation={0} style={{
                paddingTop: '0px'
            }}>
                <CardHeader title="How much money are you requesting?"
                    subheader="Loan Request Amount: Average Monthly Payroll is multiplied 2.5x + If you received an Economic Injury Disaster Loan (EIDL) between January 31, 2020 and April 3, 2020 you must enter the total amount received minus the advance you received from the total.">

                </CardHeader>
                <CardContent style={{
                    paddingBottom: '0'
                }}>
                    <Grid container spacing={2}>

                        {PAYROLL_INFO_FORM_PROPERTY_ARRAY.map((v, index) => {

                            let jsx = null;

                            switch (v.name) {
                                case PayrollInfoFormNames.loanRequest:

                                    jsx = <React.Fragment>

                                        <CurrencyInput
                                            inputProps={v}
                                            error={this.props.errorFields.get(v.name)?.error}
                                            onInputChange={this.handleBasicInputChange}
                                            value={this.props.data.getDataByName(v.name)?.value}
                                        />
                                    </React.Fragment>;
                                    break;
                                case PayrollInfoFormNames.payrollAvg:

                                    jsx = <CurrencyInput
                                        inputProps={v}
                                        error={this.props.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.data.getDataByName(v.name)?.value}
                                    />;
                                    break;

                                case PayrollInfoFormNames.purpose:
                                    jsx = <OtherSelect
                                        inputProps={v}
                                        error={this.props.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.data.getDataByName(v.name)?.value}
                                        options={purposeSelectOptions}
                                    />;
                                    break;


                                default:

                                    jsx = <BasicInput
                                        inputProps={v}
                                        error={this.props.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.data.getDataByName(v.name)?.value}
                                    />;
                                    break;
                            }


                            return <Grid key={index} item xs={12} sm={v.width || 12}>
                                {jsx}
                            </Grid>

                        })}

                    </Grid>
                </CardContent>
            </Card>

        </React.Fragment>
    }
}

export default withStyles(styles, { withTheme: true })(PayrollInfoForm);