import React from "react";
import { TextField, InputLabelProps, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../FormInputProperties";


interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: string;
    onInputChange(inputInfo: InputInfo, event: React.ChangeEvent<HTMLInputElement>): void;
    recommendationName: string;
    recommendationValue: string;
    formOrder?: number;
}

class BasicInputState {
    copyChecked: boolean = false;
    value: string = '';
}

export class InputInfo {
    name: string = '';
    value: string = '';
    label: string = '';
}

export class CopyRecommendedInput extends React.Component<BasicInputProps, BasicInputState> {

    protected inputInfo: InputInfo = new InputInfo();

    constructor(props: BasicInputProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleCopyCheckChange = this.handleCopyCheckChange.bind(this);
        let state = new BasicInputState();
        state.copyChecked = this.props.recommendationValue === this.props.value;
        this.state = state;
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {

        this.inputInfo.name = this.props.inputProps.name;
        this.inputInfo.value = event.currentTarget.value;
        this.inputInfo.label = this.props.inputProps.label;

        this.props.onInputChange(this.inputInfo, event);

    };

    handleCopyCheckChange(event: React.ChangeEvent<HTMLInputElement>) {

        if (event.currentTarget.checked) {

            this.inputInfo.name = this.props.inputProps.name;
            this.inputInfo.value = this.props.recommendationValue;
            this.inputInfo.label = this.props.inputProps.label;
            this.props.onInputChange(this.inputInfo, event);

        }

        this.setState({
            copyChecked: event.currentTarget.checked,
        });
    }

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;

        let value = this.props.value;

        return <React.Fragment>

            <Grid item>

                <TextField
                    name={this.props.inputProps.name}
                    label={this.props.inputProps.label}
                    fullWidth
                    error={this.props.error ? true : false}
                    type='text'
                    onChange={this.handleChange}
                    required={this.props.inputProps.required}
                    placeholder={this.props.inputProps.placeholder}
                    variant="outlined"
                    value={value || ''}
                    InputLabelProps={inputLabelProps}
                    autoFocus={typeof this.props.formOrder !== 'undefined' ? this.props.formOrder === 1 ? true : false : false}
                />
                <br />

                <FormControlLabel
                    control={<Checkbox checked={this.state.copyChecked} onChange={this.handleCopyCheckChange} />}
                    label={this.props.recommendationName}
                />
            </Grid>


        </React.Fragment>;
    }
}

export default CopyRecommendedInput;