import React from "react";
import { TextField, InputLabelProps } from "@material-ui/core";
import { FormInputPropertiesInterface } from "./FormInputProperties";
import { IndustryTypeNaics, Option } from "../../SelectOptions";
import { Autocomplete } from "@material-ui/lab";

interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: IndustryTypeNaics|null;
    onInputChange(value: IndustryTypeNaics): void;
    options: IndustryTypeNaics[]
}

interface BasicInputState {
    value: IndustryTypeNaics|null
}


export class IndustryTypeNaicsSelect extends React.Component<BasicInputProps, BasicInputState> {

    constructor(props: BasicInputProps) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(event: React.ChangeEvent<any>, value: IndustryTypeNaics | null, reason: any, details: any) {

        if(!value) {
            return;
        }

        this.setState({
            value: value
        })

        this.props.onInputChange(value);
    };

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;

        let defaultVal = undefined;

        if(this.props.value !== undefined && this.props.value) {

            for(let j in this.props.options) {
                if(this.props.options[j].id == this.props.value.id) {
                    defaultVal = this.props.options[j];
                    break;
                }
            }
        }

        return <React.Fragment>
            <Autocomplete
                options={this.props.options}
                getOptionLabel={(option) => option.name}
                onChange={this.handleSelectChange}
                noOptionsText='Not Found'
                // defaultValue={this.props.value}
                value={this.props.options.find((option) => {
                    if(!this.props.value) {
                        return false;
                    }
                    return option.id === this.props.value.id;
                })}
                renderInput={(params) =>

                    <TextField
                        {...params}
                        label={this.props.inputProps.label}
                        variant="outlined"
                        fullWidth
                        error={this.props.error ? true : false}
                        required={this.props.inputProps.required}
                        placeholder={this.props.inputProps.placeholder}
                        InputLabelProps={inputLabelProps}
                    >
                    </TextField>
                }
            />
        </React.Fragment >;
    }
}

export default IndustryTypeNaicsSelect;