import React, { ErrorInfo } from "react";
import { WithStyles, Theme, createStyles, withTheme, withStyles } from "@material-ui/core";

import httpClient from "../../../util/HttpClient";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";

import { CategoryUpload, uploadSectionsPPP } from "../../../util/DocumentUtils";
import { FORMAPI_BASE } from "../BaseUtils";
import { UploadedFile } from "../../../util/ui/form/UploadInput";



const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});


enum CurrentScreen {
    Loading, MainForm
}

interface PPPEndFormProps extends WithStyles<typeof styles> {
    formToken: string;
}

class PPPEndFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    currentScreen: number = CurrentScreen.Loading;
    uploadedFiles: {
        [category: string]: CategoryUpload
    } = {};

    constructor() {
        this.uploadedFiles = {};
        for (var i in uploadSectionsPPP) {
            this.uploadedFiles[uploadSectionsPPP[i].category] = new CategoryUpload();
            this.uploadedFiles[uploadSectionsPPP[i].category].name = uploadSectionsPPP[i].category;
        }
    }
}

class PPPEndForm extends React.Component<PPPEndFormProps, PPPEndFormState> {

    constructor(props: PPPEndFormProps) {
        super(props);

        this.state = new PPPEndFormState();
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.refreshFiles = this.refreshFiles.bind(this);
    }

    componentDidMount() {


        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        openLoader(true);

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/files')
            .then((r) => {

                console.log(r);
                this.refreshFiles(r.data.data);

            }).finally(() => {
                openLoader(false);
            });

        this.setState({
            currentScreen: CurrentScreen.MainForm
        })

    }

    handleFileUpload(files: UploadedFile[]): void {

        if (files.length === 0) {
            return;
        }

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/files', files)
            .then((r) => {
                this.refreshFiles(r.data.data);
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })
    }

    refreshFiles(data: any): void {

        let files: {
            [category: string]: CategoryUpload
        } = {};

        for (var i in data) {
            let d = data[i];
            let c = CategoryUpload.fromData(d);
            files[d.name] = c;
        }

        this.setState({
            uploadedFiles: files
        });

    }


    handleSubmitClick(e: React.MouseEvent | React.TouchEvent): void {

    }

    render() {
        return <React.Fragment>
            {/* <Card elevation={0}>
                <CardHeader title="Your Application Has Been Submitted."
                    subheader="You can upload more documents below">
                </CardHeader>
                <CardContent>
                    {uploadSectionsPPP.map((u, i) => {

                        return <UploadInput
                            key={i}
                            category={u.category}
                            label={u.label}
                            required={u.required}
                            fileListUploaded={this.state.uploadedFiles[u.category]?.getFilenames()}
                            error={false}
                            formToken={this.props.formToken}
                            onFileupload={this.handleFileUpload}
                        >

                        </UploadInput>
                    })}
                </CardContent>
            </Card> */}

        </React.Fragment>
    }
}

export default withTheme(withStyles(styles)(PPPEndForm));