import React from "react";
import { Theme, createStyles, withTheme, withStyles, WithStyles, Grid, List, ListItem, ListItemText, ListItemAvatar, Avatar, CardHeader, CardContent, Card, Typography } from "@material-ui/core";

import { Connection } from "../BankConnectForm";
import { ClientAppConfig } from "../../../util/AppUtil";

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    interstitialContainer: {
        justifyContent: 'center',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogContent: {
        [theme.breakpoints.down('sm')]: {
            width: theme.breakpoints.values.sm
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.breakpoints.values.xs
        },
        [theme.breakpoints.down('md')]: {
            width: theme.breakpoints.values.md
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.breakpoints.values.lg
        },
    }
});

interface BankConnectCompleteProps extends WithStyles<typeof styles> {

    formToken: string;
    connections: Connection[];
    clientAppConfig: ClientAppConfig;
}

class BankConnectCompleteState {
}

class BankConnectComplete extends React.Component<BankConnectCompleteProps, BankConnectCompleteState> {

    constructor(props: BankConnectCompleteProps) {
        super(props);


        let state = new BankConnectCompleteState();
        this.state = state;

    }


    render() {

        return <React.Fragment>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card elevation={0}>
                        <CardHeader title="Connection Established"
                            subheader={"The bank integration process is now complete! You may now close this window"}>
                        </CardHeader>
                        <CardContent>
                            <Typography variant='subtitle1' gutterBottom>Thank you for granting access to the following Bank Account(s):</Typography>
                            <List title="Connected Bank Accounts">
                                {this.props.connections.map((c, i) => {
                                    return <ListItem key={i}>
                                        <ListItemAvatar>
                                            <Avatar src={c.logo}>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={c.bankName} secondary={c.status !== 'FAILED' ? 'Connection: Good' : 'Connection: Failed'} />
                                    </ListItem>
                                })}
                            </List>
                        </CardContent>
                    </Card>


                </Grid>

            </Grid>
        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(BankConnectComplete));