import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import validator from 'validator';
import httpClient from '../../../../util/HttpClient';
import { FORMAPI_BASE } from '../../BaseUtil';
import { openLoader } from '../../../../util/ui/dialog/loader/Loader';
import { openNotifier } from '../../../../util/ui/dialog/notifier/Notifier';

let openInviteOwnerFn: (onOwnerAdd?: () => void) => void;

export default function InviteOwnerFormERC(props: {
    token: string
}) {

    const [state, setState] = React.useState({
        email: '',
        open: false,
        formToken: props.token,
        error: false,
        onOwnerAdd: () => { }
    });

    openInviteOwnerFn = (onOwnerAdd?: any) => {
        setState({
            ...state,
            open: true,
            onOwnerAdd: onOwnerAdd
        })
    };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    const handleInvite = () => {

        if (!validator.isEmail(state.email)) {

            setState({
                ...state,
                error: true
            })

            return;
        }

        openLoader(true);

        httpClient.get(FORMAPI_BASE + '/' + props.token + '/invite-owner?email=' + state.email)
            .then((r) => {
                openNotifier("Succesfully invited " + state.email + " to the application");
                if (typeof state.onOwnerAdd === 'function') {
                    state.onOwnerAdd();
                }
                handleClose();
            })
            .catch(()=> {
                openLoader(false);
            })
            .finally(() => {
                openLoader(false);
            });

    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            email: e.currentTarget.value,
            error: false
        })
    }

    return (
        <div>
            <Dialog open={state.open} onClose={handleClose} aria-labelledby="invite-owner-form-dialog-title">
                <DialogTitle id="invite-owner-form-dialog-title">Invite Partner / Owner / Signer</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To invite your co-owner/partner, please enter their email address here.
                        <br /> We will email them a link to join this application.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        required={true}
                        value={state.email}
                        error={state.error}
                        onChange={handleEmailChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleInvite} color="primary">
                        Invite
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

export function openInviteOwnerForm(onOwnerAdd?: () => void): void {
    openInviteOwnerFn(onOwnerAdd);
};