import { osVersion, osName, mobileVendor, browserName, mobileModel, deviceType } from 'react-device-detect';
import { openNotifier } from './ui/dialog/notifier/Notifier';
import {MetaInfo} from "./AppMetaUtils";

export class DeviceInfo {
    osVersion: string = '';
    osName: string = '';
    browserName: string = '';
    mobileVendor: string = '';
    mobileModel: string = '';
    deviceType: string = '';

    static getCurrentDeviceInfo(): DeviceInfo {

        let o = new DeviceInfo();

        o.osVersion = osVersion;
        o.osName = osName;
        o.browserName = browserName;
        o.mobileVendor = mobileVendor;
        o.mobileModel = mobileModel;
        o.deviceType = deviceType;

        return o;
    }
}

class WindowUtils {

    private loaded: boolean = false;

    constructor() {
        this.onBack = this.onBack.bind(this);
        setTimeout(() => {
            this.loaded = true;
        }, 3000);
    }

    private onBack(): void {
        window.history.go(1);
        if (this.loaded) {
            openNotifier("Please use navigation on the Form to go back and forth", undefined, {
                horizontal: 'left',
                vertical: 'top'
            });
        }
    }

    preventBackButton(): void {
        window.history.pushState(null, '', window.location.href);
        window.history.back();
        window.history.forward();

        window.onpopstate = this.onBack;
    }

    insertParam(key: string, value: string): void {
        key = encodeURI(key); value = encodeURI(value);

        var kvp = window.location.search.substr(1).split('&');

        var i = kvp.length; var x; while (i--) {
            x = kvp[i].split('=');

            if (x[0] === key) {
                x[1] = value;
                kvp[i] = x.join('=');
                break;
            }
        }

        if (i < 0) { kvp[kvp.length] = [key, value].join('='); }

        //this will reload the page, it's likely better to store this until finished
        window.location.search = kvp.join('&');
    }

    clearParams(keys: string[]) {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));
        for (let i in keys) {
            params.delete(keys[i]);
        }
        window.location.search = params.toString();
    }
    getParamsLike(pattern: string):MetaInfo{

        let params = new URLSearchParams(window.location.search);

        let mathcingParams: MetaInfo = {};

        for(let key of params.keys() ){

            if(key.startsWith(pattern)){
                mathcingParams[key.substr(pattern.length)]=params.get(key);
            }

        }

        return mathcingParams;



    }
    getParam(key: string):string {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has(key)){

            let val =  urlParams.get(key);

            if(val){
                return val;
            }else{
                return '';
            }

        }else{
            return '';
        }

    }
    ensureParams(map: {
        key: string, val: string
    }[]) {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search.slice(1));

        let update: number[] = [];
        for (let i in map) {
            let v = map[i];
            if (params.get(v.key) === v.val) {
                continue;
            }
            update.push(Number(i));
        }

        if (update.length === 0) {
            return;
        }

        for (let j in update) {
            let index = update[j];
            params.set(map[index].key, map[index].val);
        }

        window.location.search = params.toString();
    }


    addCustomScript = (encrypted: string) => {

        let customScript = '<!DOCTYPE html><html><head>' + (atob(encrypted)) + '</head></html>';

        let parser = new DOMParser();

        let xml = parser.parseFromString((customScript), 'text/html');

        let scripts = xml.getElementsByTagName("script");

        for (let i = 0; i < scripts.length; i++) {

            let script = scripts.item(i);

            if (script === null) {
                continue;
            }

            var newScript = document.createElement("script");

            let attribs = script.getAttributeNames();

            for (let a in attribs) {
                let attrib = attribs[a];
                let attribValue = script.getAttributeNode(attrib)?.value;
                if (typeof attribValue !== 'undefined' && attribValue !== null) {
                    newScript.setAttribute(attrib, attribValue);
                }
            }

            var inlineScript = document.createTextNode(script.innerHTML);
            newScript.appendChild(inlineScript);
            document.head.appendChild(newScript);
        }

    };
}

export default new WindowUtils();