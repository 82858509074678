import React from 'react';
import { ErrorInfo } from '../../../../util/ui/form/ErrorInfo';
import { AbstractInfo } from '../AbstractInfo';
import { InputData } from '../../../../util/ui/form/InputData';
import { Grid, Button } from '@material-ui/core';
import { FormInputPropertiesInterface } from '../../../../util/ui/form/FormInputProperties';
import BasicInput from '../../../../util/ui/form/inputs/BasicInput';
import { Delete } from '@material-ui/icons';


export enum AddendumAInputFormNames {
    entityName = "entityName",
    relationship = "relationship"
}

export const ADDENDUMA_FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: AddendumAInputFormNames.entityName,
    label: 'Business Legal Name',
    required: true,
    type: 'text',
    width: 7,
}, {
    name: AddendumAInputFormNames.relationship,
    label: 'Relationship',
    required: true,
    type: 'text',
    width: 4
}];

let FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in ADDENDUMA_FORM_PROPERTY_ARRAY) {
    FORM_PROPERTY_LIST[ADDENDUMA_FORM_PROPERTY_ARRAY[i].name] = ADDENDUMA_FORM_PROPERTY_ARRAY[i];
}

export const ADDENDUMA_FORM_PROPERTY_MAP = FORM_PROPERTY_LIST;


export class AdditionalBusinessInfo extends AbstractInfo {

}

interface AddendumAItemProps {
    isDeletable: boolean;
    index: number;
    businessInfo: AdditionalBusinessInfo;
    errorFields: Map<string, ErrorInfo>;
    onChange(index: number, inputData: InputData): void;
    onRemoveAddendumItem(index: number): void;
}

export class AddendumAItem extends React.Component<AddendumAItemProps> {

    constructor(props: AddendumAItemProps) {
        super(props);
        this.handleLineChange = this.handleLineChange.bind(this);
        this.handleRemoveAddnlBusiness = this.handleRemoveAddnlBusiness.bind(this);
    }

    handleLineChange(input: InputData, event: React.ChangeEvent<HTMLInputElement>) {

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        this.props.onChange(this.props.index, inputData);
    }

    handleRemoveAddnlBusiness() {
        this.props.onRemoveAddendumItem(this.props.index);
    }

    render() {

        return <React.Fragment>
            <Grid container spacing={2}>

                {ADDENDUMA_FORM_PROPERTY_ARRAY.map((v, index) => {
                    let jsx = <BasicInput
                        inputProps={v}
                        error={this.props.errorFields?.get(v.name)?.error}
                        onInputChange={this.handleLineChange}
                        value={this.props.businessInfo?.getDataByName(v.name)?.value}
                    />;
                    return <Grid key={index} item sm={v.width || 12} xs={12}>
                        {jsx}
                    </Grid>
                })}
                {this.props.isDeletable &&
                    <Grid item sm={1} xs={12} style={{
                        paddingTop: '15px'
                    }}>
                        <Button size='large' variant='contained' color='secondary' onClick={this.handleRemoveAddnlBusiness}><Delete></Delete>  </Button>
                    </Grid>

                }

            </Grid>
        </React.Fragment>;
    }
}