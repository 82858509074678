import React from "react";
import { Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress, Grid, Typography, CardContent, Card, CardHeader, Checkbox } from "@material-ui/core";

import { InputInfo } from "../../../util/ui/form/AbstractBasicInput";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import _ from 'lodash';
import httpClient from "../../../util/HttpClient";

import { openLoader } from "../../../util/ui/dialog/loader/Loader";

import { esignOptions } from "../../../util/RadioCheckOptions";
import { FormInputPropertiesInterface } from "../../../util/ui/form/FormInputProperties";
import { BasicInput } from "../../../util/ui/form/inputs/BasicInput";
import { AbstractInfo } from "./AbstractInfo";
import SSNInput from "../../../util/ui/form/inputs/SSNInput";
import { Alert } from "@material-ui/lab";
import { format } from 'date-fns'
import { FORMAPI_BASE } from "../BaseUtils";

const certifications2: string[] = [
    'I have read the statements included in this form, including the Statements Required by Law and Executive Orders, and I understand them.',
    'The Applicant is eligible to receive a loan under the rules in effect at the time this application is submitted that have been issued by the Small  Business  Administration  (SBA)  implementing  the  Paycheck  Protection  Program  under  Division  A,  Title  I  of  the  Coronavirus  Aid, Relief, and Economic Security Act (CARES Act) (the Paycheck Protection Program Rule).',
    'The Applicant (1) is an independent contractor, eligible self-employed individual, or sole proprietor or (2) employs no more than the greater of 500 or employees or, if applicable, the size standard in number of employees established by the SBA in 13 C.F.R. 121.201 for the Applicant’s industry.',
    'I will comply, whenever applicable, with the civil rights and other limitations in this form.',
    'All  SBA  loan  proceeds  will  be  used  only  for  business-related  purposes  as  specified  in  the  loan  application  and  consistent  with  the Paycheck Protection Program Rule.',
    'To the extent feasible, I will purchase only American-made equipment and products.',
    'The Applicant is not engaged in any activity that is illegal under federal, state or local law.',
    'Any loan received by the Applicant under Section 7(b)(2) of the Small Business Act between January 31, 2020 and April 3, 2020 was for a purpose other than paying payroll costs and other allowable uses loans under the Paycheck Protection Program Rule.'
];

enum EsignFormNames {
    ssn = "ssn",
    zip = "zip",
    address = "address",
    city = "city",
    state = "state",
    fullname = "fullname",
    title = "title",
}

export const ESIGN_FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: EsignFormNames.ssn,
    label: 'Applicant SSN',
    required: true,
    type: 'text',
    width: 12,
}, {
    name: EsignFormNames.address,
    label: 'Applicant Home Address',
    required: true,
    type: 'text',
    width: 12
}, {
    name: EsignFormNames.city,
    label: 'City',
    required: true,
    type: 'text',
    width: 6
}, {
    name: EsignFormNames.state,
    label: 'State',
    required: true,
    width: 3
}, {
    name: EsignFormNames.zip,
    label: 'ZIP Code',
    required: true,
    width: 3
}, {
    name: EsignFormNames.fullname,
    label: 'Full Name',
    required: true,
    width: 9
}, {
    name: EsignFormNames.title,
    label: 'Title',
    required: true,
    width: 3
}];

let FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in ESIGN_FORM_PROPERTY_ARRAY) {
    FORM_PROPERTY_LIST[ESIGN_FORM_PROPERTY_ARRAY[i].name] = ESIGN_FORM_PROPERTY_ARRAY[i];
}

export const ESIGN_FORM_PROPERTY_MAP = FORM_PROPERTY_LIST;


const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});

interface EsignFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    onGoBack(): void;
    formToken: string;
}


enum AddnlInfoSteps {
    Start
}

enum CurrentScreen {
    Loading, MainForm
}

class ApplicantAuthorization extends AbstractInfo {

}

class EsignFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    certificationsChecked: {
        [key: string]: boolean
    } = {};
    currentScreen: number = CurrentScreen.Loading;
    certErrors1: {
        [key: string]: boolean
    } = {}
    primaryApplicantName: string = '';
    authorization: ApplicantAuthorization = new ApplicantAuthorization();
    certificationFinal: boolean = false;
    certErrors2: boolean = false;
    signDate: string = '';
    signDate2: string = '';
}

class EsignForm extends React.Component<EsignFormProps, EsignFormState> {

    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: EsignFormProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleCertificationsChange = this.handleCertificationsChange.bind(this);
        this.handleFinalCertificationChange = this.handleFinalCertificationChange.bind(this);

        let state = new EsignFormState();

        for (var i in esignOptions) {
            let o = esignOptions[i];
            state.certificationsChecked[o.name || ''] = false;
        }

        this.state = state;
    }

    componentDidMount() {

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/esign')
            .then((r) => {
                console.log(r);
                this.refreshFormData(r.data.data);
            });

        this.setState({
            currentScreen: CurrentScreen.MainForm,
            signDate2: format(new Date(), 'EEEE, LLLL M \'at\' h:mma'),
            signDate: format(new Date(), 'EEEE, LLLL M h:mma'),
        })

        setTimeout(() => {

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

        }, 100);
    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {
        this.errorFields = _.clone(this.state.errorFields);
        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }
        let a = _.clone(this.state.authorization);
        a.setDataByName(input);
        this.setState({
            authorization: a
        });
    }


    getCurrentStep(): number {

        return AddnlInfoSteps.Start;

    }

    refreshFormData(data: any) {

        this.setState({
            primaryApplicantName: data.primaryApplicantName,
            certificationFinal: data.certificationFinal,
            certificationsChecked: data.certificationsChecked,
            authorization: new ApplicantAuthorization(data.authorization ? data.authorization.inputs : [])
        })
    }

    validate(): boolean {
        return this.validateCerts() && this.validateForm();
    }

    validateForm(): boolean {


        let errors = new Map<string, ErrorInfo>();

        for (var i in ESIGN_FORM_PROPERTY_ARRAY) {

            let name = ESIGN_FORM_PROPERTY_ARRAY[i].name;
            let value = this.state.authorization.getDataByName(name)?.value || '';

            if (ESIGN_FORM_PROPERTY_ARRAY[i].required && value === '') {
                errors.set(name, new ErrorInfo(true));
            }

        }

        this.setState({
            errorFields: errors
        });

        return !(errors.size > 0);

    }


    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        if (!this.validate()) {
            return;
        }

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/esign', this.state)
            .then((r) => {

                this.props.onCompleteForm();
                // this.refreshFormData(answers);
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })
    }

    handleBackClick(event: React.TouchEvent | React.MouseEvent): void {
        this.props.onGoBack();
    }


    render() {

        switch (this.state.currentScreen) {
            case CurrentScreen.Loading:
                return <LinearProgress></LinearProgress>;
            case CurrentScreen.MainForm:
                return this.getMainFormJSX();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }

    }

    handleCertificationsChange(event: React.ChangeEvent<HTMLInputElement>) {

        let s = _.clone(this.state.certificationsChecked);
        if (s === null) {
            s = {};
        }
        s[event.currentTarget.name] = event.currentTarget.checked;
        let e = _.clone(this.state.certErrors1);
        e[event.currentTarget.name] = false;

        this.setState({
            certificationsChecked: s,
            certErrors1: e
        });

    }

    handleFinalCertificationChange(event: React.ChangeEvent<HTMLInputElement>) {

        this.setState({
            certificationFinal: event.currentTarget.checked,
            certErrors2: !event.currentTarget.checked
        })
    }

    validateCerts(): boolean {

        let e = _.clone(this.state.certErrors1);
        let failed: boolean = false;
        for (var i in esignOptions) {

            let name: string = esignOptions[i].name || '';
            if (!this.state.certificationsChecked[name]) {
                e[name] = true;
                failed = true;
            } else {
                e[name] = false;
            }

        }

        this.setState({
            certErrors1: e,
            certErrors2: !this.state.certificationFinal
        })

        return !failed && this.state.certificationFinal;
    }

    getMainFormJSX(): JSX.Element {

        return <React.Fragment>
            <Grid container spacing={2}>

                <Grid item xs={12} >
                    <Grid container spacing={2}>
                        <Card variant='elevation' elevation={0}>
                            <CardHeader title='Certifications' subheader='By Checking the Boxes,
                            Completing the Form, and Signing Below, You Make the Following
                            Representations, Authorizations and Certifications'></CardHeader>
                            <CardContent>

                                {esignOptions.map((d, i) => {

                                    let checked: boolean = false;
                                    if (this.state.certificationsChecked && this.state.certificationsChecked[d.name || '']) {
                                        checked = true;
                                    }

                                    return <React.Fragment key={i}>
                                        <Grid container spacing={1}>

                                            <Grid item xs={11}>
                                                <Typography variant='body2' color={this.state.certErrors1[d.name || ''] ? 'error' : 'initial'}  >
                                                    {d.jsx}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} >
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={this.handleCertificationsChange}
                                                    color="primary"
                                                    name={d.name || ''}
                                                    value={checked}
                                                />

                                            </Grid>
                                        </Grid>
                                        {
                                            i < esignOptions.length - 1 && <br />
                                        }

                                    </React.Fragment>
                                })}

                            </CardContent>
                        </Card>
                        <Card variant='elevation' elevation={0} style={{
                            paddingTop: '0px',
                            paddingBottom: '0px'
                        }}>
                            <CardHeader title={'Applicant Authorization - ' + this.state.primaryApplicantName} ></CardHeader>
                            <CardContent>
                                <Grid container spacing={2}>

                                    {ESIGN_FORM_PROPERTY_ARRAY.map((v, index) => {

                                        let jsx = <React.Fragment></React.Fragment>;

                                        switch (v.name) {
                                            case EsignFormNames.ssn:
                                                jsx = <SSNInput
                                                    inputProps={v}
                                                    error={this.state.errorFields.get(v.name)?.error}
                                                    onInputChange={this.handleBasicInputChange}
                                                    value={this.state.authorization.getDataByName(v.name)?.value}
                                                />;
                                                break;
                                            default:

                                                jsx = <BasicInput
                                                    inputProps={v}
                                                    error={this.state.errorFields.get(v.name)?.error}
                                                    onInputChange={this.handleBasicInputChange}
                                                    value={this.state.authorization.getDataByName(v.name)?.value}
                                                />;
                                                break;
                                        }


                                        return <React.Fragment key={index} >
                                            {index === 5 &&
                                                <Grid item sm={12}>
                                                    <Typography>
                                                        Applicant E-Signature*
                                                    </Typography>
                                                </Grid>}
                                            <Grid item sm={v.width || 12} xs={12}>
                                                {jsx}
                                            </Grid>
                                            {
                                                index === ESIGN_FORM_PROPERTY_ARRAY.length - 1 &&
                                                <Grid item xs={12}>
                                                    <React.Fragment>

                                                        <Alert variant='filled' >
                                                            {this.state.signDate2}
                                                        </Alert>
                                                        <br />
                                                        <Typography variant='body2'>
                                                            *You acknowledge that the electronic signatures, initials, or symbols appearing on this application are the same as handwritten signatures for the purposes of validity, enforceability and admissibility.
                                                        </Typography>
                                                    </React.Fragment>
                                                </Grid>

                                            }
                                        </React.Fragment>
                                    })}

                                </Grid>
                            </CardContent>
                        </Card>
                        <Card variant='elevation' elevation={0} style={{ paddingTop: '0px' }}>
                            <CardContent style={{ paddingTop: '0px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                        <Typography color={this.state.certErrors2 ? 'error' : 'initial'} gutterBottom>
                                            <Checkbox

                                                checked={this.state.certificationFinal}
                                                onChange={this.handleFinalCertificationChange}
                                                name="certificationFinal"
                                                color="primary"
                                            />
                                            I Certify that...
                                        </Typography>
                                        <ul>
                                            {certifications2.map((c, i) =>
                                                <li key={i}>
                                                    {c}
                                                </li>)}
                                        </ul>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                                onClick={this.handleContinueClick} fullWidth>
                                Sign And Submit
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid >
        </React.Fragment >;
    }
}

export default withTheme(withStyles(styles)(EsignForm));