import React from "react";
import { Stepper, Step, StepLabel, Theme, createStyles, withStyles, WithStyles, withTheme, LinearProgress } from "@material-ui/core";

import BusinessInfoForm from "./components/BusinessInfoForm";

import OwnerInfoForm from "./components/OwnerInfoForm";
import { InputData } from "../../util/ui/form/InputData";
import httpClient from "../../util/HttpClient";

import EsignForm from "./components/EsignForm";
import PPPStartForm from "./components/PPPStartForm";
import PPPEndForm from "./components/PPPEndForm";
import { FORMAPI_BASE } from "./BaseUtils";

const styles = (theme: Theme) => createStyles({

    stepper: {
        padding: theme.spacing(2, 0, 3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
});

interface FundingFormProps extends WithStyles<typeof styles> {
    formToken: string;
    onFormTokenUpdate(formToken: string): void;
}

class FundingFormState {
    activeStep: number = -1;
    amountRequested: InputData = new InputData();
    ssn: string = '';
}

class FormStep {
    label: string = '';
    element: JSX.Element = <React.Fragment></React.Fragment>;

    constructor(label: string, element: JSX.Element) {
        this.label = label;
        this.element = element;
    }
}

enum ActiveStep {
    Initial = -1, PPPStart = 0, Business = 1, Owner = 2, Esign = 3, Summary = 4
}

class PPPFundingForm extends React.Component<FundingFormProps, FundingFormState> {


    constructor(props: FundingFormProps) {
        super(props);

        this.handleBusinessCompleteForm = this.handleBusinessCompleteForm.bind(this);
        this.handleOwnerCompleteForm = this.handleOwnerCompleteForm.bind(this);
        this.handleFormTokenUpdate = this.handleFormTokenUpdate.bind(this);
        this.handleBusinessInfoGoBack = this.handleBusinessInfoGoBack.bind(this);

        this.handleOwnerInfoGoBack = this.handleOwnerInfoGoBack.bind(this);

        this.handleEsignCompleteForm = this.handleEsignCompleteForm.bind(this);
        this.handleEsignGoBack = this.handleEsignGoBack.bind(this);

        this.handleCompletePPPStart = this.handleCompletePPPStart.bind(this);

        this.state = new FundingFormState();
    }

    componentDidMount() {
        if (this.props.formToken !== 'new') {
            httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep').then((r) => {
                this.setState({
                    activeStep: r.data.data
                });
            });
        } else {
            this.setState({
                activeStep: ActiveStep.PPPStart
            });
        }

    }

    handleOwnerCompleteForm() {

        this.changeStep(ActiveStep.Esign);
    }

    handleBusinessCompleteForm() {
        this.changeStep(ActiveStep.Owner);
    }

    handleFormTokenUpdate(token: string) {
        this.props.onFormTokenUpdate(token);
    }

    handleBusinessInfoGoBack(): void {
        this.changeStep(ActiveStep.PPPStart);
    }

    handleEsignGoBack(): void {
        this.changeStep(ActiveStep.Owner);
    }

    handleEsignCompleteForm(): void {
        this.changeStep(ActiveStep.Summary);
    }

    changeStep(step: number) {
        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep?activeStep=' + step);

        this.setState({
            activeStep: step
        });
    }

    handleOwnerInfoGoBack(): void {
        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep?activeStep=' + ActiveStep.Business);

        this.setState({
            activeStep: ActiveStep.Business
        });
    }

    handleCompletePPPStart(): void {
        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep?activeStep=' + ActiveStep.Business);

        this.setState({
            activeStep: ActiveStep.Business
        });
    }

    handleFinalComplete(): void {

    }

    render() {

        let steps: FormStep[] = [];

        steps.push(new FormStep("Start", <PPPStartForm
            key={steps.length}
            formToken={this.props.formToken}
            onFormTokenUpdate={this.handleFormTokenUpdate}
            onCompleteForm={this.handleCompletePPPStart}
        ></PPPStartForm>));

        steps.push(new FormStep("Business", <BusinessInfoForm
            key={steps.length}
            formToken={this.props.formToken}
            onCompleteForm={this.handleBusinessCompleteForm}
            onGoBack={this.handleBusinessInfoGoBack}
        ></BusinessInfoForm>));


        steps.push(new FormStep("Owners", <OwnerInfoForm
            key={steps.length}
            formToken={this.props.formToken}
            onCompleteForm={this.handleOwnerCompleteForm}
            onGoBack={this.handleOwnerInfoGoBack}
        ></OwnerInfoForm>));


        steps.push(new FormStep("E-Sign",
            <EsignForm
                formToken={this.props.formToken}
                key={steps.length}
                onCompleteForm={this.handleEsignCompleteForm}
                onGoBack={this.handleEsignGoBack}
            />
        ));


        if (this.state.activeStep === ActiveStep.Initial) {
            return <React.Fragment>
                <LinearProgress />
            </React.Fragment>
        }

        return <React.Fragment>

            <Stepper className={this.props.classes.stepper} activeStep={this.state.activeStep} orientation="horizontal">
                {steps.map(s => (
                    <Step key={s.label}>
                        <StepLabel>
                            {s.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            <React.Fragment>
                {this.state.activeStep === steps.length ? (
                    <React.Fragment>
                        <PPPEndForm
                            formToken={this.props.formToken}
                        />
                    </React.Fragment>
                ) : (
                        < React.Fragment >
                            {steps[this.state.activeStep].element}
                        </React.Fragment>
                    )}
            </React.Fragment>
        </React.Fragment >

    }
}

export default withTheme(withStyles(styles)(PPPFundingForm));