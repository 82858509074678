import React from "react";
import { Stepper, Step, StepLabel, Theme, createStyles, withStyles, WithStyles, withTheme, LinearProgress } from "@material-ui/core";

import httpClient from "../../util/HttpClient";
import { ClientAppConfig } from "../../util/AppUtil";
import { FORMAPI_BASE } from "./BaseUtil";
import BankConnectStart from "./components/BankConnectStart";
import BankConnectComplete from "./components/BankConnectComplete";

const styles = (theme: Theme) => createStyles({

    stepper: {
        padding: theme.spacing(2, 0, 3),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    square: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        width: '100%'
    },

});

interface BankConnectFormProps extends WithStyles<typeof styles> {
    formToken: string;
    clientAppConfig: ClientAppConfig;
    onValidTokens(formToken: string): void;
    onInvalidTokens(): void;
}


export class Connection {
    bankName: string = '';
    accountNumber: string = '';
    status: string = '';
    logo: string = '';
}

class BankConnectFormState {
    activeStep: number = -1;
    connections: Connection[] = [];
    yodleeToken: string = '';
    fastLinkURL: string = '';
}

class FormStep {
    label: string = '';
    element: JSX.Element = <React.Fragment></React.Fragment>;

    constructor(label: string, element: JSX.Element) {
        this.label = label;
        this.element = element;
    }
}

enum ActiveStep {
    Loading = -1, GrantAccess = 0, Complete = 1
}

class BankConnectForm extends React.Component<BankConnectFormProps, BankConnectFormState> {


    constructor(props: BankConnectFormProps) {
        super(props);

        this.handleBankCompleteForm = this.handleBankCompleteForm.bind(this);
        this.scriptLoaded = this.scriptLoaded.bind(this);

        this.state = new BankConnectFormState();
    }

    componentDidMount() {

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/formState').then((r) => {

            let activeStep = ActiveStep.Loading;

            if (r.data.data.step === ActiveStep.GrantAccess) {

                const script = document.createElement("script");
                script.src = "https://cdn.yodlee.com/fastlink/v1/initialize.js";
                script.async = true;
                script.onload = () => this.scriptLoaded();
                document.body.appendChild(script);


            } else {
                activeStep = r.data.data.step;
            }

            this.setState({
                activeStep: activeStep,
                connections: r.data.data.connections,
                yodleeToken: r.data.data.newConnectionInfo.token,
                fastLinkURL: r.data.data.newConnectionInfo.fastLinkURL
            });

            this.props.onValidTokens(this.props.formToken);

        }).catch((e) => {
            console.log(e);
            this.props.onInvalidTokens();

        });
    }

    scriptLoaded() {
        this.setState({
            activeStep: ActiveStep.GrantAccess
        });

    }

    handleBankCompleteForm(): void {
        this.changeStep(ActiveStep.Complete);
    }


    changeStep(step: number) {
        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/activeStep?activeStep=' + step);

        this.setState({
            activeStep: step
        });
    }

    render() {

        if (this.state.activeStep === ActiveStep.Loading) {
            return <React.Fragment>
                <LinearProgress></LinearProgress>
            </React.Fragment>;
        }

        let steps: FormStep[] = [];

        steps.push(new FormStep("Grant Access", <BankConnectStart
            key={steps.length}
            formToken={this.props.formToken}
            yodleeToken={this.state.yodleeToken}
            yodleeUrl={this.state.fastLinkURL}
            onCompleteForm={this.handleBankCompleteForm}
            clientAppConfig={this.props.clientAppConfig}
        ></BankConnectStart>));

        steps.push(new FormStep("Done", <BankConnectComplete
            key={steps.length}
            formToken={this.props.formToken}
            connections={this.state.connections}
            clientAppConfig={this.props.clientAppConfig}
        ></BankConnectComplete>));

        return <React.Fragment>

            <Stepper className={this.props.classes.stepper} activeStep={this.state.activeStep} orientation="horizontal">
                {steps.map(s => {

                    return (
                        <Step key={s.label}>
                            <StepLabel>
                                {s.label}
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {steps[this.state.activeStep].element}

        </React.Fragment >

    }
}

export default withTheme(withStyles(styles)(BankConnectForm));