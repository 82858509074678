import React from "react";
import { AbstractBasicInput, BasicInputProps, BasicInputState } from "../AbstractBasicInput";
import StringUtils from "../../../StringUtils";
import { InputLabelProps, TextField } from "@material-ui/core";
import ReactInputMask from "react-input-mask";



class PhoneInputState implements BasicInputState {
    selectionEnd: number = 0;
}

export class PhoneInput extends AbstractBasicInput<BasicInputProps, PhoneInputState> {

    cleanInputBeforeSendingOut(val: string) {
        return StringUtils.onlyNumbers(val).substr(0, 10);
    }

    // getFormattedVal(v: string): string {
    //     return StringUtils.formatPhone(v);
    // }

    // formatValueBeforeDisplaying(val: any) {
    //     return this.getFormattedVal(val);
    // }

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;
        let val: string = this.formatValueBeforeDisplaying(this.props.value || '');

        let variant: "filled" | "outlined" | "standard" | undefined = "outlined";
        if (typeof this.props.textFieldVariant !== 'undefined' && this.props.textFieldVariant !== null) {
            variant = this.props.textFieldVariant;
        }

        return <React.Fragment>
            <ReactInputMask
                mask='(999) 999-9999'
                value={val}
                onChange={this.handleChange}>
                {
                    () => <TextField
                        name={this.props.inputProps.name}
                        label={this.props.inputProps.label}
                        fullWidth
                        error={this.props.error ? true : false}
                        type={this.props.inputProps.type ? this.props.inputProps.type : 'text'}

                        required={this.props.inputProps.required}
                        placeholder={this.props.inputProps.placeholder}
                        variant={variant}
                        InputLabelProps={inputLabelProps}
                        InputProps={{
                            inputProps: this.props.inputProps,
                            inputMode: this.props.inputProps.inputMode
                        }}
                        autoFocus={typeof this.props.formOrder !== 'undefined' ? this.props.formOrder === 1 ? true : false : false}
                    />
                }

            </ReactInputMask>
        </React.Fragment>;
    }
}

export default PhoneInput;