import validator from 'validator';


class StringUtils {

    onlyNumbers(val: string, allowDecimals: boolean = false) {
        if (!allowDecimals) {
            return val.replace(/[^0-9]/g, '');
        } else {
            return val.replace(/[^0-9.]/g, '');
        }

    }

    validateCCExpirationDate(value: string): boolean {

        if (/[^0-9-\s]+/.test(value))
            return false;

        value = value.replace(/\D/g, "");

        let firstTwo: number = parseInt(value.slice(0, 2));
        let lastTwo: number = parseInt(value.slice(2, 4));

        if (isNaN(firstTwo) || isNaN(lastTwo)) {
            return false;
        }

        if (firstTwo > 12) {
            return false;
        }

        var today = new Date();

        var someDay = new Date();
        someDay.setFullYear(2000 + lastTwo, firstTwo, 1);

        if (someDay < today) {
            return false;
        }

        return true;
    }

    validateCCCVV(value: string): boolean {
        return /^[0-9]{3,4}$/.test(value);
    }

    validateCCZipcode(value: string): boolean {
        return /^\d{5}(-\d{4})?(?!-)$/.test(value);
    }

    formatCurrency(num: any): string {
        num = this.nFormat(num);
        if (isNaN(num)) {
            num = "0";
        } else {
            num = parseFloat(num);
        }

        let sign = (num === (num = Math.abs(num)));
        num = Math.floor(num * 100 + 0.50000000001);
        let cents = num % 100;
        num = Math.floor(num / 100).toString();
        let centsStr = '';
        if (cents < 10) {
            centsStr = "0" + cents.toString();
        } else {
            centsStr = cents.toString();
        }

        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
            num = num.substring(0, num.length - (4 * i + 3)) + ',' +
                num.substring(num.length - (4 * i + 3));
        return (((sign) ? '' : '-') + '$' + num + '.' + centsStr);
    }

    isNumeric(n : any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    nFormat(n: string, dec: number = 2) {
        if (typeof n === 'undefined') {
            return 0;
        }
        if (typeof dec === 'undefined') dec = 2;
        let ret = (Number(n.toString().replace(/[^\d.-]/g, '')).toFixed(dec));
        if (this.isNumeric(ret)) {
            return parseFloat(ret);
        }
        return 0;
    }

    isEmpty(str: string | null) {
        if (str === null) {
            return true;
        }

        return validator.isEmpty(str);
    }

    formatSSN(v: string): string {
        if (v.length >= 11) {
            v = v.substring(0, 11);
            return v;
        }

        var stripedv = this.onlyNumbers(v);
        var areacode = stripedv.substring(0, 3);
        var prefix = stripedv.substring(3, 5);
        var suffix = stripedv.substring(5, 9);
        var newval = "";
        if (areacode.length > 0) {
            newval += (areacode);
        }

        if (prefix.length > 0) {
            if (areacode.length === 3) newval += "-";
            newval += prefix;
        }


        if (suffix.length > 0) {
            if (prefix.length === 2) newval += "-";
            newval += (suffix);
        }

        return newval;
    }


    formatPhone(v: string): string {

        v = this.onlyNumbers(v);

        if (v.length >= 12) {
            v = v.substring(0, 12);
            return v;
        }

        var stripedv = this.onlyNumbers(v);
        var areacode = stripedv.substring(0, 3);
        var prefix = stripedv.substring(3, 6);
        var suffix = stripedv.substring(6, 10);
        var newval = "";
        if (areacode.length > 0) {
            newval += (areacode);
        }

        if (prefix.length > 0) {
            if (areacode.length === 3) newval += "-";
            newval += prefix;
        }


        if (suffix.length > 0) {
            if (prefix.length === 3) newval += "-";
            newval += (suffix);
        }

        return newval;
    }

    formatFein(v: string): string {
        if (v.length >= 11) {
            v = v.substring(0, 11);
            return v;
        }

        var stripedv = this.onlyNumbers(v);
        var areacode = stripedv.substring(0, 2);
        var prefix = stripedv.substring(2, 5);
        var suffix = stripedv.substring(5, 9);
        var newval = "";
        if (areacode.length > 0) {
            newval += (areacode);
        }

        if (prefix.length > 0) {
            if (areacode.length === 2) newval += "-";
            newval += prefix;
        }


        if (suffix.length > 0) {
            if (prefix.length === 2) newval += "";
            newval += (suffix);
        }
        return newval;
    }

    formatMonthYear(v: string): string {
        if (v.length >= 7) {
            v = v.substring(0, 7);
            return v;
        }

        var stripedv = this.onlyNumbers(v);
        var areacode = stripedv.substring(0, 2);
        var prefix = stripedv.substring(2, 5);
        var suffix = stripedv.substring(5, 9);
        var newval = "";
        if (areacode.length > 0) {
            newval += (areacode);
        }

        if (prefix.length > 0) {
            if (areacode.length === 2) newval += "/";
            newval += prefix;
        }


        if (suffix.length > 0) {
            if (prefix.length === 2) newval += "";
            newval += (suffix);
        }
        return newval;
    }

    formatDateFromMySQLToAmerican(v: string): string {
        if (v.length > 10) {
            v = v.substring(0, 10);
            return v;
        }

        var stripedv = this.onlyNumbers(v);
        var mm = stripedv.substring(4, 6);
        var dd = stripedv.substring(6, 8);
        var yyyy = stripedv.substring(0, 4);
        var newval = "";
        if (mm.length > 0) {
            newval += (mm);
        }

        if (dd.length > 0) {
            if (mm.length === 2) newval += "/";
            newval += dd;
        }


        if (yyyy.length > 0) {
            if (dd.length === 2) newval += "/";
            newval += (yyyy);
        }
        return newval;
    }
}

export default new StringUtils();