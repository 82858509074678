import { InputData } from "../../../util/ui/form/InputData";

export abstract class AbstractInfo {

    inputs: { [key: string]: InputData } = {};

    constructor(inputData: InputData[] = []) {

        for (var i in inputData) {
            this.inputs[inputData[i].name] = inputData[i];
        }
    }

    setDataByName(data: InputData) {
        this.inputs[data.name] = data;
    }

    getDataByName(name: string) {
        return this.inputs[name];
    }

    getDataValueByName(name: string) {
        if (typeof this.inputs[name] === 'undefined') {
            return '';
        }
        return this.inputs[name].value;
    }
}