import React from "react";
import { TextField, InputLabelProps, MenuItem } from "@material-ui/core";
import { FormInputPropertiesInterface } from "./FormInputProperties";
import { Option } from "../../SelectOptions";

interface BasicInputProps {
    inputProps: FormInputPropertiesInterface;
    error?: boolean;
    value?: string;
    onInputChange(inputInfo: InputInfo): void;
    otherEnabled?: boolean;
    options: Option[]
}

class BasicInputState {
    other: boolean = false;
    value: string = '';
}

export class InputInfo {
    name: string = '';
    value: string = '';
    label: string = '';
}

export class BasicSelect extends React.Component<BasicInputProps, BasicInputState> {

    protected inputInfo: InputInfo = new InputInfo();
    protected valList: string[] = [''];

    constructor(props: BasicInputProps) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);

        let state = new BasicInputState();

        for (var i in props.options) {
            this.valList.push(props.options[i].value);
        }
        if (props.value && this.valList.indexOf(props.value) < 0) {
            state.other = true;
        }

        this.state = state;
    }

    handleSelectChange(event: React.ChangeEvent<HTMLInputElement>) {

        this.inputInfo.value = event.target.value;
        this.setState({
            value: this.inputInfo.value
        })

        this.inputInfo.name = this.props.inputProps.name;
        this.inputInfo.label = this.props.inputProps.label;
        this.props.onInputChange(this.inputInfo);
    };

    render() {

        let inputLabelProps: InputLabelProps = {};

        inputLabelProps.shrink = true;

        let value = this.props.value;

        return <React.Fragment>


            <TextField
                onChange={this.handleSelectChange}
                label={this.props.inputProps.label}
                value={value || ''}
                variant="outlined"
                fullWidth
                error={this.props.error ? true : false}
                required={this.props.inputProps.required}
                select
                placeholder={this.props.inputProps.placeholder}
                InputLabelProps={inputLabelProps}
            >
                <MenuItem value=''>
                    Select One
                        </MenuItem>
                {this.props.options.map(o =>
                    <MenuItem key={o.value} value={o.value}>
                        {o.name}
                    </MenuItem>
                )}

            </TextField>


        </React.Fragment >;
    }
}

export default BasicSelect;