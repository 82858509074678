import React from "react";
import { EachOwnerFormInfo } from "../OwnerInfoForm";

import { Grid, Theme, createStyles, WithStyles, withStyles, Button, Card, CardHeader, CardContent, CardActions } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../../../../util/ui/form/FormInputProperties";
import { InputData } from "../../../../util/ui/form/InputData";
import BasicInput from "../../../../util/ui/form/inputs/BasicInput";
import { ErrorInfo } from "../../../../util/ui/form/ErrorInfo";

import { InputInfo } from "../../../../util/ui/form/AbstractBasicInput";
import { businessTitleOptionsPPP } from "../../../../util/SelectOptions";
import { Delete } from "@material-ui/icons";
import OtherSelect from "../../../../util/ui/form/OtherSelect";
import { SSNInput } from "../../../../util/ui/form/inputs/SSNInput";


export enum OwnerFormInputNames {

    name = 'name',
    businessTitle = 'businessTitle',
    ownershipPercent = 'ownershipPercent',
    socialSecNum = 'socialSecNum',
    address = 'address',
    homeZip = 'homeZip'
}

export const OWNER_FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: OwnerFormInputNames.name,
    label: 'Name',
    required: true,
    type: 'text',
    width: 12
}, {
    name: OwnerFormInputNames.businessTitle,
    label: 'Title / Office',
    required: true,
    width: 4
}, {
    name: OwnerFormInputNames.ownershipPercent,
    label: 'Ownership Percent',
    required: true,
    width: 4
}, {
    name: OwnerFormInputNames.socialSecNum,
    label: 'TIN (EIN/SSN)',
    required: true,
    width: 4
}, {
    name: OwnerFormInputNames.homeZip,
    label: 'Home Zip',
    required: true,
    width: 4
}, {
    name: OwnerFormInputNames.address,
    label: 'Home Street Address',
    required: true,
    width: 8
}];

let OWNER_FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in OWNER_FORM_PROPERTY_ARRAY) {
    OWNER_FORM_PROPERTY_LIST[OWNER_FORM_PROPERTY_ARRAY[i].name] = OWNER_FORM_PROPERTY_ARRAY[i];
}

export const OWNER_FORM_PROPERTY_MAP = OWNER_FORM_PROPERTY_LIST;

const styles = (theme: Theme) => createStyles({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    navigator: {

    },
    firstGuess: {
        // marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(2),
        // textAlign: 'center',
        justifyContent: 'center'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center',
    },
});

export interface EachOwnerFormProps extends WithStyles<typeof styles> {

    /**
     * identifiers
     */
    index: number;
    isDeletable: boolean;
    eachOwnerFormInfo: EachOwnerFormInfo;
    errorFields: Map<string, ErrorInfo>;
    onBasicInputChange(inputInfo: InputData, index: number): void;
    onRemoveOwner(index: number): void;

}

export class EachOwnerFormState {

}


export enum EachOwnerInternalSteps {
    Start, ConfirmPersonalInfoGuess, BriefFollowup, FullFollowUp, Followup
}

class EachOwnerForm extends React.Component<EachOwnerFormProps, EachOwnerFormState> {



    constructor(props: EachOwnerFormProps) {
        super(props);

        this.state = new EachOwnerFormState();
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleRemoveOwner = this.handleRemoveOwner.bind(this);

    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        this.props.onBasicInputChange(inputData, this.props.index);

    }

    handleRemoveOwner(): void {
        this.props.onRemoveOwner(this.props.index);
    }

    render() {

        let title = "Owner " + (this.props.index + 1).toString();
        let action = this.props.isDeletable ?
            <CardActions><Button variant='contained' color='secondary' onClick={this.handleRemoveOwner}><Delete></Delete> Remove Owner</Button></CardActions> : <React.Fragment></React.Fragment>;
        return <React.Fragment>
            <Card variant='outlined'>
                <CardHeader title={title}
                    action={
                        action
                    } >

                </CardHeader>
                <CardContent style={{
                    paddingTop: '0px'
                }}>

                    <Grid container spacing={2}>
                        {OWNER_FORM_PROPERTY_ARRAY.map((v, index) => {

                            let jsx = null;

                            switch (v.name) {


                                case OwnerFormInputNames.businessTitle:
                                    jsx = <OtherSelect
                                        inputProps={v}
                                        error={this.props.errorFields?.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(v.name)?.value}
                                        options={businessTitleOptionsPPP}
                                    />;
                                    break;

                                case OwnerFormInputNames.socialSecNum:
                                    jsx = <SSNInput
                                        inputProps={v}
                                        error={this.props.errorFields?.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(v.name)?.value}

                                    />;
                                    break;
                                default:

                                    jsx = <BasicInput
                                        inputProps={v}
                                        error={this.props.errorFields?.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.eachOwnerFormInfo.ownerInfo.getDataByName(v.name)?.value}
                                    />;
                                    break;
                            }


                            return <Grid key={index} item sm={v.width || 12} xs={12}>
                                {jsx}
                            </Grid>

                        })}

                    </Grid>

                </CardContent>
            </Card>

        </React.Fragment>
    }
}

export default withStyles(styles, { withTheme: true })(EachOwnerForm);