import {FORMAPI_BASE} from "../forms/merchant-form/BaseUtil";
import httpClient from "./HttpClient";

export interface MetaInfo {
    [s: string]: any
}

export interface MetaPackage {
    custom : MetaInfo,
    system ?: MetaInfo
}

class AppMetaUtils {

    saveAppMeta(formToken: string, m: MetaPackage) {

        return httpClient.post(FORMAPI_BASE + '/' + formToken + '/appMeta', m);
    }

}

export default new AppMetaUtils();