import React from 'react';
import { Grid, Theme, createStyles, WithStyles, withStyles, Chip } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone'
import { debounce } from 'lodash';

import { AWSError } from 'aws-sdk';
import { bucket, s3Instance } from '../../services/aws/s3';
import { openNotifier } from '../dialog/notifier/Notifier';
import { openLoader } from '../dialog/loader/Loader';
import { AttachFile } from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    },
    dropzone: {
        minHeight: 0,
        // height: '60px',
        '& input': {
            display: 'none'
        }
    },
    dropzoneParagraphClass: {
        // fontSize: 0
    }
});

interface UploadInputProps extends WithStyles<typeof styles> {
    label: JSX.Element;
    category: string;
    required: boolean;
    error: boolean;
    fileListUploaded: string[];
    formToken: string;
    urlToken: string;
    onFileupload?(files: UploadedFile[]): void;
    viewOwnly?: boolean;
}

class UploadInputState {
    uploadedFiles: File[] = []
}

export class UploadedFile {
    category: string = '';
    locationFilename: string = '';
    locationFolder: string = bucket || '';
    filename: string = '';

    static toObj(category: string, serverName: string, name: string): UploadedFile {
        let o = new UploadedFile();
        o.category = category;
        o.locationFilename = serverName;
        o.filename = name;
        return o;
    }
}

class UploadInput extends React.Component<UploadInputProps, UploadInputState> {

    onDropThrottled: (f: File) => void;

    constructor(props: UploadInputProps) {
        super(props);
        this.state = new UploadInputState();
        this.onDrop = this.onDrop.bind(this);
        this.onDropThrottled = debounce(this.onDrop, 1000);
    }

    onDrop(f: File) {

        let token = this.props.formToken;
        var promises = [];

        let uploadedFiles: UploadedFile[] = [];

        let promise = new Promise((resolve, reject) => {
            let servername = this.props.urlToken + '/apps/' + token + '/' + f.name + '_' + Math.random().toString();

            s3Instance.uploadFile(f, servername, (err: AWSError) => {
                if (err != null && err.statusCode !== 200) {
                    openNotifier("Error! Oops. Please Try Again");
                    reject();
                } else {
                    uploadedFiles.push(UploadedFile.toObj(this.props.category, servername, f.name));
                    resolve();
                }
            });

        })

        promises.push(promise);

        openLoader(true);

        Promise.all(promises).then((s) => {
            if (s.length === 1) {
                openNotifier("Uploaded");
            }
            if (typeof this.props.onFileupload !== 'undefined') {
                this.props.onFileupload(uploadedFiles)
            }
        }).catch((r) => {
            openNotifier("Some Files were not Uploaded. Please try again");
        }).finally(() => {
            openLoader(false);

        })
    }

    render() {

        return <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    {this.props.label}

                </Grid>


                {this.props.fileListUploaded?.length > 0 ?
                    <Grid item xs={12} style={{
                        paddingTop: '0'
                    }}>
                        {this.props.fileListUploaded?.map((fn, i) => {

                            return <React.Fragment key={i}>
                                <Chip size='medium' variant='default' color='primary' icon={<AttachFile />} style={{
                                    marginRight: '5px', marginBottom: '5px'
                                }} label={fn}></Chip>
                            </React.Fragment>
                        })}
                    </Grid>
                    : <React.Fragment>
                        {/* <br /> */}
                    </React.Fragment>}


                {this.props.viewOwnly ?

                    <React.Fragment>



                    </React.Fragment>
                    :

                    <React.Fragment>

                        <Grid item xs={12} sm={12}>
                            <DropzoneArea
                                maxFileSize={10 * 1024 * 1024}
                                dropzoneText={'Drag and Drop your ' + this.props.category + ' here or Click to upload'}
                                showPreviewsInDropzone={false}
                                dropzoneClass={this.props.classes.dropzone}
                                dropzoneParagraphClass={this.props.classes.dropzoneParagraphClass}
                                showAlerts={false}
                                onDrop={this.onDrop}
                                filesLimit={100}
                            ></DropzoneArea>
                        </Grid>

                    </React.Fragment>

                }

            </Grid >
        </React.Fragment >;
    }
}


export default withStyles(styles, { withTheme: true })(UploadInput);