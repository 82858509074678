import React from 'react';
import { ErrorInfo } from '../../../../util/ui/form/ErrorInfo';
import { AbstractInfo } from '../AbstractInfo';
import { InputData } from '../../../../util/ui/form/InputData';
import { TextField } from '@material-ui/core';

export const valueNameAddendumB = 'addendumB';

export class AddendumBInfo extends AbstractInfo {

}

interface AddendumBProps {
    addendumBInfo: AddendumBInfo;
    errorFields: Map<string, ErrorInfo>;
    onChange(input: InputData): void;
}

export class AddendumB extends React.Component<AddendumBProps> {

    constructor(props: AddendumBProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        let input = new InputData();
        input.label = 'Addendum B';
        input.name = valueNameAddendumB;
        input.value = event.currentTarget.value;
        this.props.onChange(input);
    }

    render() {
        return <React.Fragment>
            <TextField error={this.props.errorFields.get('addendumB')?.error} fullWidth rows={4} multiline variant="outlined"
                onChange={this.handleChange} value={this.props.addendumBInfo.getDataValueByName('addendumB') || ''}>
            </TextField>
        </React.Fragment>;
    }
}