import { S3 } from 'aws-sdk';

var accessKeyId = process.env['REACT_APP_S3_ACCESSKEY'];
var clientSecret = process.env['REACT_APP_S3_CLIENT_SECRET'];
var region = process.env['REACT_APP_S3_REGION'];
export const bucket = process.env['REACT_APP_S3_BUCKET'];
var endpoint = process.env['REACT_APP_S3_ENDPOINT'];

class S3Instance {
    bucket: string = bucket || '';

    s3: S3 = new S3();

    constructor(s3Props: S3.ClientConfiguration) {
        this.s3 = new S3(s3Props);
    }

    uploadFile(file: File, key: string, callback: any) {
        this.s3.putObject({
            Key: key,
            Bucket: this.bucket,
            Body: file
        }, callback);
    }
}


export const s3Instance = new S3Instance({
    apiVersion: '2006-03-01',
    accessKeyId: accessKeyId,
    endpoint: endpoint,
    s3BucketEndpoint: true,
    region: region,
    secretAccessKey: clientSecret,
    sslEnabled: true
});