import React from "react";
import { Link } from "@material-ui/core";

export class RadioCheckOption {
    name?: string = '';
    jsx: JSX.Element = <React.Fragment></React.Fragment>;
    value: string = '';

    static generateSimple(simple: string): RadioCheckOption {
        let o = new RadioCheckOption();
        o.jsx = <React.Fragment>{simple}</React.Fragment>;
        o.value = simple;
        return o;
    }

    static generateSimpleWithName(name: string, simple: string): RadioCheckOption {
        let o = new RadioCheckOption();
        o.jsx = <React.Fragment>{simple}</React.Fragment>;
        o.name = name;
        o.value = simple;
        return o;
    }

    static generateSimpleFromArray(arr: string[]): RadioCheckOption[] {
        let options: RadioCheckOption[] = [];
        for (var i in arr) {
            options.push(RadioCheckOption.generateSimple(arr[i]));
        }
        return options;
    }

    static generateJSX(simple: JSX.Element, value: string): RadioCheckOption {
        let o = new RadioCheckOption();
        o.jsx = <React.Fragment>{simple}</React.Fragment>;
        o.value = value;
        return o;
    }

}

let disclosure1: RadioCheckOption[] = [];
disclosure1.push(RadioCheckOption.generateSimple('Applicant is a business with not more than 500 employees.'));
disclosure1.push(RadioCheckOption.generateSimple('Applicant is an individual who operates under a sole proprietorship, with or without employees, or as an independent contractor.'));
disclosure1.push(RadioCheckOption.generateSimple('Applicant is a cooperative with not more than 500 employees.'));
disclosure1.push(RadioCheckOption.generateSimple('Applicant is an Employee Stock Ownership Plan (ESOP), as defined in 15 U.S.C. 632, with not more than 500 employees.'));
disclosure1.push(RadioCheckOption.generateSimple('Applicant is a tribal small business concern, as described in 15 U.S.C. 657a(b)(2)(C), with not more than 500 employees.'));
disclosure1.push(RadioCheckOption.generateJSX(<React.Fragment>
    Applicant is a business, including an agricultural cooperative, aquaculture enterprise, nursery, or producer cooperative, that is small under SBA Size Standards found at <Link href='https://www.sba.gov/size-standards'>https://www.sba.gov/size-standards</Link>.
</React.Fragment>,
    'Applicant is a business, including an agricultural cooperative, aquaculture enterprise, nursery, or producer cooperative, that is small under SBA Size Standards found at https://www.sba.gov/size-standards.'));
disclosure1.push(RadioCheckOption.generateJSX(<React.Fragment>
    Applicant is a business with more than 500 employees that is small under SBA Size Standards found at <Link href='https://www.sba.gov/size-standards'>https://www.sba.gov/size-standards.</Link>
</React.Fragment>, 'Applicant is a business with more than 500 employees that is small under SBA Size Standards found at https://www.sba.gov/size-standards.'));
disclosure1.push(RadioCheckOption.generateSimple('Applicant is a private non-profit organization that is a non-governmental agency or entity that currently has an effective ruling letter from the IRS granting tax exemption under sections 501(c),(d), or (e) of the Internal Revenue Code of 1954, or satisfactory evidence from the State that the non-revenue producing organization or entity is a non-profit one organized or doing business under State law, or a faith-based organization.'));

let disclosure2: RadioCheckOption[] = [];
disclosure2.push(RadioCheckOption.generateSimple('Applicant is not engaged in any illegal activity (as defined by Federal guidelines).'));
disclosure2.push(RadioCheckOption.generateSimple('No principal of the Applicant with a 50 percent or greater ownership interest is more than sixty (60) days delinquent on child support obligations.'));
disclosure2.push(RadioCheckOption.generateSimple('Applicant is not an agricultural enterprise (e.g., farm), other than an aquaculture enterprise, agricultural cooperative, or nursery.'));
disclosure2.push(RadioCheckOption.generateSimple('Applicant does not present live performances of a prurient sexual nature or derive directly or indirectly more than de minimis gross revenue through the sale of products or services, or the presentation of any depictions or displays, of a prurient sexual nature.'));
disclosure2.push(RadioCheckOption.generateSimple('Applicant does not derive more than one-third of gross annual revenue from legal gambling activities.'));
disclosure2.push(RadioCheckOption.generateSimple('Applicant is not in the business of lobbying.'));
disclosure2.push(RadioCheckOption.generateSimple('Applicant cannot be a state, local, or municipal government entity and cannot be a member of Congress.'));

export const nonProfitOpts: RadioCheckOption[] = RadioCheckOption.generateSimpleFromArray([
    'Yes', 'No'
]);

export const franchiseOps: RadioCheckOption[] = RadioCheckOption.generateSimpleFromArray([
    'Yes', 'No'
]);

let esignOptions1: RadioCheckOption[] = [];
esignOptions1.push(
    RadioCheckOption.generateSimpleWithName('c1', 'The Applicant was in operation on February 15, 2020 and had employees for whom it paid salaries and payroll taxes or paid independent contractors, as reported on Form(s) 1099-MISC.'),
    RadioCheckOption.generateSimpleWithName('c2', 'Current economic uncertainty makes this loan request necessary to support the ongoing operations of the Applicant.'),
    RadioCheckOption.generateSimpleWithName('c3', 'The funds will be used to retain workers and maintain payroll or make mortgage interest payments, lease payments, and utility payments, as specified under the Paycheck Protection Program Rule; I understand that if the funds are knowingly used for unauthorized purposes, the federal government may hold me legally liable, such as for charges of fraud.'),
    RadioCheckOption.generateSimpleWithName('c4', 'The  Applicant  will  provide  to  the  Lender  documentation  verifying  the  number  of  full-time  equivalent  employees  on  the  Applicant’s payroll as well as the dollar amounts of payroll costs, covered mortgage interest payments, covered rent payments, and covered utilities for the eight-week period following this loan.'),
    RadioCheckOption.generateSimpleWithName('c5', 'I  understand  that  loan  forgiveness  will  be  provided  for  the  sum  of  documented  payroll  costs,  covered  mortgage  interest  payments, covered rent payments, and covered utilities, and not more than 25% of the forgiven amount may be for non-payroll costs.'),
    RadioCheckOption.generateSimpleWithName('c6', 'During the period beginning on February 15, 2020 and ending on December 31, 2020, the Applicant has not and will not receive another loan under the Paycheck Protection Program.'),
    RadioCheckOption.generateSimpleWithName('c7', 'I further certify that the information provided in this application and the information provided in all supporting documents and forms is true and accurate in all material respects. I understand that knowingly making a false statement to obtain a guaranteed loan from SBA is punishable under the law, including under18 USC 1001 and 3571 by imprisonment of not more than five years and/or a fine of up to $250,000; under 15 USC 645 by imprisonment of not more than two years and/or a fine of not more than $5,000; and, if submitted to a federally insured institution, under 18 USC 1014 by imprisonment of not more than thirty years and/or a fine of not more than  $1,000,000.'),
    RadioCheckOption.generateSimpleWithName('c8', "I  acknowledge  that  the  lender  will  confirm  the  eligible  loan  amount  using  required  documents  submitted.  I  understand,  acknowledge and agree that the Lender can share any tax information that I have provided with SBA's authorized representatives, including  authorized  representatives  of  the  SBA  Office  of  Inspector  General,  for  the  purpose  of  compliance  with  SBA  Loan  Program Requirements and all SBA reviews."),
);

export const esignOptions = esignOptions1;

export const disclosure = disclosure1;
export const disclosureConfirms = disclosure2;
