import React from "react";
import { Grid, Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress, CardContent } from "@material-ui/core";
import { InputData } from "../../../util/ui/form/InputData";
import { AbstractInfo } from "./AbstractInfo";

import { InputInfo } from "../../../util/ui/form/AbstractBasicInput";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import _ from 'lodash';
import validator from "validator";
import httpClient from "../../../util/HttpClient";


import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import PrimaryContactForm, { PRIMARY_CONTACT_FORM_PROPERTY_ARRAY, PrimaryContactFormNames } from "./ppp/PrimaryContactForm";
import PayrollInfoForm, { PayrollInfoFormNames, PAYROLLINFO_FORM_PROPERTY_MAP } from "./ppp/PayrollInfoForm";
import { FORMAPI_BASE } from "../BaseUtils";


const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});



interface PPPStartFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    onFormTokenUpdate(token: string): void;
    formToken: string;
}

enum PPPStartFormSteps {
    Start
}

enum CurrentScreen {
    Loading, MainForm
}

export class PrimaryContactInfo extends AbstractInfo {

}

export class PayrollInfo extends AbstractInfo {

}

class PPPStartFormState {
    errorFields: Map<string, ErrorInfo> = new Map();
    primaryContactInfo: PrimaryContactInfo = new PrimaryContactInfo();
    errorFieldsPrimaryContactInfo: Map<string, ErrorInfo> = new Map();

    payrollInfo: PayrollInfo = new PayrollInfo();
    errorFieldsPayrollInfo: Map<string, ErrorInfo> = new Map();
    isLoanRequestDirty: boolean = false;

    currentScreen: number = CurrentScreen.Loading;

}

class PPPStartForm extends React.Component<PPPStartFormProps, PPPStartFormState> {

    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: PPPStartFormProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleBasicInputChangePayrollInfo = this.handleBasicInputChangePayrollInfo.bind(this);
        this.handleContinueClick = this.handleContinueClick.bind(this);

        this.state = new PPPStartFormState();
    }

    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });


        if (this.props.formToken !== 'new') {

            httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/ppp-start')
                .then((r) => {

                    let primaryContactInfo = r.data.data.primaryContactInfo;
                    let payrollInfo = r.data.data.payrollInfo;
                    this.refreshFormData(primaryContactInfo, payrollInfo);

                });
        } else {
            this.setState({
                currentScreen: CurrentScreen.MainForm
            });
        }

    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }

        let primaryContactInfo = _.clone(this.state.primaryContactInfo);

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        primaryContactInfo.setDataByName(inputData);

        this.setState({
            primaryContactInfo: primaryContactInfo
        });
    }

    handleBasicInputChangePayrollInfo(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }

        let payrollInfo = _.clone(this.state.payrollInfo);

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        if (payrollInfo !== null) {

            if (
                inputData.name === PayrollInfoFormNames.payrollAvg
                && inputData.value !== ''
                && !this.state.isLoanRequestDirty) {
                let l2 = new InputData();
                l2.name = PayrollInfoFormNames.loanRequest;
                l2.label = PAYROLLINFO_FORM_PROPERTY_MAP[l2.name].label;
                l2.value = Math.round((parseFloat(inputData.value) * 2.5)).toFixed(2);
                payrollInfo.setDataByName(l2);
            }
        }

        payrollInfo.setDataByName(inputData);

        if (inputData.name === PayrollInfoFormNames.loanRequest) {
            this.setState({
                isLoanRequestDirty: true,
                payrollInfo: payrollInfo
            })
        } else {

            this.setState({
                payrollInfo: payrollInfo
            });
        }

    }

    getCurrentStep(): number {

        return PPPStartFormSteps.Start;

    }

    refreshFormData(primaryContactInfo: PrimaryContactInfo, payrollInfo: PayrollInfo) {

        let inputs = [];
        for (var j in primaryContactInfo.inputs) {
            inputs.push(primaryContactInfo.inputs[j]);
        }

        let inputs1 = [];
        for (var jj in payrollInfo.inputs) {
            inputs1.push(payrollInfo.inputs[jj]);
        }

        this.setState({
            primaryContactInfo: new PrimaryContactInfo(inputs),
            payrollInfo: new PayrollInfo(inputs1),
            currentScreen: CurrentScreen.MainForm
        });
    }

    handleContinueClick(event: React.TouchEvent | React.MouseEvent): void {

        switch (this.getCurrentStep()) {

            case PPPStartFormSteps.Start:

                openLoader(true);

                httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
                    + '/ppp-start', this.state)
                    .then((r) => {
                        console.log(r);
                        this.props.onFormTokenUpdate(r.data.token);
                        this.props.onCompleteForm();
                    })
                    .catch((r) => {

                    }).finally(() => {
                        openLoader(false);
                    })

                break;

        }

    }

    validatePrimaryContactInfo(): boolean {


        let errors = new Map<string, ErrorInfo>();

        for (var i in PRIMARY_CONTACT_FORM_PROPERTY_ARRAY) {

            let name = PRIMARY_CONTACT_FORM_PROPERTY_ARRAY[i].name;
            let value = this.state.primaryContactInfo.getDataByName(name)?.value || '';

            if (PRIMARY_CONTACT_FORM_PROPERTY_ARRAY[i].required && value === '') {
                errors.set(name, new ErrorInfo(true));
            }

            switch (name) {
                case PrimaryContactFormNames.email:

                    if (!validator.isEmail(value)) {
                        errors.set(name, new ErrorInfo(true));
                    }

                    break;

                case PrimaryContactFormNames.phone:
                    if (!validator.isMobilePhone(value, 'en-US')) {
                        errors.set(name, new ErrorInfo(true));
                    }
                    break;

            }
        }

        this.setState({
            errorFieldsPrimaryContactInfo: errors
        });

        return !(errors.size > 0);

    }


    validateFields(): boolean {

        if (!this.validatePrimaryContactInfo()) {
            return false;
        }

        return true;
    }


    getMainFormRender() {
        switch (this.getCurrentStep()) {
            case PPPStartFormSteps.Start:
                return this.getFormJSX();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }
    }

    render() {

        switch (this.state.currentScreen) {
            case CurrentScreen.Loading:
                return <LinearProgress></LinearProgress>;
            case CurrentScreen.MainForm:
                return this.getMainFormRender();
            default:
                return <React.Fragment>
                    Error
                </React.Fragment>;
        }

    }

    getFormJSX(): JSX.Element {

        return <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PrimaryContactForm
                        data={this.state.primaryContactInfo}
                        onBasicInputChange={this.handleBasicInputChange}
                        errorFields={this.state.errorFieldsPrimaryContactInfo}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PayrollInfoForm
                        data={this.state.payrollInfo}
                        onBasicInputChange={this.handleBasicInputChangePayrollInfo}
                        errorFields={this.state.errorFieldsPayrollInfo}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CardContent style={{
                        paddingTop: '0',
                        paddingBottom: '0'
                    }}>
                        <Button variant="contained" color="primary" className={this.props.classes.firstGuessConfirmButton}
                            onClick={this.handleContinueClick} fullWidth>
                            Continue
                        </Button>
                    </CardContent>
                </Grid>
            </Grid>
        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(PPPStartForm));