import React from "react";

import { Grid, Theme, createStyles, WithStyles, withStyles, CardContent, Card, CardHeader } from "@material-ui/core";
import { FormInputPropertiesInterface } from "../../../../util/ui/form/FormInputProperties";
import { InputData } from "../../../../util/ui/form/InputData";
import BasicInput from "../../../../util/ui/form/inputs/BasicInput";
import { ErrorInfo } from "../../../../util/ui/form/ErrorInfo";

import { InputInfo } from "../../../../util/ui/form/AbstractBasicInput";
import PhoneInput from "../../../../util/ui/form/inputs/PhoneInput";

import { PrimaryContactInfo } from "../PPPStartForm";

export enum PrimaryContactFormNames {
    name = "name",
    email = "email",
    phone = "contact"
}

export const PRIMARY_CONTACT_FORM_PROPERTY_ARRAY: FormInputPropertiesInterface[] = [{
    name: PrimaryContactFormNames.name,
    label: 'Full Name',
    placeholder: 'John Doe',
    required: true,
    type: 'text',
    width: 6
}, {
    name: PrimaryContactFormNames.phone,
    label: 'Contact #',
    placeholder: 'Contact #',
    required: true,
    type: 'tel',
    width: 6
}, {
    name: PrimaryContactFormNames.email,
    label: 'Email Address',
    placeholder: 'john.doe@gmail.com',
    required: true,
    type: 'text',
    width: 12
}];

let PRIMARY_CONTACT_FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in PRIMARY_CONTACT_FORM_PROPERTY_ARRAY) {
    PRIMARY_CONTACT_FORM_PROPERTY_LIST[PRIMARY_CONTACT_FORM_PROPERTY_ARRAY[i].name] = PRIMARY_CONTACT_FORM_PROPERTY_ARRAY[i];
}

export const BAP_FORM_PROPERTY_MAP = PRIMARY_CONTACT_FORM_PROPERTY_LIST;

const styles = (theme: Theme) => createStyles({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    navigator: {

    },
});

export interface PrimaryContactFormProps extends WithStyles<typeof styles> {

    /**
     * identifiers
     */
    data: PrimaryContactInfo;
    errorFields: Map<string, ErrorInfo>;
    onBasicInputChange(inputInfo: InputData): void;
}

export class PrimaryContactFormState {

}

class PrimaryContactForm extends React.Component<PrimaryContactFormProps, PrimaryContactFormState> {

    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: PrimaryContactFormProps) {
        super(props);

        this.state = new PrimaryContactFormState();
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);

    }

    handleBasicInputChange(input: InputInfo) {

        if (this.errorFields.has(input.name)) {
            this.errorFields.delete(input.name);
            this.setState({
                errorFields: this.errorFields
            });
        }

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        this.props.onBasicInputChange(inputData);

    }

    getRequiredFields(): string[] {

        let required: string[] = [];

        for (var i in PRIMARY_CONTACT_FORM_PROPERTY_ARRAY) {
            if (PRIMARY_CONTACT_FORM_PROPERTY_ARRAY[i].required) {
                required.push(PRIMARY_CONTACT_FORM_PROPERTY_ARRAY[i].name);
            }
        }

        return required;
    }

    render() {

        return <React.Fragment>

            <Card elevation={0} style={{
                paddingTop: '0',
                paddingBottom: '0'
            }}>
                <CardHeader title="Your Primary Contact Information" subheader="This will be used in signing the application and all further communications"></CardHeader>
                <CardContent style={{ paddingBottom: '0' }}>
                    <Grid container spacing={2}>
                        {PRIMARY_CONTACT_FORM_PROPERTY_ARRAY.map((v, index) => {

                            let jsx = null;

                            switch (v.name) {

                                case PrimaryContactFormNames.phone:

                                    jsx = <PhoneInput
                                        inputProps={v}
                                        error={this.props.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.data.getDataByName(v.name)?.value}
                                    />;
                                    break;

                                default:

                                    jsx = <BasicInput
                                        inputProps={v}
                                        error={this.props.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.data.getDataByName(v.name)?.value}
                                    />;
                                    break;
                            }


                            return <Grid key={index} item xs={12} sm={v.width || 12}>
                                {jsx}
                            </Grid>

                        })}


                    </Grid>
                </CardContent>
            </Card>

        </React.Fragment>
    }
}

export default withStyles(styles, { withTheme: true })(PrimaryContactForm);