import React from 'react';
import { Theme, createStyles, withTheme, withStyles, WithStyles, Grid, Divider, Card, CardHeader, CardContent } from '@material-ui/core';
import { InputData } from '../../../../util/ui/form/InputData';
import { AbstractInfo } from '../AbstractInfo';
import QuestionYesNoRadio from '../../../../util/ui/form/inputs/QuestionYesNoRadio';
import { Alert } from '@material-ui/lab';
import { FormInputPropertiesInterface } from '../../../../util/ui/form/FormInputProperties';
import { ErrorInfo } from '../../../../util/ui/form/ErrorInfo';


export class Question {

    text: string = '';

    static fromSimple(text: string): Question {
        let q = new Question();
        q.text = text;
        return q;
    }

    static fromSimpleArray(text: string[]): Question[] {
        let t = [];
        for (var i in text) {
            t.push(Question.fromSimple(text[i]));
        }
        return t;
    }
}


let questions = Question.fromSimpleArray([
    'Is the Applicant or any owner of the Applicant presently suspended, debarred, proposed for debarment, declared ineligible, voluntarily excluded from participation in this transaction by any Federal department or agency, or presently involved in any bankruptcy?',
    'Has the Applicant, any owner of the Applicant, or any business owned or controlled by any of them, ever obtained a direct or guaranteed loan from SBA or any other Federal agency that is currently delinquent or has defaulted in the last 7 years and caused a loss to the government?',
    'Is the Applicant or any owner of the Applicant an owner of any other business, or have common management with, any other business? If yes, list all such businesses and describe the relationship on a separate sheet identified as addendum A.',
    'Has the Applicant received an SBA Economic Injury Disaster Loan between January 31, 2020 and April 3, 2020? If yes, provide details on a separate sheet identified as addendum B.',
    'Is the Applicant (if an individual) or any individual owning 20% or more of the equity of the Applicant subject to an indictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any jurisdiction, or presently incarcerated, or on probation or parole?',
    'Within the last 5 years, for any felony, has the Applicant (if an individual) or any owner of the Applicant 1) been convicted; 2) pleaded guilty; 3) pleaded nolo contendere; 4) been placed on pretrial diversion; or 5) been placed on any form of parole or probation (including probation before judgment)?',
    'Is the United States the principal place of residence for all employees of the Applicant included in the Applicant’s payroll calculation above?',
    'Is the Applicant a franchise that is listed in the SBA’s Franchise Directory?'
]);


let QUESTION_ARRAY: FormInputPropertiesInterface[] = [];

for (var i in questions) {
    QUESTION_ARRAY.push({
        name: 'question-' + (parseInt(i) + 1),
        label: (parseInt(i) + 1) + ". " + questions[i].text,
        required: true
    })
}

let FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {};
for (let i in QUESTION_ARRAY) {
    FORM_PROPERTY_LIST[QUESTION_ARRAY[i].name] = QUESTION_ARRAY[i];
}

export const QUESTION_PROPERTY_MAP = FORM_PROPERTY_LIST;
export const QUESTION_LIST = QUESTION_ARRAY;

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    }
});


export class Answers extends AbstractInfo {

}

interface QuestionsProps extends WithStyles<typeof styles> {

    data: Answers;
    errorFields: Map<string, ErrorInfo>;
    onBasicInputChange(inputInfo: InputData): void;
}

class QuestionsState {

}


class Questions extends React.Component<QuestionsProps, QuestionsState> {

    constructor(props: QuestionsProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
    }

    handleBasicInputChange(input: InputData, event?: React.ChangeEvent<HTMLElement>) {
        this.props.onBasicInputChange(input);
    }

    render() {
        return <React.Fragment>
            <Card variant='outlined'>
                <CardHeader title='Questions' style={{ paddingBottom: '0px' }}>

                </CardHeader>
                <CardContent>
                    <Grid container spacing={2}>
                        {QUESTION_ARRAY.map((v, index) => {

                            let jsx = null;

                            switch (index) {

                                default:

                                    jsx = <QuestionYesNoRadio
                                        inputProps={v}
                                        error={this.props.errorFields.get(v.name)?.error}
                                        onInputChange={this.handleBasicInputChange}
                                        value={this.props.data.getDataByName(v.name)?.value}
                                    />;
                                    break;
                            }


                            return <Grid key={index} item sm={v.width || 12}>
                                {index === 0 &&
                                    <React.Fragment>
                                        <Alert severity='warning' >
                                            If questions (1), (2), (5) or (6) below are answered “Yes,” the loan will not be approved.
                                        </Alert>
                                        <br />
                                    </React.Fragment>
                                }
                                {jsx}
                                {(index < (QUESTION_ARRAY.length - 1))
                                    &&
                                    <Divider style={{
                                        marginTop: '10px'
                                    }} />
                                }

                            </Grid>

                        })}


                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(Questions));