import AbstractBasicInput, { BasicInputProps, BasicInputState } from "../AbstractBasicInput";
import React from "react";
import { InputLabelProps, FormControl, RadioGroup, FormControlLabel, Radio, Grid, Typography } from "@material-ui/core";

export class QuestionYesNoRadio extends AbstractBasicInput<BasicInputProps, BasicInputState> {

    render() {

        let inputLabelProps: InputLabelProps = {};

        // inputLabelProps.variant = 'filled';
        if (this.props.inputProps.type === 'date')
            inputLabelProps.shrink = true;


        return <React.Fragment>

            <Grid container spacing={2}>
                <Grid item sm={9}>
                    <FormControl error={this.props.error ? true : false} component="fieldset" >
                        <Typography variant='body2' color={this.props.error ? 'error' : 'initial'}>
                            {this.props.inputProps.label}
                        </Typography>
                    </FormControl>
                </Grid>
                <Grid item sm={3}>
                    <RadioGroup row name={this.props.inputProps.name} value={this.props.value || ''} onChange={this.handleChange}>
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    </RadioGroup>
                </Grid>
            </Grid>

        </React.Fragment>;
    }
}

export default QuestionYesNoRadio;