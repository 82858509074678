import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import { ClientAppConfig, REPCONFIG_BUCKET_BASE } from './lib/util/AppUtil';
import { url_identifier } from './lib/util/FormApiUtils';
import { Alert } from '@material-ui/lab';
import WindowUtils from './lib/util/WindowUtils';


declare var client_app_config: ClientAppConfig;
const script = document.createElement("script");
script.async = true;
script.src = REPCONFIG_BUCKET_BASE + '/' + url_identifier + '/config.js';
document.head.appendChild(script);


if (url_identifier) {

  script.onload = () => {

    if (typeof client_app_config.formType === 'undefined') {
      client_app_config.formType = 'form';
    }

    let customScript = client_app_config.customScript;
    if (typeof customScript !== 'undefined' && customScript !== null && customScript.length > 0) {
      WindowUtils.addCustomScript(customScript);
    }

    ReactDOM.render(
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <App clientAppConfig={client_app_config} />

      </ThemeProvider>,
      document.querySelector('#root'),
    );

  };

} else {

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Alert severity='error'>404 Not Found</Alert>

    </ThemeProvider>,
    document.querySelector('#root'),
  );
}

