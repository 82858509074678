export class Option {
    name: string = '';
    value: string = '';

    static generateSimple(simple: string): Option {
        let o = new Option();
        o.name = simple;
        o.value = simple;
        return o;
    }

    static generateSimpleFromArray(arr: string[]): Option[] {
        let options: Option[] = [];
        for (var i in arr) {
            options.push(Option.generateSimple(arr[i]));
        }
        return options;
    }

    static generateKV(obj: {
        [name: string]: string
    }) {
        let options: Option[] = [];
        for (var i in obj) {
            let o = new Option();
            o.name = obj[i];
            o.value = i;
            options.push(o);
        }
        return options;
    }
}


export const entityTypeOptionsPPP: Option[] = Option.generateSimpleFromArray(["Sole proprietor", "Partnership", "C-Corp", "S-S-Corp", "LLC",
    "Independent contractor", "Eligible self-employed individual",
    "501(c)(3) nonprofit", "501(c)(19) veterans organization", "Tribal business (sec. 31(b)(2)(C) of Small Business Act)", "Other"]);


export const vendorTypeOptions: Option[] = Option.generateSimpleFromArray([
    'New',
    'Used'
]);

export const entityTypeOptionsMerchantForm: Option[] = Option.generateSimpleFromArray([
    'S-Corp',
    'C-Corp',
    'Limited Liability Company',
    'Limited Partnership',
    'Limited Liability Partnership',
    'Sole Proprietorship'
]);

export const stateKV : {
    [stateCode: string] : string
} = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    id: "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};
export const stateList: Option[] = Option.generateKV(stateKV);

export const lengthOfCurrentOwnershipKv: {
    [v: string]: string
} = {
    "Since Start of Business":"Since Start of Business",
    "< 6 Months":"Less Than 6 Months",
    "< 1 Year":"Less Than 1 Year",
    "1 Year":"1 Year",
    "2 Years":"2 Years",
    "3 Years":"3 Years",
    "4 Years":"4 Years",
    "5 Years":"5 Years",
    "6 Years":"6 Years",
    "7 Years":"7 Years",
    "8 Years":"8 Years",
    "9 Years":"9 Years",
    "10 Years":"10 Years",
    "11 Years":"11 Years",
    "12 Years":"12 Years",
    "13 Years":"13 Years",
    "14 Years":"14 Years",
    "15 Years":"15 Years",
    "16 Years":"16 Years",
    "17 Years":"17 Years",
    "18 Years":"18 Years",
    "19 Years":"19 Years",
    "20 Years":"20 Years",
    "21 Years":"21 Years",
    "22 Years":"22 Years",
    "23 Years":"23 Years",
    "24 Years":"24 Years",
    "25 Years":"25 Years",
    "26 Years":"26 Years",
    "27 Years":"27 Years",
    "28 Years":"28 Years",
    "29 Years":"29 Years",
    "30 Years":"30 Years",
    "31 Years":"31 Years",
    "32 Years":"32 Years",
    "33 Years":"33 Years",
    "34 Years":"34 Years",
    "35 Years":"35 Years",
    "36 Years":"36 Years",
    "37 Years":"37 Years",
    "38 Years":"38 Years",
    "39 Years":"39 Years",
    "40 Years":"40 Years",
    "41 Years":"41 Years",
    "42 Years":"42 Years",
    "43 Years":"43 Years",
    "44 Years":"44 Years",
    "45 Years":"45 Years",
    "46 Years":"46 Years",
    "47 Years":"47 Years",
    "48 Years":"48 Years",
    "49 Years":"49 Years",
    "50 Years":"50 Years",
    "> 50 Years":"More Than 50 Years"
};

export const lengthOfCurrentOwnershipList : Option[] = Option.generateKV(lengthOfCurrentOwnershipKv);

export const industryTypeOptions: Option[] = Option.generateSimpleFromArray([
    'Agriculture',
    'Automotive Repair',
    'Automotive Sales & Gas Service Stations',
    'Business Services',
    'Communications',
    'Construction & Contractors',
    'Eating & Drinking Places',
    'Educational Services',
    'Entertainment Services',
    'Faith Based Organization',
    'Finance',
    'Food & Beverage Stores',
    'Freight',
    'Health Services',
    'Hotels & Lodging',
    'Insurance',
    'Legal Services',
    'Manufacturing',
    'Mining & Natural Resource Extraction',
    'Miscellaneous Services',
    'Personal Services',
    'Real Estate',
    'Retail',
    'Storage',
    'Transportation',
    'Wholesale'
]);

export const propertyOwnershipOptions: Option[] = Option.generateSimpleFromArray([
    'Own',
    'Lease'
]);

export const vendorEquipmentType: Option[] = Option.generateSimpleFromArray([
    'New',
    'Used'
]);

export const authorizedPersonOptions: Option[] = Option.generateSimpleFromArray([
    'Yes',
    'No'
]);

export const cafOptions: Option[] = Option.generateSimpleFromArray([
    'Not Applicable'
]);

export const businessTitleOptionsMerchantForm: Option[] = Option.generateSimpleFromArray([
    'Owner',
    'CEO',
    'President'
]);

export const idTypeOptionsERC: Option[] = Option.generateSimpleFromArray([
    'Drivers License',
    'Federal ID',
    'Military ID',
    'State Issued ID'
]);

export const countryOptionsERC: Option[] = Option.generateSimpleFromArray([
    'USA',
    'Canada'
]);

export const businessTitleOptionsPPP: Option[] = Option.generateSimpleFromArray([
    'Owner',
    'Manager',
    'Accountant',
    'CEO',
    'CFO',
    'COO'
]);

export const purposeSelectOptions: Option[] = Option.generateSimpleFromArray([
    'Payroll',
    'Lease / Mortgage Interest',
    'Utilities'
]);

export const useOfProceedsOptions: Option[] = Option.generateSimpleFromArray([
    'Business Working Capital',
    'Business Expansion',
    'Purchase Inventory',
    'Purchase Equipment',
    'Project Financing',
    'Marketing',
    'Debt Consolidation',
    'Payroll'
]);

export const positionOptions: Option[] = Option.generateKV({
    0 : "No other open Loans/MCA"
});

export interface IndustryTypeNaics {
    id: string,
    name: string,
    group: string,
    sicCode: string,
    naicsCode: string
}

export const naicsList: IndustryTypeNaics[] = [
    {
        id: "303",
        name:"Abrasive Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3291",
        naicsCode: "327910"
    },
    {
        id: "264",
        name:"Adhesive Manufacturing",
        group: "Manufacturing",
        sicCode: "2891",
        naicsCode: "325520"
    },
    {
        id: "1046",
        name:"Administration of Air and Water Resource and Solid Waste Management Programs",
        group: "Public Administration",
        sicCode: "9511",
        naicsCode: "924110"
    },
    {
        id: "1047",
        name:"Administration of Conservation Programs",
        group: "Public Administration",
        sicCode: "9512",
        naicsCode: "924120"
    },
    {
        id: "1042",
        name:"Administration of Education Programs",
        group: "Public Administration",
        sicCode: "9411",
        naicsCode: "923110"
    },
    {
        id: "1050",
        name:"Administration of General Economic Programs",
        group: "Public Administration",
        sicCode: "9611",
        naicsCode: "926110"
    },
    {
        id: "1048",
        name:"Administration of Housing Programs",
        group: "Public Administration",
        sicCode: "9531",
        naicsCode: "925110"
    },
    {
        id: "1044",
        name:"Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",
        group: "Public Administration",
        sicCode: "9441",
        naicsCode: "923130"
    },
    {
        id: "1043",
        name:"Administration of Public Health Programs",
        group: "Public Administration",
        sicCode: "9431",
        naicsCode: "923120"
    },
    {
        id: "1049",
        name:"Administration of Urban Planning and Community and Rural Development",
        group: "Public Administration",
        sicCode: "9532",
        naicsCode: "925120"
    },
    {
        id: "1045",
        name:"Administration of Veterans' Affairs",
        group: "Public Administration",
        sicCode: "9451",
        naicsCode: "923140"
    },
    {
        id: "812",
        name:"Administrative Management and General Management Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8742",
        naicsCode: "541611"
    },
    {
        id: "823",
        name:"Advertising Agencies",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7311",
        naicsCode: "541810"
    },
    {
        id: "829",
        name:"Advertising Material Distribution Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7319",
        naicsCode: "541870"
    },
    {
        id: "949",
        name:"Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7389",
        naicsCode: "711410"
    },
    {
        id: "387",
        name:"Air and Gas Compressor Manufacturing",
        group: "Manufacturing",
        sicCode: "3563",
        naicsCode: "333912"
    },
    {
        id: "672",
        name:"Air Traffic Control",
        group: "#N/A",
        sicCode: "4581",
        naicsCode: "488111"
    },
    {
        id: "377",
        name:"Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "2499",
        naicsCode: "333415"
    },
    {
        id: "459",
        name:"Aircraft Engine and Engine Parts Manufacturing",
        group: "Manufacturing",
        sicCode: "3724",
        naicsCode: "336412"
    },
    {
        id: "458",
        name:"Aircraft Manufacturing",
        group: "Manufacturing",
        sicCode: "3721",
        naicsCode: "336411"
    },
    {
        id: "964",
        name:"All Other Amusement and Recreation Industries",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7911",
        naicsCode: "713990"
    },
    {
        id: "49",
        name:"All Other Animal Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "219",
        naicsCode: "112990"
    },
    {
        id: "988",
        name:"All Other Automotive Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7534",
        naicsCode: "811198"
    },
    {
        id: "251",
        name:"All Other Basic Organic Chemical Manufacturing",
        group: "Manufacturing",
        sicCode: "2869",
        naicsCode: "325199"
    },
    {
        id: "855",
        name:"All Other Business Support Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561499"
    },
    {
        id: "781",
        name:"All Other Consumer Goods Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7359",
        naicsCode: "532289"
    },
    {
        id: "235",
        name:"All Other Converted Paper Product Manufacturing",
        group: "Manufacturing",
        sicCode: "2675",
        naicsCode: "322299"
    },
    {
        id: "621",
        name:"All Other General Merchandise Stores",
        group: "#N/A",
        sicCode: "5331",
        naicsCode: "452319"
    },
    {
        id: "8",
        name:"All Other Grain Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "119",
        naicsCode: "111199"
    },
    {
        id: "601",
        name:"All Other Health and Personal Care Stores",
        group: "#N/A",
        sicCode: "5047",
        naicsCode: "446199"
    },
    {
        id: "579",
        name:"All Other Home Furnishings Stores",
        group: "#N/A",
        sicCode: "5719",
        naicsCode: "442299"
    },
    {
        id: "722",
        name:"All Other Information Services",
        group: "Information",
        sicCode: "7389",
        naicsCode: "519190"
    },
    {
        id: "757",
        name:"All Other Insurance Related Activities",
        group: "Finance and Insurance",
        sicCode: "6411",
        naicsCode: "524298"
    },
    {
        id: "210",
        name:"All Other Leather Good and Allied Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3131",
        naicsCode: "316998"
    },
    {
        id: "791",
        name:"All Other Legal Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541199"
    },
    {
        id: "75",
        name:"All Other Metal Ore Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1061",
        naicsCode: "212299"
    },
    {
        id: "921",
        name:"All Other Miscellaneous Ambulatory Health Care Services",
        group: "Health Care and Social Assistance",
        sicCode: "8099",
        naicsCode: "621999"
    },
    {
        id: "273",
        name:"All Other Miscellaneous Chemical Product and Preparation Manufacturing",
        group: "Manufacturing",
        sicCode: "2819",
        naicsCode: "325998"
    },
    {
        id: "30",
        name:"All Other Miscellaneous Crop Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "139",
        naicsCode: "111998"
    },
    {
        id: "442",
        name:"All Other Miscellaneous Electrical Equipment and Component Manufacturing",
        group: "Manufacturing",
        sicCode: "3629",
        naicsCode: "335999"
    },
    {
        id: "361",
        name:"All Other Miscellaneous Fabricated Metal Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3291",
        naicsCode: "332999"
    },
    {
        id: "180",
        name:"All Other Miscellaneous Food Manufacturing",
        group: "Manufacturing",
        sicCode: "2015",
        naicsCode: "311999"
    },
    {
        id: "400",
        name:"All Other Miscellaneous General Purpose Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "2599",
        naicsCode: "333999"
    },
    {
        id: "497",
        name:"All Other Miscellaneous Manufacturing",
        group: "Manufacturing",
        sicCode: "2499",
        naicsCode: "339999"
    },
    {
        id: "307",
        name:"All Other Miscellaneous Nonmetallic Mineral Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3272",
        naicsCode: "327999"
    },
    {
        id: "899",
        name:"All Other Miscellaneous Schools and Instruction",
        group: "Educational Services",
        sicCode: "7999",
        naicsCode: "611699"
    },
    {
        id: "630",
        name:"All Other Miscellaneous Store Retailers (except Tobacco Stores)",
        group: "#N/A",
        sicCode: "5085",
        naicsCode: "453998"
    },
    {
        id: "199",
        name:"All Other Miscellaneous Textile Product Mills",
        group: "#N/A",
        sicCode: "2299",
        naicsCode: "314999"
    },
    {
        id: "883",
        name:"All Other Miscellaneous Waste Management Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4959",
        naicsCode: "562998"
    },
    {
        id: "224",
        name:"All Other Miscellaneous Wood Product Manufacturing",
        group: "Manufacturing",
        sicCode: "2421",
        naicsCode: "321999"
    },
    {
        id: "734",
        name:"All Other Nondepository Credit Intermediation",
        group: "Finance and Insurance",
        sicCode: "5932",
        naicsCode: "522298"
    },
    {
        id: "87",
        name:"All Other Nonmetallic Mineral Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1499",
        naicsCode: "212399"
    },
    {
        id: "915",
        name:"All Other Outpatient Care Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8093",
        naicsCode: "621498"
    },
    {
        id: "1014",
        name:"All Other Personal Services",
        group: "Other Services (except Public Administration)",
        sicCode: "4899",
        naicsCode: "812990"
    },
    {
        id: "244",
        name:"All Other Petroleum and Coal Products Manufacturing",
        group: "Manufacturing",
        sicCode: "2999",
        naicsCode: "324199"
    },
    {
        id: "668",
        name:"All Other Pipeline Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4619",
        naicsCode: "486990"
    },
    {
        id: "284",
        name:"All Other Plastics Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3069",
        naicsCode: "326199"
    },
    {
        id: "836",
        name:"All Other Professional, Scientific, and Technical Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "4499",
        naicsCode: "541990"
    },
    {
        id: "697",
        name:"All Other Publishers",
        group: "Information",
        sicCode: "2741",
        naicsCode: "511199"
    },
    {
        id: "289",
        name:"All Other Rubber Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3061",
        naicsCode: "326299"
    },
    {
        id: "595",
        name:"All Other Specialty Food Stores",
        group: "#N/A",
        sicCode: "5143",
        naicsCode: "445299"
    },
    {
        id: "137",
        name:"All Other Specialty Trade Contractors",
        group: "Construction",
        sicCode: "1771",
        naicsCode: "238990"
    },
    {
        id: "684",
        name:"All Other Support Activities for Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4729",
        naicsCode: "488999"
    },
    {
        id: "872",
        name:"All Other Support Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7299",
        naicsCode: "561990"
    },
    {
        id: "717",
        name:"All Other Telecommunications",
        group: "Information",
        sicCode: "4899",
        naicsCode: "517919"
    },
    {
        id: "664",
        name:"All Other Transit and Ground Passenger Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4111",
        naicsCode: "485999"
    },
    {
        id: "469",
        name:"All Other Transportation Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3799",
        naicsCode: "336999"
    },
    {
        id: "859",
        name:"All Other Travel Arrangement and Reservation Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4729",
        naicsCode: "561599"
    },
    {
        id: "968",
        name:"All Other Traveler Accommodation",
        group: "Accommodation and Food Services",
        sicCode: "7011",
        naicsCode: "721199"
    },
    {
        id: "312",
        name:"Alumina Refining and Primary Aluminum Production",
        group: "#N/A",
        sicCode: "2819",
        naicsCode: "331313"
    },
    {
        id: "324",
        name:"Aluminum Foundries (except Die-Casting)",
        group: "#N/A",
        sicCode: "3365",
        naicsCode: "331524"
    },
    {
        id: "314",
        name:"Aluminum Sheet, Plate, and Foil Manufacturing",
        group: "Manufacturing",
        sicCode: "3353",
        naicsCode: "331315"
    },
    {
        id: "919",
        name:"Ambulance Services",
        group: "Health Care and Social Assistance",
        sicCode: "4119",
        naicsCode: "621910"
    },
    {
        id: "1033",
        name:"American Indian and Alaska Native Tribal Governments",
        group: "Public Administration",
        sicCode: "8641",
        naicsCode: "921150"
    },
    {
        id: "358",
        name:"Ammunition (except Small Arms) Manufacturing",
        group: "Manufacturing",
        sicCode: "3483",
        naicsCode: "332993"
    },
    {
        id: "955",
        name:"Amusement and Theme Parks",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7996",
        naicsCode: "713110"
    },
    {
        id: "956",
        name:"Amusement Arcades",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7993",
        naicsCode: "713120"
    },
    {
        id: "420",
        name:"Analytical Laboratory Instrument Manufacturing",
        group: "Manufacturing",
        sicCode: "3826",
        naicsCode: "334516"
    },
    {
        id: "162",
        name:"Animal (except Poultry) Slaughtering",
        group: "#N/A",
        sicCode: "751",
        naicsCode: "311611"
    },
    {
        id: "69",
        name:"Anthracite Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1231",
        naicsCode: "212113"
    },
    {
        id: "46",
        name:"Apiculture",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "279",
        naicsCode: "112910"
    },
    {
        id: "206",
        name:"Apparel Accessories and Other Apparel Manufacturing",
        group: "Manufacturing",
        sicCode: "2323",
        naicsCode: "315990"
    },
    {
        id: "13",
        name:"Apple Orchards",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "175",
        naicsCode: "111331"
    },
    {
        id: "995",
        name:"Appliance Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7623",
        naicsCode: "811412"
    },
    {
        id: "892",
        name:"Apprenticeship Training",
        group: "Educational Services",
        sicCode: "8249",
        naicsCode: "611513"
    },
    {
        id: "796",
        name:"Architectural Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8712",
        naicsCode: "541310"
    },
    {
        id: "862",
        name:"Armored Car Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7381",
        naicsCode: "561613"
    },
    {
        id: "627",
        name:"Art Dealers",
        group: "#N/A",
        sicCode: "5999",
        naicsCode: "453920"
    },
    {
        id: "254",
        name:"Artificial and Synthetic Fibers and Filaments Manufacturing",
        group: "Manufacturing",
        sicCode: "2823",
        naicsCode: "325220"
    },
    {
        id: "241",
        name:"Asphalt Paving Mixture and Block Manufacturing",
        group: "Manufacturing",
        sicCode: "2951",
        naicsCode: "324121"
    },
    {
        id: "242",
        name:"Asphalt Shingle and Coating Materials Manufacturing",
        group: "Manufacturing",
        sicCode: "2952",
        naicsCode: "324122"
    },
    {
        id: "929",
        name:"Assisted Living Facilities for the Elderly",
        group: "Health Care and Social Assistance",
        sicCode: "8361",
        naicsCode: "623312"
    },
    {
        id: "407",
        name:"Audio and Video Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3651",
        naicsCode: "334310"
    },
    {
        id: "416",
        name:"Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use",
        group: "Manufacturing",
        sicCode: "3822",
        naicsCode: "334512"
    },
    {
        id: "498",
        name:"Automobile and Other Motor Vehicle Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5012",
        naicsCode: "423110"
    },
    {
        id: "898",
        name:"Automobile Driving Schools",
        group: "Educational Services",
        sicCode: "8299",
        naicsCode: "611692"
    },
    {
        id: "443",
        name:"Automobile Manufacturing",
        group: "Manufacturing",
        sicCode: "3711",
        naicsCode: "336111"
    },
    {
        id: "984",
        name:"Automotive Body, Paint, and Interior Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7532",
        naicsCode: "811121"
    },
    {
        id: "981",
        name:"Automotive Exhaust System Repair",
        group: "Other Services (except Public Administration)",
        sicCode: "7533",
        naicsCode: "811112"
    },
    {
        id: "985",
        name:"Automotive Glass Replacement Shops",
        group: "Other Services (except Public Administration)",
        sicCode: "7536",
        naicsCode: "811122"
    },
    {
        id: "986",
        name:"Automotive Oil Change and Lubrication Shops",
        group: "Other Services (except Public Administration)",
        sicCode: "7549",
        naicsCode: "811191"
    },
    {
        id: "574",
        name:"Automotive Parts and Accessories Stores",
        group: "#N/A",
        sicCode: "5013",
        naicsCode: "441310"
    },
    {
        id: "982",
        name:"Automotive Transmission Repair",
        group: "Other Services (except Public Administration)",
        sicCode: "7537",
        naicsCode: "811113"
    },
    {
        id: "593",
        name:"Baked Goods Stores",
        group: "#N/A",
        sicCode: "5461",
        naicsCode: "445291"
    },
    {
        id: "356",
        name:"Ball and Roller Bearing Manufacturing",
        group: "Manufacturing",
        sicCode: "3562",
        naicsCode: "332991"
    },
    {
        id: "999",
        name:"Barber Shops",
        group: "Other Services (except Public Administration)",
        sicCode: "7241",
        naicsCode: "812111"
    },
    {
        id: "408",
        name:"Bare Printed Circuit Board Manufacturing ",
        group: "Manufacturing",
        sicCode: "3672",
        naicsCode: "334412"
    },
    {
        id: "1000",
        name:"Beauty Salons",
        group: "Other Services (except Public Administration)",
        sicCode: "7231",
        naicsCode: "812112"
    },
    {
        id: "967",
        name:"Bed-and-Breakfast Inns",
        group: "Accommodation and Food Services",
        sicCode: "7011",
        naicsCode: "721191"
    },
    {
        id: "31",
        name:"Beef Cattle Ranching and Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "212",
        naicsCode: "112111"
    },
    {
        id: "559",
        name:"Beer and Ale Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5181",
        naicsCode: "424810"
    },
    {
        id: "596",
        name:"Beer, Wine, and Liquor Stores",
        group: "#N/A",
        sicCode: "5181",
        naicsCode: "445310"
    },
    {
        id: "147",
        name:"Beet Sugar Manufacturing",
        group: "Manufacturing",
        sicCode: "2063",
        naicsCode: "311313"
    },
    {
        id: "16",
        name:"Berry (except Strawberry) Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "171",
        naicsCode: "111334"
    },
    {
        id: "262",
        name:"Biological Product (except Diagnostic) Manufacturing",
        group: "Manufacturing",
        sicCode: "2836",
        naicsCode: "325414"
    },
    {
        id: "99",
        name:"Biomass Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221117"
    },
    {
        id: "67",
        name:"Bituminous Coal and Lignite Surface Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1221",
        naicsCode: "212111"
    },
    {
        id: "68",
        name:"Bituminous Coal Underground Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1222",
        naicsCode: "212112"
    },
    {
        id: "423",
        name:"Blank Magnetic and Optical Recording Media Manufacturing",
        group: "Manufacturing",
        sicCode: "3577",
        naicsCode: "334613"
    },
    {
        id: "481",
        name:"Blind and Shade Manufacturing",
        group: "Manufacturing",
        sicCode: "2591",
        naicsCode: "337920"
    },
    {
        id: "920",
        name:"Blood and Organ Banks",
        group: "Health Care and Social Assistance",
        sicCode: "8099",
        naicsCode: "621991"
    },
    {
        id: "466",
        name:"Boat Building",
        group: "#N/A",
        sicCode: "3069",
        naicsCode: "336612"
    },
    {
        id: "572",
        name:"Boat Dealers",
        group: "#N/A",
        sicCode: "5551",
        naicsCode: "441222"
    },
    {
        id: "348",
        name:"Bolt, Nut, Screw, Rivet, and Washer Manufacturing",
        group: "Manufacturing",
        sicCode: "3429",
        naicsCode: "332722"
    },
    {
        id: "694",
        name:"Book Publishers",
        group: "Information",
        sicCode: "2731",
        naicsCode: "511130"
    },
    {
        id: "617",
        name:"Book Stores",
        group: "#N/A",
        sicCode: "5192",
        naicsCode: "451211"
    },
    {
        id: "562",
        name:"Book, Periodical, and Newspaper Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5192",
        naicsCode: "424920"
    },
    {
        id: "238",
        name:"Books Printing",
        group: "#N/A",
        sicCode: "2732",
        naicsCode: "323117"
    },
    {
        id: "182",
        name:"Bottled Water Manufacturing",
        group: "Manufacturing",
        sicCode: "2086",
        naicsCode: "312112"
    },
    {
        id: "963",
        name:"Bowling Centers",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7933",
        naicsCode: "713950"
    },
    {
        id: "146",
        name:"Breakfast Cereal Manufacturing",
        group: "Manufacturing",
        sicCode: "2043",
        naicsCode: "311230"
    },
    {
        id: "184",
        name:"Breweries",
        group: "#N/A",
        sicCode: "2082",
        naicsCode: "312120"
    },
    {
        id: "505",
        name:"Brick, Stone, and Related Construction Material Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5032",
        naicsCode: "423320"
    },
    {
        id: "189",
        name:"Broadwoven Fabric Mills",
        group: "#N/A",
        sicCode: "2211",
        naicsCode: "313210"
    },
    {
        id: "37",
        name:"Broilers and Other Meat Type Chicken Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "251",
        naicsCode: "112320"
    },
    {
        id: "495",
        name:"Broom, Brush, and Mop Manufacturing",
        group: "Manufacturing",
        sicCode: "2392",
        naicsCode: "339994"
    },
    {
        id: "800",
        name:"Building Inspection Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541350"
    },
    {
        id: "496",
        name:"Burial Casket Manufacturing",
        group: "Manufacturing",
        sicCode: "3995",
        naicsCode: "339995"
    },
    {
        id: "656",
        name:"Bus and Other Motor Vehicle Transit Systems",
        group: "#N/A",
        sicCode: "4111",
        naicsCode: "485113"
    },
    {
        id: "887",
        name:"Business and Secretarial Schools",
        group: "Educational Services",
        sicCode: "8244",
        naicsCode: "611410"
    },
    {
        id: "1023",
        name:"Business Associations",
        group: "Other Services (except Public Administration)",
        sicCode: "8611",
        naicsCode: "813910"
    },
    {
        id: "567",
        name:"Business to Business Electronic Markets",
        group: "Wholesale Trade",
        sicCode: "5012",
        naicsCode: "425110"
    },
    {
        id: "712",
        name:"Cable and Other Subscription Programming",
        group: "Information",
        sicCode: "4841",
        naicsCode: "515210"
    },
    {
        id: "978",
        name:"Cafeterias, Grill Buffets, and Buffets",
        group: "Accommodation and Food Services",
        sicCode: "5812",
        naicsCode: "722514"
    },
    {
        id: "148",
        name:"Cane Sugar Manufacturing",
        group: "Manufacturing",
        sicCode: "2061",
        naicsCode: "311314"
    },
    {
        id: "410",
        name:"Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing",
        group: "Manufacturing",
        sicCode: "3675",
        naicsCode: "334416"
    },
    {
        id: "987",
        name:"Car Washes",
        group: "Other Services (except Public Administration)",
        sicCode: "7542",
        naicsCode: "811192"
    },
    {
        id: "441",
        name:"Carbon and Graphite Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3624",
        naicsCode: "335991"
    },
    {
        id: "195",
        name:"Carpet and Rug Mills",
        group: "#N/A",
        sicCode: "2273",
        naicsCode: "314110"
    },
    {
        id: "868",
        name:"Carpet and Upholstery Cleaning Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7217",
        naicsCode: "561740"
    },
    {
        id: "966",
        name:"Casino Hotels",
        group: "Accommodation and Food Services",
        sicCode: "7011",
        naicsCode: "721120"
    },
    {
        id: "957",
        name:"Casinos (except Casino Hotels)",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7999",
        naicsCode: "713210"
    },
    {
        id: "973",
        name:"Caterers",
        group: "Accommodation and Food Services",
        sicCode: "5812",
        naicsCode: "722320"
    },
    {
        id: "32",
        name:"Cattle Feedlots",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "211",
        naicsCode: "112112"
    },
    {
        id: "296",
        name:"Cement Manufacturing",
        group: "Manufacturing",
        sicCode: "3241",
        naicsCode: "327310"
    },
    {
        id: "1005",
        name:"Cemeteries and Crematories",
        group: "Other Services (except Public Administration)",
        sicCode: "6531",
        naicsCode: "812220"
    },
    {
        id: "662",
        name:"Charter Bus Industry",
        group: "#N/A",
        sicCode: "4141",
        naicsCode: "485510"
    },
    {
        id: "159",
        name:"Cheese Manufacturing",
        group: "Manufacturing",
        sicCode: "2022",
        naicsCode: "311513"
    },
    {
        id: "36",
        name:"Chicken Egg Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "252",
        naicsCode: "112310"
    },
    {
        id: "931",
        name:"Child and Youth Services",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624110"
    },
    {
        id: "939",
        name:"Child Day Care Services",
        group: "Health Care and Social Assistance",
        sicCode: "8351",
        naicsCode: "624410"
    },
    {
        id: "606",
        name:"Children's and Infants' Clothing Stores",
        group: "#N/A",
        sicCode: "5137",
        naicsCode: "448130"
    },
    {
        id: "150",
        name:"Chocolate and Confectionery Manufacturing from Cacao Beans",
        group: "Manufacturing",
        sicCode: "2066",
        naicsCode: "311351"
    },
    {
        id: "12",
        name:"Citrus (except Orange) Groves",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "174",
        naicsCode: "111320"
    },
    {
        id: "1022",
        name:"Civic and Social Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8641",
        naicsCode: "813410"
    },
    {
        id: "755",
        name:"Claims Adjusting",
        group: "Finance and Insurance",
        sicCode: "6411",
        naicsCode: "524291"
    },
    {
        id: "83",
        name:"Clay and Ceramic and Refractory Minerals Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1459",
        naicsCode: "212325"
    },
    {
        id: "291",
        name:"Clay Building Material and Refractories Manufacturing",
        group: "Manufacturing",
        sicCode: "3251",
        naicsCode: "327120"
    },
    {
        id: "608",
        name:"Clothing Accessories Stores",
        group: "#N/A",
        sicCode: "5611",
        naicsCode: "448150"
    },
    {
        id: "516",
        name:"Coal and Other Mineral and Ore Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5052",
        naicsCode: "423520"
    },
    {
        id: "644",
        name:"Coastal and Great Lakes Freight Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4424",
        naicsCode: "483113"
    },
    {
        id: "645",
        name:"Coastal and Great Lakes Passenger Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4481",
        naicsCode: "483114"
    },
    {
        id: "175",
        name:"Coffee and Tea Manufacturing",
        group: "Manufacturing",
        sicCode: "2043",
        naicsCode: "311920"
    },
    {
        id: "1006",
        name:"Coin-Operated Laundries and Drycleaners",
        group: "Other Services (except Public Administration)",
        sicCode: "7215",
        naicsCode: "812310"
    },
    {
        id: "851",
        name:"Collection Agencies",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7322",
        naicsCode: "561440"
    },
    {
        id: "886",
        name:"Colleges, Universities, and Professional Schools",
        group: "Educational Services",
        sicCode: "8221",
        naicsCode: "611310"
    },
    {
        id: "783",
        name:"Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing",
        group: "Real Estate and Rental and Leasing",
        sicCode: "4499",
        naicsCode: "532411"
    },
    {
        id: "993",
        name:"Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7623",
        naicsCode: "811310"
    },
    {
        id: "112",
        name:"Commercial and Institutional Building Construction",
        group: "Construction",
        sicCode: "1522",
        naicsCode: "236220"
    },
    {
        id: "168",
        name:"Commercial Bakeries",
        group: "#N/A",
        sicCode: "2051",
        naicsCode: "311812"
    },
    {
        id: "724",
        name:"Commercial Banking",
        group: "Finance and Insurance",
        sicCode: "6021",
        naicsCode: "522110"
    },
    {
        id: "833",
        name:"Commercial Photography",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7335",
        naicsCode: "541922"
    },
    {
        id: "236",
        name:"Commercial Printing (except Screen and Books)",
        group: "#N/A",
        sicCode: "2752",
        naicsCode: "323111"
    },
    {
        id: "237",
        name:"Commercial Screen Printing",
        group: "#N/A",
        sicCode: "2396",
        naicsCode: "323113"
    },
    {
        id: "427",
        name:"Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing",
        group: "Manufacturing",
        sicCode: "3646",
        naicsCode: "335122"
    },
    {
        id: "741",
        name:"Commodity Contracts Brokerage",
        group: "Finance and Insurance",
        sicCode: "6221",
        naicsCode: "523140"
    },
    {
        id: "740",
        name:"Commodity Contracts Dealing",
        group: "Finance and Insurance",
        sicCode: "6099",
        naicsCode: "523130"
    },
    {
        id: "991",
        name:"Communication Equipment Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7622",
        naicsCode: "811213"
    },
    {
        id: "934",
        name:"Community Food Services",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624210"
    },
    {
        id: "655",
        name:"Commuter Rail Systems",
        group: "#N/A",
        sicCode: "4111",
        naicsCode: "485112"
    },
    {
        id: "510",
        name:"Computer and Computer Peripheral Equipment and Software Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5045",
        naicsCode: "423430"
    },
    {
        id: "990",
        name:"Computer and Office Machine Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7378",
        naicsCode: "811212"
    },
    {
        id: "810",
        name:"Computer Facilities Management Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7376",
        naicsCode: "541513"
    },
    {
        id: "402",
        name:"Computer Storage Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3572",
        naicsCode: "334112"
    },
    {
        id: "809",
        name:"Computer Systems Design Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7373",
        naicsCode: "541512"
    },
    {
        id: "403",
        name:"Computer Terminal and Other Computer Peripheral Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3575",
        naicsCode: "334118"
    },
    {
        id: "888",
        name:"Computer Training",
        group: "Educational Services",
        sicCode: "8243",
        naicsCode: "611420"
    },
    {
        id: "298",
        name:"Concrete Block and Brick Manufacturing",
        group: "Manufacturing",
        sicCode: "3251",
        naicsCode: "327331"
    },
    {
        id: "299",
        name:"Concrete Pipe Manufacturing",
        group: "Manufacturing",
        sicCode: "3272",
        naicsCode: "327332"
    },
    {
        id: "594",
        name:"Confectionery and Nut Stores",
        group: "#N/A",
        sicCode: "5145",
        naicsCode: "445292"
    },
    {
        id: "151",
        name:"Confectionery Manufacturing from Purchased Chocolate",
        group: "Manufacturing",
        sicCode: "2064",
        naicsCode: "311352"
    },
    {
        id: "547",
        name:"Confectionery Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5145",
        naicsCode: "424450"
    },
    {
        id: "524",
        name:"Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5082",
        naicsCode: "423810"
    },
    {
        id: "364",
        name:"Construction Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3531",
        naicsCode: "333120"
    },
    {
        id: "80",
        name:"Construction Sand and Gravel Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1442",
        naicsCode: "212321"
    },
    {
        id: "784",
        name:"Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7353",
        naicsCode: "532412"
    },
    {
        id: "776",
        name:"Consumer Electronics and Appliances Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7359",
        naicsCode: "532210"
    },
    {
        id: "989",
        name:"Consumer Electronics Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7622",
        naicsCode: "811211"
    },
    {
        id: "730",
        name:"Consumer Lending",
        group: "Finance and Insurance",
        sicCode: "6141",
        naicsCode: "522291"
    },
    {
        id: "928",
        name:"Continuing Care Retirement Communities",
        group: "Health Care and Social Assistance",
        sicCode: "8051",
        naicsCode: "623311"
    },
    {
        id: "589",
        name:"Convenience Stores",
        group: "#N/A",
        sicCode: "5411",
        naicsCode: "445120"
    },
    {
        id: "871",
        name:"Convention and Trade Show Organizers",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561920"
    },
    {
        id: "858",
        name:"Convention and Visitors Bureaus",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561591"
    },
    {
        id: "390",
        name:"Conveyor and Conveying Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3523",
        naicsCode: "333922"
    },
    {
        id: "170",
        name:"Cookie and Cracker Manufacturing",
        group: "Manufacturing",
        sicCode: "2052",
        naicsCode: "311821"
    },
    {
        id: "317",
        name:"Copper Rolling, Drawing, Extruding, and Alloying",
        group: "#N/A",
        sicCode: "3341",
        naicsCode: "331420"
    },
    {
        id: "73",
        name:"Copper, Nickel, Lead, and Zinc Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1021",
        naicsCode: "212230"
    },
    {
        id: "5",
        name:"Corn Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "115",
        naicsCode: "111150"
    },
    {
        id: "839",
        name:"Corporate, Subsidiary, and Regional Managing Offices",
        group: "Management of Companies and Enterprises",
        sicCode: "Aux",
        naicsCode: "551114"
    },
    {
        id: "1038",
        name:"Correctional Institutions",
        group: "Public Administration",
        sicCode: "9223",
        naicsCode: "922140"
    },
    {
        id: "229",
        name:"Corrugated and Solid Fiber Box Manufacturing",
        group: "Manufacturing",
        sicCode: "2653",
        naicsCode: "322211"
    },
    {
        id: "598",
        name:"Cosmetics, Beauty Supplies, and Perfume Stores",
        group: "#N/A",
        sicCode: "5087",
        naicsCode: "446120"
    },
    {
        id: "890",
        name:"Cosmetology and Barber Schools",
        group: "Educational Services",
        sicCode: "7231",
        naicsCode: "611511"
    },
    {
        id: "25",
        name:"Cotton Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "131",
        naicsCode: "111920"
    },
    {
        id: "57",
        name:"Cotton Ginning",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "724",
        naicsCode: "115111"
    },
    {
        id: "686",
        name:"Couriers and Express Delivery Services",
        group: "#N/A",
        sicCode: "4215",
        naicsCode: "492110"
    },
    {
        id: "854",
        name:"Court Reporting and Stenotype Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7338",
        naicsCode: "561492"
    },
    {
        id: "1035",
        name:"Courts",
        group: "Public Administration",
        sicCode: "9211",
        naicsCode: "922110"
    },
    {
        id: "158",
        name:"Creamery Butter Manufacturing",
        group: "Manufacturing",
        sicCode: "2021",
        naicsCode: "311512"
    },
    {
        id: "852",
        name:"Credit Bureaus",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7323",
        naicsCode: "561450"
    },
    {
        id: "728",
        name:"Credit Card Issuing",
        group: "Finance and Insurance",
        sicCode: "6021",
        naicsCode: "522210"
    },
    {
        id: "726",
        name:"Credit Unions",
        group: "Finance and Insurance",
        sicCode: "6061",
        naicsCode: "522130"
    },
    {
        id: "59",
        name:"Crop Harvesting, Primarily by Machine",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "722",
        naicsCode: "115113"
    },
    {
        id: "65",
        name:"Crude Petroleum Extraction ",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1311",
        naicsCode: "211120"
    },
    {
        id: "78",
        name:"Crushed and Broken Granite Mining and Quarrying",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1423",
        naicsCode: "212313"
    },
    {
        id: "77",
        name:"Crushed and Broken Limestone Mining and Quarrying",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1422",
        naicsCode: "212312"
    },
    {
        id: "439",
        name:"Current-Carrying Wiring Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3643",
        naicsCode: "335931"
    },
    {
        id: "196",
        name:"Curtain and Linen Mills",
        group: "#N/A",
        sicCode: "2391",
        naicsCode: "314120"
    },
    {
        id: "477",
        name:"Custom Architectural Woodwork and Millwork Manufacturing",
        group: "Manufacturing",
        sicCode: "2541",
        naicsCode: "337212"
    },
    {
        id: "271",
        name:"Custom Compounding of Purchased Resins",
        group: "#N/A",
        sicCode: "3087",
        naicsCode: "325991"
    },
    {
        id: "808",
        name:"Custom Computer Programming Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7371",
        naicsCode: "541511"
    },
    {
        id: "328",
        name:"Custom Roll Forming",
        group: "#N/A",
        sicCode: "3449",
        naicsCode: "332114"
    },
    {
        id: "202",
        name:"Cut and Sew Apparel Contractors",
        group: "#N/A",
        sicCode: "2311",
        naicsCode: "315210"
    },
    {
        id: "219",
        name:"Cut Stock, Resawing Lumber, and Planing",
        group: "#N/A",
        sicCode: "2421",
        naicsCode: "321912"
    },
    {
        id: "304",
        name:"Cut Stone and Stone Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3281",
        naicsCode: "327991"
    },
    {
        id: "380",
        name:"Cutting Tool and Machine Tool Accessory Manufacturing",
        group: "Manufacturing",
        sicCode: "3545",
        naicsCode: "333515"
    },
    {
        id: "250",
        name:"Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing",
        group: "Manufacturing",
        sicCode: "2861",
        naicsCode: "325194"
    },
    {
        id: "33",
        name:"Dairy Cattle and Milk Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "241",
        naicsCode: "112120"
    },
    {
        id: "545",
        name:"Dairy Product (except Dried or Canned) Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5143",
        naicsCode: "424430"
    },
    {
        id: "941",
        name:"Dance Companies",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7922",
        naicsCode: "711120"
    },
    {
        id: "718",
        name:"Data Processing, Hosting, and Related Services",
        group: "Information",
        sicCode: "7374",
        naicsCode: "518210"
    },
    {
        id: "642",
        name:"Deep Sea Freight Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4412",
        naicsCode: "483111"
    },
    {
        id: "643",
        name:"Deep Sea Passenger Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4481",
        naicsCode: "483112"
    },
    {
        id: "484",
        name:"Dental Equipment and Supplies Manufacturing",
        group: "Manufacturing",
        sicCode: "3843",
        naicsCode: "339114"
    },
    {
        id: "486",
        name:"Dental Laboratories",
        group: "#N/A",
        sicCode: "8072",
        naicsCode: "339116"
    },
    {
        id: "619",
        name:"Department Stores",
        group: "#N/A",
        sicCode: "5311",
        naicsCode: "452210"
    },
    {
        id: "917",
        name:"Diagnostic Imaging Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8071",
        naicsCode: "621512"
    },
    {
        id: "1002",
        name:"Diet and Weight Reducing Centers",
        group: "Other Services (except Public Administration)",
        sicCode: "7299",
        naicsCode: "812191"
    },
    {
        id: "76",
        name:"Dimension Stone Mining and Quarrying",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1411",
        naicsCode: "212311"
    },
    {
        id: "749",
        name:"Direct Health and Medical Insurance Carriers",
        group: "Finance and Insurance",
        sicCode: "6321",
        naicsCode: "524114"
    },
    {
        id: "748",
        name:"Direct Life Insurance Carriers",
        group: "Finance and Insurance",
        sicCode: "6311",
        naicsCode: "524113"
    },
    {
        id: "828",
        name:"Direct Mail Advertising",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7331",
        naicsCode: "541860"
    },
    {
        id: "750",
        name:"Direct Property and Casualty Insurance Carriers",
        group: "Finance and Insurance",
        sicCode: "6331",
        naicsCode: "524126"
    },
    {
        id: "751",
        name:"Direct Title Insurance Carriers",
        group: "Finance and Insurance",
        sicCode: "6361",
        naicsCode: "524127"
    },
    {
        id: "695",
        name:"Directory and Mailing List Publishers",
        group: "Information",
        sicCode: "2741",
        naicsCode: "511140"
    },
    {
        id: "186",
        name:"Distilleries",
        group: "#N/A",
        sicCode: "2085",
        naicsCode: "312140"
    },
    {
        id: "846",
        name:"Document Preparation Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7338",
        naicsCode: "561410"
    },
    {
        id: "138",
        name:"Dog and Cat Food Manufacturing",
        group: "Manufacturing",
        sicCode: "2047",
        naicsCode: "311111"
    },
    {
        id: "489",
        name:"Doll, Toy, and Game Manufacturing",
        group: "Manufacturing",
        sicCode: "3069",
        naicsCode: "339930"
    },
    {
        id: "799",
        name:"Drafting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541340"
    },
    {
        id: "156",
        name:"Dried and Dehydrated Food Manufacturing",
        group: "Manufacturing",
        sicCode: "2034",
        naicsCode: "311423"
    },
    {
        id: "88",
        name:"Drilling Oil and Gas Wells",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1381",
        naicsCode: "213111"
    },
    {
        id: "975",
        name:"Drinking Places (Alcoholic Beverages)",
        group: "Accommodation and Food Services",
        sicCode: "5813",
        naicsCode: "722410"
    },
    {
        id: "702",
        name:"Drive-In Motion Picture Theaters",
        group: "Information",
        sicCode: "7833",
        naicsCode: "512132"
    },
    {
        id: "538",
        name:"Drugs and Druggists' Sundries Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5122",
        naicsCode: "424210"
    },
    {
        id: "171",
        name:"Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour",
        group: "Manufacturing",
        sicCode: "2045",
        naicsCode: "311824"
    },
    {
        id: "3",
        name:"Dry Pea and Bean Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "119",
        naicsCode: "111130"
    },
    {
        id: "160",
        name:"Dry, Condensed, and Evaporated Dairy Product Manufacturing",
        group: "Manufacturing",
        sicCode: "2023",
        naicsCode: "311514"
    },
    {
        id: "1007",
        name:"Drycleaning and Laundry Services (except Coin-Operated)",
        group: "Other Services (except Public Administration)",
        sicCode: "7211",
        naicsCode: "812320"
    },
    {
        id: "130",
        name:"Drywall and Insulation Contractors",
        group: "Construction",
        sicCode: "1742",
        naicsCode: "238310"
    },
    {
        id: "34",
        name:"Dual-Purpose Cattle Ranching and Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "Aux",
        naicsCode: "112130"
    },
    {
        id: "900",
        name:"Educational Support Services",
        group: "Educational Services",
        sicCode: "8299",
        naicsCode: "611710"
    },
    {
        id: "101",
        name:"Electric Bulk Power Transmission and Control",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221121"
    },
    {
        id: "425",
        name:"Electric Lamp Bulb and Part Manufacturing",
        group: "Manufacturing",
        sicCode: "3641",
        naicsCode: "335110"
    },
    {
        id: "102",
        name:"Electric Power Distribution",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221122"
    },
    {
        id: "517",
        name:"Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5063",
        naicsCode: "423610"
    },
    {
        id: "127",
        name:"Electrical Contractors and Other Wiring Installation Contractors",
        group: "Construction",
        sicCode: "1711",
        naicsCode: "238210"
    },
    {
        id: "414",
        name:"Electromedical and Electrotherapeutic Apparatus Manufacturing",
        group: "Manufacturing",
        sicCode: "3842",
        naicsCode: "334510"
    },
    {
        id: "401",
        name:"Electronic Computer Manufacturing",
        group: "Manufacturing",
        sicCode: "3571",
        naicsCode: "334111"
    },
    {
        id: "411",
        name:"Electronic Connector Manufacturing",
        group: "Manufacturing",
        sicCode: "3678",
        naicsCode: "334417"
    },
    {
        id: "631",
        name:"Electronic Shopping and Mail-Order Houses",
        group: "#N/A",
        sicCode: "5961",
        naicsCode: "454110"
    },
    {
        id: "581",
        name:"Electronics Stores",
        group: "#N/A",
        sicCode: "5045",
        naicsCode: "443142"
    },
    {
        id: "351",
        name:"Electroplating, Plating, Polishing, Anodizing, and Coloring",
        group: "#N/A",
        sicCode: "3399",
        naicsCode: "332813"
    },
    {
        id: "884",
        name:"Elementary and Secondary Schools",
        group: "Educational Services",
        sicCode: "8211",
        naicsCode: "611110"
    },
    {
        id: "389",
        name:"Elevator and Moving Stairway Manufacturing",
        group: "Manufacturing",
        sicCode: "3534",
        naicsCode: "333921"
    },
    {
        id: "937",
        name:"Emergency and Other Relief Services",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624230"
    },
    {
        id: "842",
        name:"Employment Placement Agencies",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7299",
        naicsCode: "561311"
    },
    {
        id: "215",
        name:"Engineered Wood Member (except Truss) Manufacturing",
        group: "Manufacturing",
        sicCode: "2439",
        naicsCode: "321213"
    },
    {
        id: "798",
        name:"Engineering Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8711",
        naicsCode: "541330"
    },
    {
        id: "1020",
        name:"Environment, Conservation and Wildlife Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8399",
        naicsCode: "813312"
    },
    {
        id: "817",
        name:"Environmental Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8999",
        naicsCode: "541620"
    },
    {
        id: "249",
        name:"Ethyl Alcohol Manufacturing",
        group: "Manufacturing",
        sicCode: "2869",
        naicsCode: "325193"
    },
    {
        id: "897",
        name:"Exam Preparation and Tutoring",
        group: "Educational Services",
        sicCode: "8299",
        naicsCode: "611691"
    },
    {
        id: "1032",
        name:"Executive and Legislative Offices, Combined",
        group: "Public Administration",
        sicCode: "9131",
        naicsCode: "921140"
    },
    {
        id: "1029",
        name:"Executive Offices",
        group: "Public Administration",
        sicCode: "9111",
        naicsCode: "921110"
    },
    {
        id: "843",
        name:"Executive Search Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7361",
        naicsCode: "561312"
    },
    {
        id: "270",
        name:"Explosives Manufacturing",
        group: "Manufacturing",
        sicCode: "2892",
        naicsCode: "325920"
    },
    {
        id: "865",
        name:"Exterminating and Pest Control Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4959",
        naicsCode: "561710"
    },
    {
        id: "194",
        name:"Fabric Coating Mills",
        group: "#N/A",
        sicCode: "2295",
        naicsCode: "313320"
    },
    {
        id: "360",
        name:"Fabricated Pipe and Pipe Fitting Manufacturing",
        group: "Manufacturing",
        sicCode: "3498",
        naicsCode: "332996"
    },
    {
        id: "334",
        name:"Fabricated Structural Metal Manufacturing",
        group: "Manufacturing",
        sicCode: "3441",
        naicsCode: "332312"
    },
    {
        id: "841",
        name:"Facilities Support Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "8744",
        naicsCode: "561210"
    },
    {
        id: "607",
        name:"Family Clothing Stores",
        group: "#N/A",
        sicCode: "5651",
        naicsCode: "448140"
    },
    {
        id: "910",
        name:"Family Planning Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8093",
        naicsCode: "621410"
    },
    {
        id: "525",
        name:"Farm and Garden Machinery and Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5083",
        naicsCode: "423820"
    },
    {
        id: "61",
        name:"Farm Labor Contractors and Crew Leaders",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "761",
        naicsCode: "115115"
    },
    {
        id: "362",
        name:"Farm Machinery and Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3523",
        naicsCode: "333111"
    },
    {
        id: "62",
        name:"Farm Management Services",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "762",
        naicsCode: "115116"
    },
    {
        id: "690",
        name:"Farm Product Warehousing and Storage",
        group: "Transportation and Warehousing",
        sicCode: "4221",
        naicsCode: "493130"
    },
    {
        id: "561",
        name:"Farm Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5191",
        naicsCode: "424910"
    },
    {
        id: "494",
        name:"Fastener, Button, Needle, and Pin Manufacturing",
        group: "Manufacturing",
        sicCode: "3131",
        naicsCode: "339993"
    },
    {
        id: "145",
        name:"Fats and Oils Refining and Blending",
        group: "#N/A",
        sicCode: "2046",
        naicsCode: "311225"
    },
    {
        id: "257",
        name:"Fertilizer (Mixing Only) Manufacturing",
        group: "Manufacturing",
        sicCode: "2875",
        naicsCode: "325314"
    },
    {
        id: "437",
        name:"Fiber Optic Cable Manufacturing",
        group: "Manufacturing",
        sicCode: "3357",
        naicsCode: "335921"
    },
    {
        id: "188",
        name:"Fiber, Yarn, and Thread Mills",
        group: "#N/A",
        sicCode: "2281",
        naicsCode: "313110"
    },
    {
        id: "736",
        name:"Financial Transactions Processing, Reserve, and Clearinghouse Activities",
        group: "Finance and Insurance",
        sicCode: "6099",
        naicsCode: "522320"
    },
    {
        id: "894",
        name:"Fine Arts Schools",
        group: "Educational Services",
        sicCode: "7911",
        naicsCode: "611610"
    },
    {
        id: "43",
        name:"Finfish Farming and Fish Hatcheries",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "273",
        naicsCode: "112511"
    },
    {
        id: "53",
        name:"Finfish Fishing",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "912",
        naicsCode: "114111"
    },
    {
        id: "134",
        name:"Finish Carpentry Contractors",
        group: "Construction",
        sicCode: "1751",
        naicsCode: "238350"
    },
    {
        id: "1040",
        name:"Fire Protection",
        group: "Public Administration",
        sicCode: "9224",
        naicsCode: "922160"
    },
    {
        id: "591",
        name:"Fish and Seafood Markets",
        group: "#N/A",
        sicCode: "5146",
        naicsCode: "445220"
    },
    {
        id: "548",
        name:"Fish and Seafood Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5146",
        naicsCode: "424460"
    },
    {
        id: "962",
        name:"Fitness and Recreational Sports Centers",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7991",
        naicsCode: "713940"
    },
    {
        id: "292",
        name:"Flat Glass Manufacturing",
        group: "Manufacturing",
        sicCode: "3211",
        naicsCode: "327211"
    },
    {
        id: "176",
        name:"Flavoring Syrup and Concentrate Manufacturing",
        group: "Manufacturing",
        sicCode: "2087",
        naicsCode: "311930"
    },
    {
        id: "891",
        name:"Flight Training",
        group: "Educational Services",
        sicCode: "8249",
        naicsCode: "611512"
    },
    {
        id: "577",
        name:"Floor Covering Stores",
        group: "#N/A",
        sicCode: "5023",
        naicsCode: "442210"
    },
    {
        id: "132",
        name:"Flooring Contractors",
        group: "Construction",
        sicCode: "1752",
        naicsCode: "238330"
    },
    {
        id: "23",
        name:"Floriculture Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "181",
        naicsCode: "111422"
    },
    {
        id: "622",
        name:"Florists",
        group: "#N/A",
        sicCode: "5992",
        naicsCode: "453110"
    },
    {
        id: "140",
        name:"Flour Milling",
        group: "#N/A",
        sicCode: "2034",
        naicsCode: "311211"
    },
    {
        id: "563",
        name:"Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5193",
        naicsCode: "424930"
    },
    {
        id: "157",
        name:"Fluid Milk Manufacturing",
        group: "Manufacturing",
        sicCode: "2023",
        naicsCode: "311511"
    },
    {
        id: "397",
        name:"Fluid Power Cylinder and Actuator Manufacturing",
        group: "Manufacturing",
        sicCode: "3593",
        naicsCode: "333995"
    },
    {
        id: "398",
        name:"Fluid Power Pump and Motor Manufacturing",
        group: "Manufacturing",
        sicCode: "3594",
        naicsCode: "333996"
    },
    {
        id: "353",
        name:"Fluid Power Valve and Hose Fitting Manufacturing",
        group: "Manufacturing",
        sicCode: "3492",
        naicsCode: "332912"
    },
    {
        id: "230",
        name:"Folding Paperboard Box Manufacturing",
        group: "Manufacturing",
        sicCode: "2657",
        naicsCode: "322212"
    },
    {
        id: "600",
        name:"Food (Health) Supplement Stores",
        group: "#N/A",
        sicCode: "5122",
        naicsCode: "446191"
    },
    {
        id: "367",
        name:"Food Product Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3556",
        naicsCode: "333241"
    },
    {
        id: "972",
        name:"Food Service Contractors",
        group: "Accommodation and Food Services",
        sicCode: "4789",
        naicsCode: "722310"
    },
    {
        id: "997",
        name:"Footwear and Leather Goods Repair",
        group: "Other Services (except Public Administration)",
        sicCode: "7251",
        naicsCode: "811430"
    },
    {
        id: "208",
        name:"Footwear Manufacturing",
        group: "Manufacturing",
        sicCode: "3021",
        naicsCode: "316210"
    },
    {
        id: "542",
        name:"Footwear Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5139",
        naicsCode: "424340"
    },
    {
        id: "51",
        name:"Forest Nurseries and Gathering of Forest Products",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "831",
        naicsCode: "113210"
    },
    {
        id: "777",
        name:"Formal Wear and Costume Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7299",
        naicsCode: "532281"
    },
    {
        id: "94",
        name:"Fossil Fuel Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221112"
    },
    {
        id: "121",
        name:"Framing Contractors",
        group: "Construction",
        sicCode: "1751",
        naicsCode: "238130"
    },
    {
        id: "914",
        name:"Freestanding Ambulatory Surgical and Emergency Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8011",
        naicsCode: "621493"
    },
    {
        id: "682",
        name:"Freight Transportation Arrangement",
        group: "Transportation and Warehousing",
        sicCode: "4731",
        naicsCode: "488510"
    },
    {
        id: "550",
        name:"Fresh Fruit and Vegetable Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5148",
        naicsCode: "424480"
    },
    {
        id: "169",
        name:"Frozen Cakes, Pies, and Other Pastries Manufacturing",
        group: "Manufacturing",
        sicCode: "2053",
        naicsCode: "311813"
    },
    {
        id: "152",
        name:"Frozen Fruit, Juice, and Vegetable Manufacturing",
        group: "Manufacturing",
        sicCode: "2037",
        naicsCode: "311411"
    },
    {
        id: "153",
        name:"Frozen Specialty Food Manufacturing",
        group: "Manufacturing",
        sicCode: "2038",
        naicsCode: "311412"
    },
    {
        id: "18",
        name:"Fruit and Tree Nut Combination Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "179",
        naicsCode: "111336"
    },
    {
        id: "154",
        name:"Fruit and Vegetable Canning",
        group: "#N/A",
        sicCode: "2033",
        naicsCode: "311421"
    },
    {
        id: "592",
        name:"Fruit and Vegetable Markets",
        group: "#N/A",
        sicCode: "5148",
        naicsCode: "445230"
    },
    {
        id: "633",
        name:"Fuel Dealers",
        group: "#N/A",
        sicCode: "5171",
        naicsCode: "454310"
    },
    {
        id: "976",
        name:"Full-Service Restaurants",
        group: "Accommodation and Food Services",
        sicCode: "5812",
        naicsCode: "722511"
    },
    {
        id: "1004",
        name:"Funeral Homes and Funeral Services",
        group: "Other Services (except Public Administration)",
        sicCode: "7261",
        naicsCode: "812210"
    },
    {
        id: "48",
        name:"Fur-Bearing Animal and Rabbit Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "271",
        naicsCode: "112930"
    },
    {
        id: "502",
        name:"Furniture Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5021",
        naicsCode: "423210"
    },
    {
        id: "576",
        name:"Furniture Stores",
        group: "#N/A",
        sicCode: "5021",
        naicsCode: "442110"
    },
    {
        id: "492",
        name:"Gasket, Packing, and Sealing Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3053",
        naicsCode: "339991"
    },
    {
        id: "602",
        name:"Gasoline Stations with Convenience Stores",
        group: "#N/A",
        sicCode: "5411",
        naicsCode: "447110"
    },
    {
        id: "980",
        name:"General Automotive Repair",
        group: "Other Services (except Public Administration)",
        sicCode: "7538",
        naicsCode: "811111"
    },
    {
        id: "648",
        name:"General Freight Trucking, Local",
        group: "#N/A",
        sicCode: "4212",
        naicsCode: "484110"
    },
    {
        id: "650",
        name:"General Freight Trucking, Long-Distance, Less Than Truckload",
        group: "#N/A",
        sicCode: "4213",
        naicsCode: "484122"
    },
    {
        id: "649",
        name:"General Freight Trucking, Long-Distance, Truckload",
        group: "#N/A",
        sicCode: "4213",
        naicsCode: "484121"
    },
    {
        id: "543",
        name:"General Line Grocery Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5141",
        naicsCode: "424410"
    },
    {
        id: "922",
        name:"General Medical and Surgical Hospitals",
        group: "Health Care and Social Assistance",
        sicCode: "8062",
        naicsCode: "622110"
    },
    {
        id: "782",
        name:"General Rental Centers",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7359",
        naicsCode: "532310"
    },
    {
        id: "688",
        name:"General Warehousing and Storage",
        group: "Transportation and Warehousing",
        sicCode: "4225",
        naicsCode: "493110"
    },
    {
        id: "801",
        name:"Geophysical Surveying and Mapping Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "1081",
        naicsCode: "541360"
    },
    {
        id: "98",
        name:"Geothermal Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221116"
    },
    {
        id: "624",
        name:"Gift, Novelty, and Souvenir Stores",
        group: "#N/A",
        sicCode: "5199",
        naicsCode: "453220"
    },
    {
        id: "123",
        name:"Glass and Glazing Contractors",
        group: "Construction",
        sicCode: "1793",
        naicsCode: "238150"
    },
    {
        id: "294",
        name:"Glass Container Manufacturing",
        group: "Manufacturing",
        sicCode: "3221",
        naicsCode: "327213"
    },
    {
        id: "295",
        name:"Glass Product Manufacturing Made of Purchased Glass",
        group: "Manufacturing",
        sicCode: "3231",
        naicsCode: "327215"
    },
    {
        id: "42",
        name:"Goat Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "214",
        naicsCode: "112420"
    },
    {
        id: "71",
        name:"Gold Ore Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1041",
        naicsCode: "212221"
    },
    {
        id: "959",
        name:"Golf Courses and Country Clubs",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7992",
        naicsCode: "713910"
    },
    {
        id: "552",
        name:"Grain and Field Bean Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5153",
        naicsCode: "424510"
    },
    {
        id: "1016",
        name:"Grantmaking Foundations",
        group: "Other Services (except Public Administration)",
        sicCode: "6732",
        naicsCode: "813211"
    },
    {
        id: "14",
        name:"Grape Vineyards",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "172",
        naicsCode: "111332"
    },
    {
        id: "806",
        name:"Graphic Design Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7336",
        naicsCode: "541430"
    },
    {
        id: "696",
        name:"Greeting Card Publishers",
        group: "Information",
        sicCode: "2771",
        naicsCode: "511191"
    },
    {
        id: "305",
        name:"Ground or Treated Mineral and Earth Manufacturing",
        group: "Manufacturing",
        sicCode: "3295",
        naicsCode: "327992"
    },
    {
        id: "461",
        name:"Guided Missile and Space Vehicle Manufacturing",
        group: "Manufacturing",
        sicCode: "3761",
        naicsCode: "336414"
    },
    {
        id: "462",
        name:"Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing",
        group: "Manufacturing",
        sicCode: "3764",
        naicsCode: "336415"
    },
    {
        id: "302",
        name:"Gypsum Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3275",
        naicsCode: "327420"
    },
    {
        id: "343",
        name:"Hardware Manufacturing",
        group: "Manufacturing",
        sicCode: "3429",
        naicsCode: "332510"
    },
    {
        id: "520",
        name:"Hardware Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5072",
        naicsCode: "423710"
    },
    {
        id: "584",
        name:"Hardware Stores",
        group: "#N/A",
        sicCode: "5072",
        naicsCode: "444130"
    },
    {
        id: "213",
        name:"Hardwood Veneer and Plywood Manufacturing",
        group: "Manufacturing",
        sicCode: "2435",
        naicsCode: "321211"
    },
    {
        id: "27",
        name:"Hay Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "139",
        naicsCode: "111940"
    },
    {
        id: "874",
        name:"Hazardous Waste Collection",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4212",
        naicsCode: "562112"
    },
    {
        id: "876",
        name:"Hazardous Waste Treatment and Disposal",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4953",
        naicsCode: "562211"
    },
    {
        id: "759",
        name:"Health and Welfare Funds",
        group: "Finance and Insurance",
        sicCode: "6371",
        naicsCode: "525120"
    },
    {
        id: "376",
        name:"Heating Equipment (except Warm Air Furnaces) Manufacturing",
        group: "Manufacturing",
        sicCode: "3433",
        naicsCode: "333414"
    },
    {
        id: "445",
        name:"Heavy Duty Truck Manufacturing",
        group: "Manufacturing",
        sicCode: "3711",
        naicsCode: "336120"
    },
    {
        id: "117",
        name:"Highway, Street, and Bridge Construction",
        group: "Construction",
        sicCode: "1611",
        naicsCode: "237310"
    },
    {
        id: "952",
        name:"Historical Sites",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "8412",
        naicsCode: "712120"
    },
    {
        id: "912",
        name:"HMO Medical Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8011",
        naicsCode: "621491"
    },
    {
        id: "614",
        name:"Hobby, Toy, and Game Stores",
        group: "#N/A",
        sicCode: "5092",
        naicsCode: "451120"
    },
    {
        id: "35",
        name:"Hog and Pig Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "213",
        naicsCode: "112210"
    },
    {
        id: "994",
        name:"Home and Garden Equipment Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7699",
        naicsCode: "811411"
    },
    {
        id: "582",
        name:"Home Centers",
        group: "#N/A",
        sicCode: "5031",
        naicsCode: "444110"
    },
    {
        id: "503",
        name:"Home Furnishing Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5023",
        naicsCode: "423220"
    },
    {
        id: "918",
        name:"Home Health Care Services",
        group: "Health Care and Social Assistance",
        sicCode: "8082",
        naicsCode: "621610"
    },
    {
        id: "779",
        name:"Home Health Equipment Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7352",
        naicsCode: "532283"
    },
    {
        id: "47",
        name:"Horses and Other Equine Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "272",
        naicsCode: "112920"
    },
    {
        id: "200",
        name:"Hosiery and Sock Mills",
        group: "#N/A",
        sicCode: "2251",
        naicsCode: "315110"
    },
    {
        id: "965",
        name:"Hotels (except Casino Hotels) and Motels",
        group: "Accommodation and Food Services",
        sicCode: "7011",
        naicsCode: "721110"
    },
    {
        id: "580",
        name:"Household Appliance Stores",
        group: "#N/A",
        sicCode: "5064",
        naicsCode: "443141"
    },
    {
        id: "518",
        name:"Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5064",
        naicsCode: "423620"
    },
    {
        id: "474",
        name:"Household Furniture (except Wood and Metal) Manufacturing",
        group: "Manufacturing",
        sicCode: "2499",
        naicsCode: "337125"
    },
    {
        id: "813",
        name:"Human Resources Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7361",
        naicsCode: "541612"
    },
    {
        id: "1019",
        name:"Human Rights Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8399",
        naicsCode: "813311"
    },
    {
        id: "56",
        name:"Hunting and Trapping",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "971",
        naicsCode: "114210"
    },
    {
        id: "93",
        name:"Hydroelectric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221111"
    },
    {
        id: "161",
        name:"Ice Cream and Frozen Dessert Manufacturing",
        group: "Manufacturing",
        sicCode: "2024",
        naicsCode: "311520"
    },
    {
        id: "183",
        name:"Ice Manufacturing",
        group: "Manufacturing",
        sicCode: "2097",
        naicsCode: "312113"
    },
    {
        id: "261",
        name:"In-Vitro Diagnostic Substance Manufacturing",
        group: "Manufacturing",
        sicCode: "2835",
        naicsCode: "325413"
    },
    {
        id: "950",
        name:"Independent Artists, Writers, and Performers",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7383",
        naicsCode: "711510"
    },
    {
        id: "375",
        name:"Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3564",
        naicsCode: "333413"
    },
    {
        id: "537",
        name:"Industrial and Personal Service Paper Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5113",
        naicsCode: "424130"
    },
    {
        id: "111",
        name:"Industrial Building Construction",
        group: "Construction",
        sicCode: "1531",
        naicsCode: "236210"
    },
    {
        id: "805",
        name:"Industrial Design Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541420"
    },
    {
        id: "246",
        name:"Industrial Gas Manufacturing",
        group: "Manufacturing",
        sicCode: "2813",
        naicsCode: "325120"
    },
    {
        id: "1009",
        name:"Industrial Launderers",
        group: "Other Services (except Public Administration)",
        sicCode: "7218",
        naicsCode: "812332"
    },
    {
        id: "526",
        name:"Industrial Machinery and Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5084",
        naicsCode: "423830"
    },
    {
        id: "378",
        name:"Industrial Mold Manufacturing",
        group: "Manufacturing",
        sicCode: "3544",
        naicsCode: "333511"
    },
    {
        id: "396",
        name:"Industrial Process Furnace and Oven Manufacturing",
        group: "Manufacturing",
        sicCode: "2599",
        naicsCode: "333994"
    },
    {
        id: "81",
        name:"Industrial Sand Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1446",
        naicsCode: "212322"
    },
    {
        id: "527",
        name:"Industrial Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5085",
        naicsCode: "423840"
    },
    {
        id: "392",
        name:"Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3496",
        naicsCode: "333924"
    },
    {
        id: "352",
        name:"Industrial Valve Manufacturing",
        group: "Manufacturing",
        sicCode: "3491",
        naicsCode: "332911"
    },
    {
        id: "646",
        name:"Inland Water Freight Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4449",
        naicsCode: "483211"
    },
    {
        id: "647",
        name:"Inland Water Passenger Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4482",
        naicsCode: "483212"
    },
    {
        id: "475",
        name:"Institutional Furniture Manufacturing",
        group: "Manufacturing",
        sicCode: "2531",
        naicsCode: "337127"
    },
    {
        id: "419",
        name:"Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals",
        group: "Manufacturing",
        sicCode: "3663",
        naicsCode: "334515"
    },
    {
        id: "417",
        name:"Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables",
        group: "Manufacturing",
        sicCode: "3823",
        naicsCode: "334513"
    },
    {
        id: "754",
        name:"Insurance Agencies and Brokerages",
        group: "Finance and Insurance",
        sicCode: "6411",
        naicsCode: "524210"
    },
    {
        id: "804",
        name:"Interior Design Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541410"
    },
    {
        id: "1057",
        name:"International Affairs",
        group: "Public Administration",
        sicCode: "9721",
        naicsCode: "928120"
    },
    {
        id: "732",
        name:"International Trade Financing",
        group: "Finance and Insurance",
        sicCode: "6081",
        naicsCode: "522293"
    },
    {
        id: "721",
        name:"Internet Publishing and Broadcasting and Web Search Portals",
        group: "Information",
        sicCode: "2711",
        naicsCode: "519130"
    },
    {
        id: "658",
        name:"Interurban and Rural Bus Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4131",
        naicsCode: "485210"
    },
    {
        id: "860",
        name:"Investigation Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7381",
        naicsCode: "561611"
    },
    {
        id: "745",
        name:"Investment Advice",
        group: "Finance and Insurance",
        sicCode: "6282",
        naicsCode: "523930"
    },
    {
        id: "738",
        name:"Investment Banking and Securities Dealing",
        group: "Finance and Insurance",
        sicCode: "6211",
        naicsCode: "523110"
    },
    {
        id: "326",
        name:"Iron and Steel Forging",
        group: "#N/A",
        sicCode: "3462",
        naicsCode: "332111"
    },
    {
        id: "308",
        name:"Iron and Steel Mills and Ferroalloy Manufacturing",
        group: "Manufacturing",
        sicCode: "3312",
        naicsCode: "331110"
    },
    {
        id: "309",
        name:"Iron and Steel Pipe and Tube Manufacturing from Purchased Steel",
        group: "Manufacturing",
        sicCode: "3317",
        naicsCode: "331210"
    },
    {
        id: "320",
        name:"Iron Foundries",
        group: "#N/A",
        sicCode: "3321",
        naicsCode: "331511"
    },
    {
        id: "70",
        name:"Iron Ore Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1011",
        naicsCode: "212210"
    },
    {
        id: "421",
        name:"Irradiation Apparatus Manufacturing",
        group: "Manufacturing",
        sicCode: "3844",
        naicsCode: "334517"
    },
    {
        id: "866",
        name:"Janitorial Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4581",
        naicsCode: "561720"
    },
    {
        id: "487",
        name:"Jewelry and Silverware Manufacturing",
        group: "Manufacturing",
        sicCode: "3172",
        naicsCode: "339910"
    },
    {
        id: "611",
        name:"Jewelry Stores",
        group: "#N/A",
        sicCode: "5094",
        naicsCode: "448310"
    },
    {
        id: "533",
        name:"Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5094",
        naicsCode: "423940"
    },
    {
        id: "885",
        name:"Junior Colleges",
        group: "Educational Services",
        sicCode: "8222",
        naicsCode: "611210"
    },
    {
        id: "82",
        name:"Kaolin and Ball Clay Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1455",
        naicsCode: "212324"
    },
    {
        id: "913",
        name:"Kidney Dialysis Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8092",
        naicsCode: "621492"
    },
    {
        id: "192",
        name:"Knit Fabric Mills",
        group: "#N/A",
        sicCode: "2257",
        naicsCode: "313240"
    },
    {
        id: "1025",
        name:"Labor Unions and Similar Labor Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8631",
        naicsCode: "813930"
    },
    {
        id: "279",
        name:"Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing",
        group: "Manufacturing",
        sicCode: "3083",
        naicsCode: "326130"
    },
    {
        id: "116",
        name:"Land Subdivision",
        group: "Construction",
        sicCode: "6552",
        naicsCode: "237210"
    },
    {
        id: "797",
        name:"Landscape Architectural Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "781",
        naicsCode: "541320"
    },
    {
        id: "867",
        name:"Landscaping Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "782",
        naicsCode: "561730"
    },
    {
        id: "896",
        name:"Language Schools",
        group: "Educational Services",
        sicCode: "8299",
        naicsCode: "611630"
    },
    {
        id: "363",
        name:"Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3524",
        naicsCode: "333112"
    },
    {
        id: "207",
        name:"Leather and Hide Tanning and Finishing",
        group: "#N/A",
        sicCode: "3111",
        naicsCode: "316110"
    },
    {
        id: "1037",
        name:"Legal Counsel and Prosecution",
        group: "Public Administration",
        sicCode: "9222",
        naicsCode: "922130"
    },
    {
        id: "1030",
        name:"Legislative Bodies",
        group: "Public Administration",
        sicCode: "9121",
        naicsCode: "921120"
    },
    {
        id: "766",
        name:"Lessors of Miniwarehouses and Self-Storage Units",
        group: "Real Estate and Rental and Leasing",
        sicCode: "4225",
        naicsCode: "531130"
    },
    {
        id: "787",
        name:"Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6792",
        naicsCode: "533110"
    },
    {
        id: "765",
        name:"Lessors of Nonresidential Buildings (except Miniwarehouses)",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6512",
        naicsCode: "531120"
    },
    {
        id: "767",
        name:"Lessors of Other Real Estate Property",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6515",
        naicsCode: "531190"
    },
    {
        id: "764",
        name:"Lessors of Residential Buildings and Dwellings",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6513",
        naicsCode: "531110"
    },
    {
        id: "720",
        name:"Libraries and Archives",
        group: "Information",
        sicCode: "7829",
        naicsCode: "519120"
    },
    {
        id: "444",
        name:"Light Truck and Utility Vehicle Manufacturing",
        group: "Manufacturing",
        sicCode: "3711",
        naicsCode: "336112"
    },
    {
        id: "301",
        name:"Lime Manufacturing",
        group: "Manufacturing",
        sicCode: "3274",
        naicsCode: "327410"
    },
    {
        id: "977",
        name:"Limited-Service Restaurants",
        group: "Accommodation and Food Services",
        sicCode: "5812",
        naicsCode: "722513"
    },
    {
        id: "660",
        name:"Limousine Service",
        group: "#N/A",
        sicCode: "4119",
        naicsCode: "485320"
    },
    {
        id: "640",
        name:"Line-Haul Railroads",
        group: "#N/A",
        sicCode: "4011",
        naicsCode: "482111"
    },
    {
        id: "1008",
        name:"Linen Supply",
        group: "Other Services (except Public Administration)",
        sicCode: "7213",
        naicsCode: "812331"
    },
    {
        id: "553",
        name:"Livestock Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5154",
        naicsCode: "424520"
    },
    {
        id: "687",
        name:"Local Messengers and Local Delivery",
        group: "#N/A",
        sicCode: "4215",
        naicsCode: "492210"
    },
    {
        id: "864",
        name:"Locksmiths",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7699",
        naicsCode: "561622"
    },
    {
        id: "52",
        name:"Logging",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "2411",
        naicsCode: "113310"
    },
    {
        id: "612",
        name:"Luggage and Leather Goods Stores",
        group: "#N/A",
        sicCode: "5948",
        naicsCode: "448320"
    },
    {
        id: "504",
        name:"Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5031",
        naicsCode: "423310"
    },
    {
        id: "346",
        name:"Machine Shops",
        group: "#N/A",
        sicCode: "3599",
        naicsCode: "332710"
    },
    {
        id: "381",
        name:"Machine Tool Manufacturing",
        group: "Manufacturing",
        sicCode: "3541",
        naicsCode: "333517"
    },
    {
        id: "430",
        name:"Major Household Appliance Manufacturing",
        group: "Manufacturing",
        sicCode: "3631",
        naicsCode: "335220"
    },
    {
        id: "142",
        name:"Malt Manufacturing",
        group: "Manufacturing",
        sicCode: "2083",
        naicsCode: "311213"
    },
    {
        id: "628",
        name:"Manufactured (Mobile) Home Dealers",
        group: "#N/A",
        sicCode: "5271",
        naicsCode: "453930"
    },
    {
        id: "222",
        name:"Manufactured Home (Mobile Home) Manufacturing",
        group: "Manufacturing",
        sicCode: "2451",
        naicsCode: "321991"
    },
    {
        id: "961",
        name:"Marinas",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "4493",
        naicsCode: "713930"
    },
    {
        id: "677",
        name:"Marine Cargo Handling",
        group: "#N/A",
        sicCode: "4491",
        naicsCode: "488320"
    },
    {
        id: "814",
        name:"Marketing Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8742",
        naicsCode: "541613"
    },
    {
        id: "831",
        name:"Marketing Research and Public Opinion Polling",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8732",
        naicsCode: "541910"
    },
    {
        id: "122",
        name:"Masonry Contractors",
        group: "Construction",
        sicCode: "1741",
        naicsCode: "238140"
    },
    {
        id: "881",
        name:"Materials Recovery Facilities",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4953",
        naicsCode: "562920"
    },
    {
        id: "480",
        name:"Mattress Manufacturing",
        group: "Manufacturing",
        sicCode: "2515",
        naicsCode: "337910"
    },
    {
        id: "177",
        name:"Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing",
        group: "Manufacturing",
        sicCode: "2035",
        naicsCode: "311941"
    },
    {
        id: "388",
        name:"Measuring, Dispensing, and Other Pumping Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3561",
        naicsCode: "333914"
    },
    {
        id: "549",
        name:"Meat and Meat Product Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5147",
        naicsCode: "424470"
    },
    {
        id: "590",
        name:"Meat Markets",
        group: "#N/A",
        sicCode: "5144",
        naicsCode: "445210"
    },
    {
        id: "163",
        name:"Meat Processed from Carcasses",
        group: "#N/A",
        sicCode: "2013",
        naicsCode: "311612"
    },
    {
        id: "385",
        name:"Mechanical Power Transmission Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3568",
        naicsCode: "333613"
    },
    {
        id: "825",
        name:"Media Buying Agencies",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7319",
        naicsCode: "541830"
    },
    {
        id: "826",
        name:"Media Representatives",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7313",
        naicsCode: "541840"
    },
    {
        id: "916",
        name:"Medical Laboratories",
        group: "Health Care and Social Assistance",
        sicCode: "8071",
        naicsCode: "621511"
    },
    {
        id: "512",
        name:"Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5047",
        naicsCode: "423450"
    },
    {
        id: "259",
        name:"Medicinal and Botanical Manufacturing",
        group: "Manufacturing",
        sicCode: "2833",
        naicsCode: "325411"
    },
    {
        id: "540",
        name:"Men's and Boys' Clothing and Furnishings Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5136",
        naicsCode: "424320"
    },
    {
        id: "604",
        name:"Men's Clothing Stores",
        group: "#N/A",
        sicCode: "5136",
        naicsCode: "448110"
    },
    {
        id: "203",
        name:"Men’s and Boys’ Cut and Sew Apparel Manufacturing",
        group: "Manufacturing",
        sicCode: "2311",
        naicsCode: "315220"
    },
    {
        id: "341",
        name:"Metal Can Manufacturing",
        group: "Manufacturing",
        sicCode: "3411",
        naicsCode: "332431"
    },
    {
        id: "350",
        name:"Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers",
        group: "#N/A",
        sicCode: "3479",
        naicsCode: "332812"
    },
    {
        id: "330",
        name:"Metal Crown, Closure, and Other Metal Stamping (except Automotive)",
        group: "#N/A",
        sicCode: "3466",
        naicsCode: "332119"
    },
    {
        id: "349",
        name:"Metal Heat Treating",
        group: "#N/A",
        sicCode: "3398",
        naicsCode: "332811"
    },
    {
        id: "473",
        name:"Metal Household Furniture Manufacturing",
        group: "Manufacturing",
        sicCode: "2514",
        naicsCode: "337124"
    },
    {
        id: "331",
        name:"Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing",
        group: "Manufacturing",
        sicCode: "3421",
        naicsCode: "332215"
    },
    {
        id: "515",
        name:"Metal Service Centers and Other Metal Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5051",
        naicsCode: "423510"
    },
    {
        id: "340",
        name:"Metal Tank (Heavy Gauge) Manufacturing",
        group: "Manufacturing",
        sicCode: "3443",
        naicsCode: "332420"
    },
    {
        id: "336",
        name:"Metal Window and Door Manufacturing",
        group: "Manufacturing",
        sicCode: "3442",
        naicsCode: "332321"
    },
    {
        id: "468",
        name:"Military Armored Vehicle, Tank, and Tank Component Manufacturing",
        group: "Manufacturing",
        sicCode: "3711",
        naicsCode: "336992"
    },
    {
        id: "306",
        name:"Mineral Wool Manufacturing",
        group: "Manufacturing",
        sicCode: "3296",
        naicsCode: "327993"
    },
    {
        id: "365",
        name:"Mining Machinery and Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3532",
        naicsCode: "333131"
    },
    {
        id: "747",
        name:"Miscellaneous Financial Investment Activities",
        group: "Finance and Insurance",
        sicCode: "6211",
        naicsCode: "523999"
    },
    {
        id: "743",
        name:"Miscellaneous Intermediation",
        group: "Finance and Insurance",
        sicCode: "6153",
        naicsCode: "523910"
    },
    {
        id: "654",
        name:"Mixed Mode Transit Systems",
        group: "#N/A",
        sicCode: "4111",
        naicsCode: "485111"
    },
    {
        id: "974",
        name:"Mobile Food Services",
        group: "Accommodation and Food Services",
        sicCode: "5963",
        naicsCode: "722330"
    },
    {
        id: "723",
        name:"Monetary Authorities-Central Bank",
        group: "Finance and Insurance",
        sicCode: "6011",
        naicsCode: "521110"
    },
    {
        id: "735",
        name:"Mortgage and Nonmortgage Loan Brokers",
        group: "Finance and Insurance",
        sicCode: "6163",
        naicsCode: "522310"
    },
    {
        id: "700",
        name:"Motion Picture and Video Distribution",
        group: "Information",
        sicCode: "7822",
        naicsCode: "512120"
    },
    {
        id: "699",
        name:"Motion Picture and Video Production",
        group: "Information",
        sicCode: "7812",
        naicsCode: "512110"
    },
    {
        id: "701",
        name:"Motion Picture Theaters (except Drive-Ins)",
        group: "Information",
        sicCode: "7832",
        naicsCode: "512131"
    },
    {
        id: "432",
        name:"Motor and Generator Manufacturing",
        group: "Manufacturing",
        sicCode: "3621",
        naicsCode: "335312"
    },
    {
        id: "448",
        name:"Motor Home Manufacturing",
        group: "Manufacturing",
        sicCode: "3716",
        naicsCode: "336213"
    },
    {
        id: "446",
        name:"Motor Vehicle Body Manufacturing",
        group: "Manufacturing",
        sicCode: "3711",
        naicsCode: "336211"
    },
    {
        id: "453",
        name:"Motor Vehicle Brake System Manufacturing",
        group: "Manufacturing",
        sicCode: "3292",
        naicsCode: "336340"
    },
    {
        id: "451",
        name:"Motor Vehicle Electrical and Electronic Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3647",
        naicsCode: "336320"
    },
    {
        id: "450",
        name:"Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",
        group: "Manufacturing",
        sicCode: "3592",
        naicsCode: "336310"
    },
    {
        id: "456",
        name:"Motor Vehicle Metal Stamping",
        group: "#N/A",
        sicCode: "3465",
        naicsCode: "336370"
    },
    {
        id: "501",
        name:"Motor Vehicle Parts (Used) Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5015",
        naicsCode: "423140"
    },
    {
        id: "455",
        name:"Motor Vehicle Seating and Interior Trim Manufacturing",
        group: "Manufacturing",
        sicCode: "2396",
        naicsCode: "336360"
    },
    {
        id: "452",
        name:"Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",
        group: "Manufacturing",
        sicCode: "3714",
        naicsCode: "336330"
    },
    {
        id: "499",
        name:"Motor Vehicle Supplies and New Parts Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5013",
        naicsCode: "423120"
    },
    {
        id: "680",
        name:"Motor Vehicle Towing",
        group: "#N/A",
        sicCode: "7549",
        naicsCode: "488410"
    },
    {
        id: "454",
        name:"Motor Vehicle Transmission and Power Train Parts Manufacturing",
        group: "Manufacturing",
        sicCode: "3292",
        naicsCode: "336350"
    },
    {
        id: "573",
        name:"Motorcycle, ATV, and All Other Motor Vehicle Dealers",
        group: "#N/A",
        sicCode: "5571",
        naicsCode: "441228"
    },
    {
        id: "467",
        name:"Motorcycle, Bicycle, and Parts Manufacturing",
        group: "Manufacturing",
        sicCode: "3751",
        naicsCode: "336991"
    },
    {
        id: "951",
        name:"Museums",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "8412",
        naicsCode: "712110"
    },
    {
        id: "20",
        name:"Mushroom Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "182",
        naicsCode: "111411"
    },
    {
        id: "705",
        name:"Music Publishers",
        group: "Information",
        sicCode: "2731",
        naicsCode: "512230"
    },
    {
        id: "942",
        name:"Musical Groups and Artists",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7929",
        naicsCode: "711130"
    },
    {
        id: "616",
        name:"Musical Instrument and Supplies Stores",
        group: "#N/A",
        sicCode: "5736",
        naicsCode: "451140"
    },
    {
        id: "493",
        name:"Musical Instrument Manufacturing",
        group: "Manufacturing",
        sicCode: "3931",
        naicsCode: "339992"
    },
    {
        id: "1001",
        name:"Nail Salons",
        group: "Other Services (except Public Administration)",
        sicCode: "7231",
        naicsCode: "812113"
    },
    {
        id: "190",
        name:"Narrow Fabric Mills and Schiffli Machine Embroidery",
        group: "#N/A",
        sicCode: "2241",
        naicsCode: "313220"
    },
    {
        id: "1056",
        name:"National Security",
        group: "Public Administration",
        sicCode: "9711",
        naicsCode: "928110"
    },
    {
        id: "103",
        name:"Natural Gas Distribution",
        group: "Utilities",
        sicCode: "4923",
        naicsCode: "221210"
    },
    {
        id: "66",
        name:"Natural Gas Extraction",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1321",
        naicsCode: "211130"
    },
    {
        id: "954",
        name:"Nature Parks and Other Similar Institutions",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7999",
        naicsCode: "712190"
    },
    {
        id: "678",
        name:"Navigational Services to Shipping",
        group: "#N/A",
        sicCode: "4492",
        naicsCode: "488330"
    },
    {
        id: "569",
        name:"New Car Dealers",
        group: "#N/A",
        sicCode: "5511",
        naicsCode: "441110"
    },
    {
        id: "109",
        name:"New Housing For-Sale Builders",
        group: "Construction",
        sicCode: "1531",
        naicsCode: "236117"
    },
    {
        id: "108",
        name:"New Multifamily Housing Construction (except For-Sale Builders)",
        group: "Construction",
        sicCode: "1522",
        naicsCode: "236116"
    },
    {
        id: "107",
        name:"New Single-Family Housing Construction (except For-Sale Builders)",
        group: "Construction",
        sicCode: "1521",
        naicsCode: "236115"
    },
    {
        id: "618",
        name:"News Dealers and Newsstands",
        group: "#N/A",
        sicCode: "5994",
        naicsCode: "451212"
    },
    {
        id: "719",
        name:"News Syndicates",
        group: "Information",
        sicCode: "7383",
        naicsCode: "519110"
    },
    {
        id: "692",
        name:"Newspaper Publishers",
        group: "Information",
        sicCode: "2711",
        naicsCode: "511110"
    },
    {
        id: "227",
        name:"Newsprint Mills",
        group: "#N/A",
        sicCode: "2611",
        naicsCode: "322122"
    },
    {
        id: "255",
        name:"Nitrogenous Fertilizer Manufacturing",
        group: "Manufacturing",
        sicCode: "2873",
        naicsCode: "325311"
    },
    {
        id: "149",
        name:"Nonchocolate Confectionery Manufacturing",
        group: "Manufacturing",
        sicCode: "2064",
        naicsCode: "311340"
    },
    {
        id: "440",
        name:"Noncurrent-Carrying Wiring Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3644",
        naicsCode: "335932"
    },
    {
        id: "327",
        name:"Nonferrous Forging",
        group: "#N/A",
        sicCode: "3463",
        naicsCode: "332112"
    },
    {
        id: "316",
        name:"Nonferrous Metal (except Aluminum) Smelting and Refining",
        group: "#N/A",
        sicCode: "3331",
        naicsCode: "331410"
    },
    {
        id: "318",
        name:"Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding",
        group: "#N/A",
        sicCode: "3356",
        naicsCode: "331491"
    },
    {
        id: "323",
        name:"Nonferrous Metal Die-Casting Foundries",
        group: "#N/A",
        sicCode: "3363",
        naicsCode: "331523"
    },
    {
        id: "770",
        name:"Nonresidential Property Managers",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6531",
        naicsCode: "531312"
    },
    {
        id: "638",
        name:"Nonscheduled Chartered Freight Air Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4522",
        naicsCode: "481212"
    },
    {
        id: "637",
        name:"Nonscheduled Chartered Passenger Air Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4522",
        naicsCode: "481211"
    },
    {
        id: "472",
        name:"Nonupholstered Wood Household Furniture Manufacturing",
        group: "Manufacturing",
        sicCode: "2511",
        naicsCode: "337122"
    },
    {
        id: "191",
        name:"Nonwoven Fabric Mills",
        group: "#N/A",
        sicCode: "2297",
        naicsCode: "313230"
    },
    {
        id: "95",
        name:"Nuclear Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221113"
    },
    {
        id: "22",
        name:"Nursery and Tree Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "181",
        naicsCode: "111421"
    },
    {
        id: "587",
        name:"Nursery, Garden Center, and Farm Supply Stores",
        group: "#N/A",
        sicCode: "5153",
        naicsCode: "444220"
    },
    {
        id: "925",
        name:"Nursing Care Facilities (Skilled Nursing Facilities)",
        group: "Health Care and Social Assistance",
        sicCode: "8051",
        naicsCode: "623110"
    },
    {
        id: "840",
        name:"Office Administrative Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "8741",
        naicsCode: "561110"
    },
    {
        id: "509",
        name:"Office Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5044",
        naicsCode: "423420"
    },
    {
        id: "478",
        name:"Office Furniture (except Wood) Manufacturing",
        group: "Manufacturing",
        sicCode: "2522",
        naicsCode: "337214"
    },
    {
        id: "785",
        name:"Office Machinery and Equipment Rental and Leasing",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7359",
        naicsCode: "532420"
    },
    {
        id: "490",
        name:"Office Supplies (except Paper) Manufacturing",
        group: "Manufacturing",
        sicCode: "2531",
        naicsCode: "339940"
    },
    {
        id: "623",
        name:"Office Supplies and Stationery Stores",
        group: "#N/A",
        sicCode: "5044",
        naicsCode: "453210"
    },
    {
        id: "909",
        name:"Offices of All Other Miscellaneous Health Practitioners",
        group: "Health Care and Social Assistance",
        sicCode: "8049",
        naicsCode: "621399"
    },
    {
        id: "837",
        name:"Offices of Bank Holding Companies",
        group: "Management of Companies and Enterprises",
        sicCode: "6712",
        naicsCode: "551111"
    },
    {
        id: "792",
        name:"Offices of Certified Public Accountants",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8721",
        naicsCode: "541211"
    },
    {
        id: "904",
        name:"Offices of Chiropractors",
        group: "Health Care and Social Assistance",
        sicCode: "8041",
        naicsCode: "621310"
    },
    {
        id: "903",
        name:"Offices of Dentists",
        group: "Health Care and Social Assistance",
        sicCode: "8021",
        naicsCode: "621210"
    },
    {
        id: "788",
        name:"Offices of Lawyers",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8111",
        naicsCode: "541110"
    },
    {
        id: "906",
        name:"Offices of Mental Health Practitioners (except Physicians)",
        group: "Health Care and Social Assistance",
        sicCode: "8049",
        naicsCode: "621330"
    },
    {
        id: "789",
        name:"Offices of Notaries",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "Aux",
        naicsCode: "541120"
    },
    {
        id: "905",
        name:"Offices of Optometrists",
        group: "Health Care and Social Assistance",
        sicCode: "8042",
        naicsCode: "621320"
    },
    {
        id: "838",
        name:"Offices of Other Holding Companies",
        group: "Management of Companies and Enterprises",
        sicCode: "6719",
        naicsCode: "551112"
    },
    {
        id: "907",
        name:"Offices of Physical, Occupational and Speech Therapists, and Audiologists",
        group: "Health Care and Social Assistance",
        sicCode: "8049",
        naicsCode: "621340"
    },
    {
        id: "901",
        name:"Offices of Physicians (except Mental Health Specialists)",
        group: "Health Care and Social Assistance",
        sicCode: "8011",
        naicsCode: "621111"
    },
    {
        id: "902",
        name:"Offices of Physicians, Mental Health Specialists",
        group: "Health Care and Social Assistance",
        sicCode: "8011",
        naicsCode: "621112"
    },
    {
        id: "908",
        name:"Offices of Podiatrists",
        group: "Health Care and Social Assistance",
        sicCode: "8043",
        naicsCode: "621391"
    },
    {
        id: "768",
        name:"Offices of Real Estate Agents and Brokers",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6531",
        naicsCode: "531210"
    },
    {
        id: "771",
        name:"Offices of Real Estate Appraisers",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6531",
        naicsCode: "531320"
    },
    {
        id: "366",
        name:"Oil and Gas Field Machinery and Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3533",
        naicsCode: "333132"
    },
    {
        id: "114",
        name:"Oil and Gas Pipeline and Related Structures Construction",
        group: "Construction",
        sicCode: "1389",
        naicsCode: "237120"
    },
    {
        id: "2",
        name:"Oilseed (except Soybean) Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "119",
        naicsCode: "111120"
    },
    {
        id: "7",
        name:"Oilseed and Grain Combination Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "119",
        naicsCode: "111191"
    },
    {
        id: "1012",
        name:"One-Hour Photofinishing",
        group: "Other Services (except Public Administration)",
        sicCode: "7384",
        naicsCode: "812922"
    },
    {
        id: "761",
        name:"Open-End Investment Funds",
        group: "Finance and Insurance",
        sicCode: "6722",
        naicsCode: "525910"
    },
    {
        id: "485",
        name:"Ophthalmic Goods Manufacturing",
        group: "Manufacturing",
        sicCode: "3851",
        naicsCode: "339115"
    },
    {
        id: "513",
        name:"Ophthalmic Goods Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5048",
        naicsCode: "423460"
    },
    {
        id: "599",
        name:"Optical Goods Stores",
        group: "#N/A",
        sicCode: "5995",
        naicsCode: "446130"
    },
    {
        id: "372",
        name:"Optical Instrument and Lens Manufacturing",
        group: "Manufacturing",
        sicCode: "3827",
        naicsCode: "333314"
    },
    {
        id: "11",
        name:"Orange Groves",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "174",
        naicsCode: "111310"
    },
    {
        id: "338",
        name:"Ornamental and Architectural Metal Work Manufacturing",
        group: "Manufacturing",
        sicCode: "3446",
        naicsCode: "332323"
    },
    {
        id: "795",
        name:"Other Accounting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8721",
        naicsCode: "541219"
    },
    {
        id: "737",
        name:"Other Activities Related to Credit Intermediation",
        group: "Finance and Insurance",
        sicCode: "6099",
        naicsCode: "522390"
    },
    {
        id: "772",
        name:"Other Activities Related to Real Estate",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6531",
        naicsCode: "531390"
    },
    {
        id: "460",
        name:"Other Aircraft Parts and Auxiliary Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3728",
        naicsCode: "336413"
    },
    {
        id: "673",
        name:"Other Airport Operations",
        group: "#N/A",
        sicCode: "4581",
        naicsCode: "488119"
    },
    {
        id: "315",
        name:"Other Aluminum Rolling, Drawing, and Extruding",
        group: "#N/A",
        sicCode: "3354",
        naicsCode: "331318"
    },
    {
        id: "139",
        name:"Other Animal Food Manufacturing",
        group: "Manufacturing",
        sicCode: "723",
        naicsCode: "311119"
    },
    {
        id: "201",
        name:"Other Apparel Knitting Mills",
        group: "#N/A",
        sicCode: "2253",
        naicsCode: "315190"
    },
    {
        id: "45",
        name:"Other Aquaculture",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "139",
        naicsCode: "112519"
    },
    {
        id: "983",
        name:"Other Automotive Mechanical and Electrical Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7539",
        naicsCode: "811118"
    },
    {
        id: "248",
        name:"Other Basic Inorganic Chemical Manufacturing",
        group: "Manufacturing",
        sicCode: "2812",
        naicsCode: "325180"
    },
    {
        id: "129",
        name:"Other Building Equipment Contractors",
        group: "Construction",
        sicCode: "1796",
        naicsCode: "238290"
    },
    {
        id: "135",
        name:"Other Building Finishing Contractors",
        group: "Construction",
        sicCode: "1761",
        naicsCode: "238390"
    },
    {
        id: "585",
        name:"Other Building Material Dealers",
        group: "#N/A",
        sicCode: "5032",
        naicsCode: "444190"
    },
    {
        id: "850",
        name:"Other Business Service Centers (including Copy Shops)",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7334",
        naicsCode: "561439"
    },
    {
        id: "556",
        name:"Other Chemical and Allied Products Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5169",
        naicsCode: "424690"
    },
    {
        id: "86",
        name:"Other Chemical and Fertilizer Mineral Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1479",
        naicsCode: "212393"
    },
    {
        id: "609",
        name:"Other Clothing Stores",
        group: "#N/A",
        sicCode: "5136",
        naicsCode: "448190"
    },
    {
        id: "786",
        name:"Other Commercial and Industrial Machinery and Equipment Rental and Leasing",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7352",
        naicsCode: "532490"
    },
    {
        id: "374",
        name:"Other Commercial and Service Industry Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3559",
        naicsCode: "333318"
    },
    {
        id: "511",
        name:"Other Commercial Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5046",
        naicsCode: "423440"
    },
    {
        id: "438",
        name:"Other Communication and Energy Wire Manufacturing",
        group: "Manufacturing",
        sicCode: "3357",
        naicsCode: "335929"
    },
    {
        id: "406",
        name:"Other Communications Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3669",
        naicsCode: "334290"
    },
    {
        id: "936",
        name:"Other Community Housing Services",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624229"
    },
    {
        id: "811",
        name:"Other Computer Related Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7379",
        naicsCode: "541519"
    },
    {
        id: "300",
        name:"Other Concrete Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3272",
        naicsCode: "327390"
    },
    {
        id: "507",
        name:"Other Construction Material Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5039",
        naicsCode: "423390"
    },
    {
        id: "79",
        name:"Other Crushed and Broken Stone Mining and Quarrying",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1429",
        naicsCode: "212319"
    },
    {
        id: "205",
        name:"Other Cut and Sew Apparel Manufacturing",
        group: "Manufacturing",
        sicCode: "2329",
        naicsCode: "315280"
    },
    {
        id: "727",
        name:"Other Depository Credit Intermediation",
        group: "Finance and Insurance",
        sicCode: "6022",
        naicsCode: "522190"
    },
    {
        id: "752",
        name:"Other Direct Insurance (except Life, Health, and Medical) Carriers",
        group: "Finance and Insurance",
        sicCode: "6311",
        naicsCode: "524128"
    },
    {
        id: "634",
        name:"Other Direct Selling Establishments",
        group: "#N/A",
        sicCode: "5142",
        naicsCode: "454390"
    },
    {
        id: "100",
        name:"Other Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221118"
    },
    {
        id: "992",
        name:"Other Electronic and Precision Equipment Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "7629",
        naicsCode: "811219"
    },
    {
        id: "413",
        name:"Other Electronic Component Manufacturing",
        group: "Manufacturing",
        sicCode: "3671",
        naicsCode: "334419"
    },
    {
        id: "519",
        name:"Other Electronic Parts and Equipment Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5065",
        naicsCode: "423690"
    },
    {
        id: "386",
        name:"Other Engine Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3519",
        naicsCode: "333618"
    },
    {
        id: "345",
        name:"Other Fabricated Wire Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3315",
        naicsCode: "332618"
    },
    {
        id: "554",
        name:"Other Farm Product Raw Material Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5159",
        naicsCode: "424590"
    },
    {
        id: "763",
        name:"Other Financial Vehicles",
        group: "Finance and Insurance",
        sicCode: "6371",
        naicsCode: "525990"
    },
    {
        id: "21",
        name:"Other Food Crops Grown Under Cover",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "182",
        naicsCode: "111419"
    },
    {
        id: "126",
        name:"Other Foundation, Structure, and Building Exterior Contractors",
        group: "Construction",
        sicCode: "1791",
        naicsCode: "238190"
    },
    {
        id: "958",
        name:"Other Gambling Industries",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7993",
        naicsCode: "713290"
    },
    {
        id: "603",
        name:"Other Gasoline Stations",
        group: "#N/A",
        sicCode: "5541",
        naicsCode: "447190"
    },
    {
        id: "1034",
        name:"Other General Government Support",
        group: "Public Administration",
        sicCode: "9199",
        naicsCode: "921190"
    },
    {
        id: "1018",
        name:"Other Grantmaking and Giving Services",
        group: "Other Services (except Public Administration)",
        sicCode: "8399",
        naicsCode: "813219"
    },
    {
        id: "551",
        name:"Other Grocery and Related Products Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5149",
        naicsCode: "424490"
    },
    {
        id: "463",
        name:"Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3769",
        naicsCode: "336419"
    },
    {
        id: "118",
        name:"Other Heavy and Civil Engineering Construction",
        group: "Construction",
        sicCode: "1622",
        naicsCode: "237990"
    },
    {
        id: "933",
        name:"Other Individual and Family Services",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624190"
    },
    {
        id: "371",
        name:"Other Industrial Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "2599",
        naicsCode: "333249"
    },
    {
        id: "760",
        name:"Other Insurance Funds",
        group: "Finance and Insurance",
        sicCode: "6321",
        naicsCode: "525190"
    },
    {
        id: "1041",
        name:"Other Justice, Public Order, and Safety Activities",
        group: "Public Administration",
        sicCode: "9229",
        naicsCode: "922190"
    },
    {
        id: "428",
        name:"Other Lighting Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3648",
        naicsCode: "335129"
    },
    {
        id: "816",
        name:"Other Management Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8748",
        naicsCode: "541618"
    },
    {
        id: "55",
        name:"Other Marine Fishing",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "919",
        naicsCode: "114119"
    },
    {
        id: "422",
        name:"Other Measuring and Controlling Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3429",
        naicsCode: "334519"
    },
    {
        id: "342",
        name:"Other Metal Container Manufacturing",
        group: "Manufacturing",
        sicCode: "3412",
        naicsCode: "332439"
    },
    {
        id: "355",
        name:"Other Metal Valve and Pipe Fitting Manufacturing",
        group: "Manufacturing",
        sicCode: "3429",
        naicsCode: "332919"
    },
    {
        id: "220",
        name:"Other Millwork (including Flooring)",
        group: "#N/A",
        sicCode: "2421",
        naicsCode: "321918"
    },
    {
        id: "534",
        name:"Other Miscellaneous Durable Goods Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5099",
        naicsCode: "423990"
    },
    {
        id: "566",
        name:"Other Miscellaneous Nondurable Goods Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5199",
        naicsCode: "424990"
    },
    {
        id: "704",
        name:"Other Motion Picture and Video Industries",
        group: "Information",
        sicCode: "7819",
        naicsCode: "512199"
    },
    {
        id: "457",
        name:"Other Motor Vehicle Parts Manufacturing",
        group: "Manufacturing",
        sicCode: "3429",
        naicsCode: "336390"
    },
    {
        id: "19",
        name:"Other Noncitrus Fruit Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "175",
        naicsCode: "111339"
    },
    {
        id: "325",
        name:"Other Nonferrous Metal Foundries (except Die-Casting)",
        group: "#N/A",
        sicCode: "3366",
        naicsCode: "331529"
    },
    {
        id: "879",
        name:"Other Nonhazardous Waste Treatment and Disposal",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4953",
        naicsCode: "562219"
    },
    {
        id: "639",
        name:"Other Nonscheduled Air Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4522",
        naicsCode: "481219"
    },
    {
        id: "231",
        name:"Other Paperboard Container Manufacturing",
        group: "Manufacturing",
        sicCode: "2652",
        naicsCode: "322219"
    },
    {
        id: "943",
        name:"Other Performing Arts Companies",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7929",
        naicsCode: "711190"
    },
    {
        id: "998",
        name:"Other Personal and Household Goods Repair and Maintenance",
        group: "Other Services (except Public Administration)",
        sicCode: "3732",
        naicsCode: "811490"
    },
    {
        id: "1003",
        name:"Other Personal Care Services",
        group: "Other Services (except Public Administration)",
        sicCode: "7299",
        naicsCode: "812199"
    },
    {
        id: "40",
        name:"Other Poultry Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "259",
        naicsCode: "112390"
    },
    {
        id: "293",
        name:"Other Pressed and Blown Glass and Glassware Manufacturing",
        group: "Manufacturing",
        sicCode: "3229",
        naicsCode: "327212"
    },
    {
        id: "514",
        name:"Other Professional Equipment and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5049",
        naicsCode: "423490"
    },
    {
        id: "930",
        name:"Other Residential Care Facilities",
        group: "Health Care and Social Assistance",
        sicCode: "8361",
        naicsCode: "623990"
    },
    {
        id: "818",
        name:"Other Scientific and Technical Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "781",
        naicsCode: "541690"
    },
    {
        id: "830",
        name:"Other Services Related to Advertising",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "5199",
        naicsCode: "541890"
    },
    {
        id: "869",
        name:"Other Services to Buildings and Dwellings",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "1799",
        naicsCode: "561790"
    },
    {
        id: "1027",
        name:"Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)",
        group: "Other Services (except Public Administration)",
        sicCode: "6531",
        naicsCode: "813990"
    },
    {
        id: "174",
        name:"Other Snack Food Manufacturing",
        group: "Manufacturing",
        sicCode: "2052",
        naicsCode: "311919"
    },
    {
        id: "1021",
        name:"Other Social Advocacy Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8399",
        naicsCode: "813319"
    },
    {
        id: "708",
        name:"Other Sound Recording Industries",
        group: "Information",
        sicCode: "7389",
        naicsCode: "512290"
    },
    {
        id: "807",
        name:"Other Specialized Design Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541490"
    },
    {
        id: "946",
        name:"Other Spectator Sports",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7948",
        naicsCode: "711219"
    },
    {
        id: "674",
        name:"Other Support Activities for Air Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4581",
        naicsCode: "488190"
    },
    {
        id: "681",
        name:"Other Support Activities for Road Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4173",
        naicsCode: "488490"
    },
    {
        id: "679",
        name:"Other Support Activities for Water Transportation",
        group: "Transportation and Warehousing",
        sicCode: "3731",
        naicsCode: "488390"
    },
    {
        id: "893",
        name:"Other Technical and Trade Schools",
        group: "Educational Services",
        sicCode: "8243",
        naicsCode: "611519"
    },
    {
        id: "657",
        name:"Other Urban Transit Systems",
        group: "#N/A",
        sicCode: "4111",
        naicsCode: "485119"
    },
    {
        id: "10",
        name:"Other Vegetable (except Potato) and Melon Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "139",
        naicsCode: "111219"
    },
    {
        id: "691",
        name:"Other Warehousing and Storage",
        group: "Transportation and Warehousing",
        sicCode: "4226",
        naicsCode: "493190"
    },
    {
        id: "875",
        name:"Other Waste Collection",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4212",
        naicsCode: "562119"
    },
    {
        id: "827",
        name:"Outdoor Advertising",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7312",
        naicsCode: "541850"
    },
    {
        id: "586",
        name:"Outdoor Power Equipment Stores",
        group: "#N/A",
        sicCode: "5083",
        naicsCode: "444210"
    },
    {
        id: "911",
        name:"Outpatient Mental Health and Substance Abuse Centers",
        group: "Health Care and Social Assistance",
        sicCode: "8093",
        naicsCode: "621420"
    },
    {
        id: "391",
        name:"Overhead Traveling Crane, Hoist, and Monorail System Manufacturing",
        group: "Manufacturing",
        sicCode: "3429",
        naicsCode: "333923"
    },
    {
        id: "544",
        name:"Packaged Frozen Food Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5142",
        naicsCode: "424420"
    },
    {
        id: "870",
        name:"Packaging and Labeling Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561910"
    },
    {
        id: "395",
        name:"Packaging Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3565",
        naicsCode: "333993"
    },
    {
        id: "683",
        name:"Packing and Crating",
        group: "#N/A",
        sicCode: "4783",
        naicsCode: "488991"
    },
    {
        id: "263",
        name:"Paint and Coating Manufacturing",
        group: "Manufacturing",
        sicCode: "2851",
        naicsCode: "325510"
    },
    {
        id: "583",
        name:"Paint and Wallpaper Stores",
        group: "#N/A",
        sicCode: "5231",
        naicsCode: "444120"
    },
    {
        id: "565",
        name:"Paint, Varnish, and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5198",
        naicsCode: "424950"
    },
    {
        id: "131",
        name:"Painting and Wall Covering Contractors",
        group: "Construction",
        sicCode: "1721",
        naicsCode: "238320"
    },
    {
        id: "226",
        name:"Paper (except Newsprint) Mills",
        group: "#N/A",
        sicCode: "2611",
        naicsCode: "322121"
    },
    {
        id: "232",
        name:"Paper Bag and Coated and Treated Paper Manufacturing",
        group: "Manufacturing",
        sicCode: "2671",
        naicsCode: "322220"
    },
    {
        id: "228",
        name:"Paperboard Mills",
        group: "#N/A",
        sicCode: "2611",
        naicsCode: "322130"
    },
    {
        id: "1013",
        name:"Parking Lots and Garages",
        group: "Other Services (except Public Administration)",
        sicCode: "7299",
        naicsCode: "812930"
    },
    {
        id: "1039",
        name:"Parole Offices and Probation Offices",
        group: "Public Administration",
        sicCode: "8322",
        naicsCode: "922150"
    },
    {
        id: "774",
        name:"Passenger Car Leasing",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7515",
        naicsCode: "532112"
    },
    {
        id: "773",
        name:"Passenger Car Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7514",
        naicsCode: "532111"
    },
    {
        id: "794",
        name:"Payroll Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7819",
        naicsCode: "541214"
    },
    {
        id: "29",
        name:"Peanut Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "139",
        naicsCode: "111992"
    },
    {
        id: "758",
        name:"Pension Funds",
        group: "Finance and Insurance",
        sicCode: "6371",
        naicsCode: "525110"
    },
    {
        id: "693",
        name:"Periodical Publishers",
        group: "Information",
        sicCode: "2721",
        naicsCode: "511120"
    },
    {
        id: "179",
        name:"Perishable Prepared Food Manufacturing",
        group: "Manufacturing",
        sicCode: "2099",
        naicsCode: "311991"
    },
    {
        id: "258",
        name:"Pesticide and Other Agricultural Chemical Manufacturing",
        group: "Manufacturing",
        sicCode: "2879",
        naicsCode: "325320"
    },
    {
        id: "626",
        name:"Pet and Pet Supplies Stores",
        group: "#N/A",
        sicCode: "5149",
        naicsCode: "453910"
    },
    {
        id: "1010",
        name:"Pet Care (except Veterinary) Services",
        group: "Other Services (except Public Administration)",
        sicCode: "752",
        naicsCode: "812910"
    },
    {
        id: "245",
        name:"Petrochemical Manufacturing",
        group: "Manufacturing",
        sicCode: "2865",
        naicsCode: "325110"
    },
    {
        id: "558",
        name:"Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)",
        group: "Wholesale Trade",
        sicCode: "5172",
        naicsCode: "424720"
    },
    {
        id: "557",
        name:"Petroleum Bulk Stations and Terminals",
        group: "Wholesale Trade",
        sicCode: "5171",
        naicsCode: "424710"
    },
    {
        id: "243",
        name:"Petroleum Lubricating Oil and Grease Manufacturing",
        group: "Manufacturing",
        sicCode: "2992",
        naicsCode: "324191"
    },
    {
        id: "240",
        name:"Petroleum Refineries",
        group: "#N/A",
        sicCode: "2911",
        naicsCode: "324110"
    },
    {
        id: "260",
        name:"Pharmaceutical Preparation Manufacturing",
        group: "Manufacturing",
        sicCode: "2834",
        naicsCode: "325412"
    },
    {
        id: "597",
        name:"Pharmacies and Drug Stores",
        group: "#N/A",
        sicCode: "5122",
        naicsCode: "446110"
    },
    {
        id: "85",
        name:"Phosphate Rock Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1475",
        naicsCode: "212392"
    },
    {
        id: "256",
        name:"Phosphatic Fertilizer Manufacturing",
        group: "Manufacturing",
        sicCode: "2874",
        naicsCode: "325312"
    },
    {
        id: "1011",
        name:"Photofinishing Laboratories (except One-Hour)",
        group: "Other Services (except Public Administration)",
        sicCode: "7384",
        naicsCode: "812921"
    },
    {
        id: "373",
        name:"Photographic and Photocopying Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3577",
        naicsCode: "333316"
    },
    {
        id: "508",
        name:"Photographic Equipment and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5043",
        naicsCode: "423410"
    },
    {
        id: "272",
        name:"Photographic Film, Paper, Plate, and Chemical Manufacturing",
        group: "Manufacturing",
        sicCode: "3861",
        naicsCode: "325992"
    },
    {
        id: "832",
        name:"Photography Studios, Portrait",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7221",
        naicsCode: "541921"
    },
    {
        id: "539",
        name:"Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5131",
        naicsCode: "424310"
    },
    {
        id: "665",
        name:"Pipeline Transportation of Crude Oil",
        group: "Transportation and Warehousing",
        sicCode: "4612",
        naicsCode: "486110"
    },
    {
        id: "666",
        name:"Pipeline Transportation of Natural Gas",
        group: "Transportation and Warehousing",
        sicCode: "4922",
        naicsCode: "486210"
    },
    {
        id: "667",
        name:"Pipeline Transportation of Refined Petroleum Products",
        group: "Transportation and Warehousing",
        sicCode: "4613",
        naicsCode: "486910"
    },
    {
        id: "274",
        name:"Plastics Bag and Pouch Manufacturing",
        group: "Manufacturing",
        sicCode: "2673",
        naicsCode: "326111"
    },
    {
        id: "282",
        name:"Plastics Bottle Manufacturing",
        group: "Manufacturing",
        sicCode: "3085",
        naicsCode: "326160"
    },
    {
        id: "252",
        name:"Plastics Material and Resin Manufacturing",
        group: "Manufacturing",
        sicCode: "2821",
        naicsCode: "325211"
    },
    {
        id: "555",
        name:"Plastics Materials and Basic Forms and Shapes Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5162",
        naicsCode: "424610"
    },
    {
        id: "275",
        name:"Plastics Packaging Film and Sheet (including Laminated) Manufacturing",
        group: "Manufacturing",
        sicCode: "2671",
        naicsCode: "326112"
    },
    {
        id: "278",
        name:"Plastics Pipe and Pipe Fitting Manufacturing",
        group: "Manufacturing",
        sicCode: "3084",
        naicsCode: "326122"
    },
    {
        id: "283",
        name:"Plastics Plumbing Fixture Manufacturing",
        group: "Manufacturing",
        sicCode: "3088",
        naicsCode: "326191"
    },
    {
        id: "335",
        name:"Plate Work Manufacturing",
        group: "Manufacturing",
        sicCode: "3443",
        naicsCode: "332313"
    },
    {
        id: "521",
        name:"Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5064",
        naicsCode: "423720"
    },
    {
        id: "354",
        name:"Plumbing Fixture Fitting and Trim Manufacturing",
        group: "Manufacturing",
        sicCode: "3432",
        naicsCode: "332913"
    },
    {
        id: "128",
        name:"Plumbing, Heating, and Air-Conditioning Contractors",
        group: "Construction",
        sicCode: "1711",
        naicsCode: "238220"
    },
    {
        id: "1036",
        name:"Police Protection",
        group: "Public Administration",
        sicCode: "9221",
        naicsCode: "922120"
    },
    {
        id: "266",
        name:"Polish and Other Sanitation Good Manufacturing",
        group: "Manufacturing",
        sicCode: "2842",
        naicsCode: "325612"
    },
    {
        id: "1026",
        name:"Political Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8651",
        naicsCode: "813940"
    },
    {
        id: "280",
        name:"Polystyrene Foam Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3086",
        naicsCode: "326140"
    },
    {
        id: "676",
        name:"Port and Harbor Operations",
        group: "#N/A",
        sicCode: "4491",
        naicsCode: "488310"
    },
    {
        id: "744",
        name:"Portfolio Management",
        group: "Finance and Insurance",
        sicCode: "6282",
        naicsCode: "523920"
    },
    {
        id: "685",
        name:"Postal Service",
        group: "#N/A",
        sicCode: "4311",
        naicsCode: "491110"
    },
    {
        id: "60",
        name:"Postharvest Crop Activities (except Cotton Ginning)",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "723",
        naicsCode: "115114"
    },
    {
        id: "84",
        name:"Potash, Soda, and Borate Mineral Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1474",
        naicsCode: "212391"
    },
    {
        id: "9",
        name:"Potato Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "134",
        naicsCode: "111211"
    },
    {
        id: "290",
        name:"Pottery, Ceramics, and Plumbing Fixture Manufacturing",
        group: "Manufacturing",
        sicCode: "3261",
        naicsCode: "327110"
    },
    {
        id: "546",
        name:"Poultry and Poultry Product Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5144",
        naicsCode: "424440"
    },
    {
        id: "39",
        name:"Poultry Hatcheries",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "254",
        naicsCode: "112340"
    },
    {
        id: "165",
        name:"Poultry Processing",
        group: "#N/A",
        sicCode: "2015",
        naicsCode: "311615"
    },
    {
        id: "119",
        name:"Poured Concrete Foundation and Structure Contractors",
        group: "Construction",
        sicCode: "1771",
        naicsCode: "238110"
    },
    {
        id: "329",
        name:"Powder Metallurgy Part Manufacturing",
        group: "Manufacturing",
        sicCode: "3499",
        naicsCode: "332117"
    },
    {
        id: "115",
        name:"Power and Communication Line and Related Structures Construction",
        group: "Construction",
        sicCode: "1623",
        naicsCode: "237130"
    },
    {
        id: "339",
        name:"Power Boiler and Heat Exchanger Manufacturing",
        group: "Manufacturing",
        sicCode: "3443",
        naicsCode: "332410"
    },
    {
        id: "431",
        name:"Power, Distribution, and Specialty Transformer Manufacturing",
        group: "Manufacturing",
        sicCode: "3548",
        naicsCode: "335311"
    },
    {
        id: "393",
        name:"Power-Driven Handtool Manufacturing",
        group: "Manufacturing",
        sicCode: "3546",
        naicsCode: "333991"
    },
    {
        id: "347",
        name:"Precision Turned Product Manufacturing",
        group: "Manufacturing",
        sicCode: "3451",
        naicsCode: "332721"
    },
    {
        id: "333",
        name:"Prefabricated Metal Building and Component Manufacturing",
        group: "Manufacturing",
        sicCode: "3448",
        naicsCode: "332311"
    },
    {
        id: "223",
        name:"Prefabricated Wood Building Manufacturing",
        group: "Manufacturing",
        sicCode: "2452",
        naicsCode: "321992"
    },
    {
        id: "436",
        name:"Primary Battery Manufacturing",
        group: "Manufacturing",
        sicCode: "3692",
        naicsCode: "335912"
    },
    {
        id: "412",
        name:"Printed Circuit Assembly (Electronic Assembly) Manufacturing",
        group: "Manufacturing",
        sicCode: "3577",
        naicsCode: "334418"
    },
    {
        id: "535",
        name:"Printing and Writing Paper Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5111",
        naicsCode: "424110"
    },
    {
        id: "269",
        name:"Printing Ink Manufacturing",
        group: "Manufacturing",
        sicCode: "2893",
        naicsCode: "325910"
    },
    {
        id: "370",
        name:"Printing Machinery and Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3555",
        naicsCode: "333244"
    },
    {
        id: "1028",
        name:"Private Households",
        group: "Other Services (except Public Administration)",
        sicCode: "8811",
        naicsCode: "814110"
    },
    {
        id: "849",
        name:"Private Mail Centers",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561431"
    },
    {
        id: "815",
        name:"Process, Physical Distribution, and Logistics Consulting Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "4731",
        naicsCode: "541614"
    },
    {
        id: "889",
        name:"Professional and Management Development Training",
        group: "Educational Services",
        sicCode: "8299",
        naicsCode: "611430"
    },
    {
        id: "845",
        name:"Professional Employer Organizations",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7363",
        naicsCode: "561330"
    },
    {
        id: "1024",
        name:"Professional Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8621",
        naicsCode: "813920"
    },
    {
        id: "947",
        name:"Promoters of Performing Arts, Sports, and Similar Events with Facilities",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "6512",
        naicsCode: "711310"
    },
    {
        id: "948",
        name:"Promoters of Performing Arts, Sports, and Similar Events without Facilities",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7389",
        naicsCode: "711320"
    },
    {
        id: "923",
        name:"Psychiatric and Substance Abuse Hospitals",
        group: "Health Care and Social Assistance",
        sicCode: "8063",
        naicsCode: "622210"
    },
    {
        id: "1031",
        name:"Public Finance Activities",
        group: "Public Administration",
        sicCode: "9311",
        naicsCode: "921130"
    },
    {
        id: "824",
        name:"Public Relations Agencies",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8743",
        naicsCode: "541820"
    },
    {
        id: "225",
        name:"Pulp Mills",
        group: "#N/A",
        sicCode: "2611",
        naicsCode: "322110"
    },
    {
        id: "945",
        name:"Racetracks",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7948",
        naicsCode: "711212"
    },
    {
        id: "405",
        name:"Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3663",
        naicsCode: "334220"
    },
    {
        id: "709",
        name:"Radio Networks",
        group: "Information",
        sicCode: "4832",
        naicsCode: "515111"
    },
    {
        id: "710",
        name:"Radio Stations",
        group: "Information",
        sicCode: "4832",
        naicsCode: "515112"
    },
    {
        id: "464",
        name:"Railroad Rolling Stock Manufacturing",
        group: "Manufacturing",
        sicCode: "3531",
        naicsCode: "336510"
    },
    {
        id: "297",
        name:"Ready-Mix Concrete Manufacturing",
        group: "Manufacturing",
        sicCode: "3273",
        naicsCode: "327320"
    },
    {
        id: "731",
        name:"Real Estate Credit",
        group: "Finance and Insurance",
        sicCode: "6111",
        naicsCode: "522292"
    },
    {
        id: "217",
        name:"Reconstituted Wood Product Manufacturing",
        group: "Manufacturing",
        sicCode: "2493",
        naicsCode: "321219"
    },
    {
        id: "707",
        name:"Record Production and Distribution",
        group: "Information",
        sicCode: "3652",
        naicsCode: "512250"
    },
    {
        id: "970",
        name:"Recreational and Vacation Camps (except Campgrounds)",
        group: "Accommodation and Food Services",
        sicCode: "7032",
        naicsCode: "721214"
    },
    {
        id: "780",
        name:"Recreational Goods Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7999",
        naicsCode: "532284"
    },
    {
        id: "571",
        name:"Recreational Vehicle Dealers",
        group: "#N/A",
        sicCode: "5561",
        naicsCode: "441210"
    },
    {
        id: "532",
        name:"Recyclable Material Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5093",
        naicsCode: "423930"
    },
    {
        id: "689",
        name:"Refrigerated Warehousing and Storage",
        group: "Transportation and Warehousing",
        sicCode: "4222",
        naicsCode: "493120"
    },
    {
        id: "523",
        name:"Refrigeration Equipment and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5078",
        naicsCode: "423740"
    },
    {
        id: "1052",
        name:"Regulation and Administration of Communications, Electric, Gas, and Other Utilities",
        group: "Public Administration",
        sicCode: "9631",
        naicsCode: "926130"
    },
    {
        id: "1051",
        name:"Regulation and Administration of Transportation Programs",
        group: "Public Administration",
        sicCode: "9621",
        naicsCode: "926120"
    },
    {
        id: "1053",
        name:"Regulation of Agricultural Marketing and Commodities",
        group: "Public Administration",
        sicCode: "9641",
        naicsCode: "926140"
    },
    {
        id: "1054",
        name:"Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors",
        group: "Public Administration",
        sicCode: "9651",
        naicsCode: "926150"
    },
    {
        id: "753",
        name:"Reinsurance Carriers",
        group: "Finance and Insurance",
        sicCode: "6311",
        naicsCode: "524130"
    },
    {
        id: "434",
        name:"Relay and Industrial Control Manufacturing",
        group: "Manufacturing",
        sicCode: "3625",
        naicsCode: "335314"
    },
    {
        id: "1015",
        name:"Religious Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8661",
        naicsCode: "813110"
    },
    {
        id: "880",
        name:"Remediation Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "1799",
        naicsCode: "562910"
    },
    {
        id: "164",
        name:"Rendering and Meat Byproduct Processing",
        group: "#N/A",
        sicCode: "2013",
        naicsCode: "311613"
    },
    {
        id: "853",
        name:"Repossession Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561491"
    },
    {
        id: "820",
        name:"Research and Development in Biotechnology (except Nanobiotechnology)",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8731",
        naicsCode: "541714"
    },
    {
        id: "819",
        name:"Research and Development in Nanotechnology",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "3721",
        naicsCode: "541713"
    },
    {
        id: "821",
        name:"Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8731",
        naicsCode: "541715"
    },
    {
        id: "822",
        name:"Research and Development in the Social Sciences and Humanities",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8732",
        naicsCode: "541720"
    },
    {
        id: "426",
        name:"Residential Electric Lighting Fixture Manufacturing",
        group: "Manufacturing",
        sicCode: "3645",
        naicsCode: "335121"
    },
    {
        id: "926",
        name:"Residential Intellectual and Developmental Disability Facilities",
        group: "Health Care and Social Assistance",
        sicCode: "8051",
        naicsCode: "623210"
    },
    {
        id: "927",
        name:"Residential Mental Health and Substance Abuse Facilities",
        group: "Health Care and Social Assistance",
        sicCode: "8361",
        naicsCode: "623220"
    },
    {
        id: "769",
        name:"Residential Property Managers",
        group: "Real Estate and Rental and Leasing",
        sicCode: "6531",
        naicsCode: "531311"
    },
    {
        id: "110",
        name:"Residential Remodelers",
        group: "Construction",
        sicCode: "1521",
        naicsCode: "236118"
    },
    {
        id: "167",
        name:"Retail Bakeries",
        group: "#N/A",
        sicCode: "5461",
        naicsCode: "311811"
    },
    {
        id: "996",
        name:"Reupholstery and Furniture Repair",
        group: "Other Services (except Public Administration)",
        sicCode: "4581",
        naicsCode: "811420"
    },
    {
        id: "6",
        name:"Rice Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "112",
        naicsCode: "111160"
    },
    {
        id: "141",
        name:"Rice Milling",
        group: "#N/A",
        sicCode: "2044",
        naicsCode: "311212"
    },
    {
        id: "173",
        name:"Roasted Nuts and Peanut Butter Manufacturing",
        group: "Manufacturing",
        sicCode: "2068",
        naicsCode: "311911"
    },
    {
        id: "310",
        name:"Rolled Steel Shape Manufacturing",
        group: "Manufacturing",
        sicCode: "3312",
        naicsCode: "331221"
    },
    {
        id: "382",
        name:"Rolling Mill and Other Metalworking Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3547",
        naicsCode: "333519"
    },
    {
        id: "124",
        name:"Roofing Contractors",
        group: "Construction",
        sicCode: "1761",
        naicsCode: "238160"
    },
    {
        id: "506",
        name:"Roofing, Siding, and Insulation Material Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5033",
        naicsCode: "423330"
    },
    {
        id: "971",
        name:"Rooming and Boarding Houses, Dormitories, and Workers' Camps",
        group: "Accommodation and Food Services",
        sicCode: "7021",
        naicsCode: "721310"
    },
    {
        id: "198",
        name:"Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills",
        group: "#N/A",
        sicCode: "2296",
        naicsCode: "314994"
    },
    {
        id: "287",
        name:"Rubber and Plastics Hoses and Belting Manufacturing",
        group: "Manufacturing",
        sicCode: "3052",
        naicsCode: "326220"
    },
    {
        id: "288",
        name:"Rubber Product Manufacturing for Mechanical Use",
        group: "Manufacturing",
        sicCode: "3061",
        naicsCode: "326291"
    },
    {
        id: "969",
        name:"RV (Recreational Vehicle) Parks and Campgrounds",
        group: "Accommodation and Food Services",
        sicCode: "7033",
        naicsCode: "721211"
    },
    {
        id: "729",
        name:"Sales Financing",
        group: "Finance and Insurance",
        sicCode: "6141",
        naicsCode: "522220"
    },
    {
        id: "234",
        name:"Sanitary Paper Product Manufacturing",
        group: "Manufacturing",
        sicCode: "2676",
        naicsCode: "322291"
    },
    {
        id: "715",
        name:"Satellite Telecommunications",
        group: "Information",
        sicCode: "4899",
        naicsCode: "517410"
    },
    {
        id: "725",
        name:"Savings Institutions",
        group: "Finance and Insurance",
        sicCode: "6035",
        naicsCode: "522120"
    },
    {
        id: "332",
        name:"Saw Blade and Handtool Manufacturing",
        group: "Manufacturing",
        sicCode: "3421",
        naicsCode: "332216"
    },
    {
        id: "369",
        name:"Sawmill, Woodworking, and Paper Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3553",
        naicsCode: "333243"
    },
    {
        id: "211",
        name:"Sawmills",
        group: "#N/A",
        sicCode: "2421",
        naicsCode: "321113"
    },
    {
        id: "399",
        name:"Scale and Balance Manufacturing",
        group: "Manufacturing",
        sicCode: "2599",
        naicsCode: "333997"
    },
    {
        id: "669",
        name:"Scenic and Sightseeing Transportation, Land",
        group: "Transportation and Warehousing",
        sicCode: "4119",
        naicsCode: "487110"
    },
    {
        id: "671",
        name:"Scenic and Sightseeing Transportation, Other",
        group: "Transportation and Warehousing",
        sicCode: "4522",
        naicsCode: "487990"
    },
    {
        id: "670",
        name:"Scenic and Sightseeing Transportation, Water",
        group: "Transportation and Warehousing",
        sicCode: "4489",
        naicsCode: "487210"
    },
    {
        id: "636",
        name:"Scheduled Freight Air Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4512",
        naicsCode: "481112"
    },
    {
        id: "635",
        name:"Scheduled Passenger Air Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4512",
        naicsCode: "481111"
    },
    {
        id: "661",
        name:"School and Employee Bus Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4119",
        naicsCode: "485410"
    },
    {
        id: "166",
        name:"Seafood Product Preparation and Packaging",
        group: "#N/A",
        sicCode: "2077",
        naicsCode: "311710"
    },
    {
        id: "415",
        name:"Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing",
        group: "Manufacturing",
        sicCode: "3812",
        naicsCode: "334511"
    },
    {
        id: "733",
        name:"Secondary Market Financing",
        group: "Finance and Insurance",
        sicCode: "6111",
        naicsCode: "522294"
    },
    {
        id: "313",
        name:"Secondary Smelting and Alloying of Aluminum",
        group: "#N/A",
        sicCode: "3341",
        naicsCode: "331314"
    },
    {
        id: "319",
        name:"Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)",
        group: "#N/A",
        sicCode: "3341",
        naicsCode: "331492"
    },
    {
        id: "742",
        name:"Securities and Commodity Exchanges",
        group: "Finance and Insurance",
        sicCode: "6231",
        naicsCode: "523210"
    },
    {
        id: "739",
        name:"Securities Brokerage",
        group: "Finance and Insurance",
        sicCode: "6211",
        naicsCode: "523120"
    },
    {
        id: "861",
        name:"Security Guards and Patrol Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7381",
        naicsCode: "561612"
    },
    {
        id: "863",
        name:"Security Systems Services (except Locksmiths)",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7382",
        naicsCode: "561621"
    },
    {
        id: "409",
        name:"Semiconductor and Related Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3674",
        naicsCode: "334413"
    },
    {
        id: "368",
        name:"Semiconductor Machinery Manufacturing",
        group: "Manufacturing",
        sicCode: "3559",
        naicsCode: "333242"
    },
    {
        id: "882",
        name:"Septic Tank and Related Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7359",
        naicsCode: "562991"
    },
    {
        id: "528",
        name:"Service Establishment Equipment and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5087",
        naicsCode: "423850"
    },
    {
        id: "932",
        name:"Services for the Elderly and Persons with Disabilities",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624120"
    },
    {
        id: "105",
        name:"Sewage Treatment Facilities",
        group: "Utilities",
        sicCode: "4952",
        naicsCode: "221320"
    },
    {
        id: "615",
        name:"Sewing, Needlework, and Piece Goods Stores",
        group: "#N/A",
        sicCode: "5131",
        naicsCode: "451130"
    },
    {
        id: "41",
        name:"Sheep Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "214",
        naicsCode: "112410"
    },
    {
        id: "337",
        name:"Sheet Metal Work Manufacturing",
        group: "Manufacturing",
        sicCode: "3444",
        naicsCode: "332322"
    },
    {
        id: "44",
        name:"Shellfish Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "273",
        naicsCode: "112512"
    },
    {
        id: "54",
        name:"Shellfish Fishing",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "913",
        naicsCode: "114112"
    },
    {
        id: "465",
        name:"Ship Building and Repairing",
        group: "#N/A",
        sicCode: "3731",
        naicsCode: "336611"
    },
    {
        id: "610",
        name:"Shoe Stores",
        group: "#N/A",
        sicCode: "5139",
        naicsCode: "448210"
    },
    {
        id: "641",
        name:"Short Line Railroads",
        group: "#N/A",
        sicCode: "4013",
        naicsCode: "482112"
    },
    {
        id: "479",
        name:"Showcase, Partition, Shelving, and Locker Manufacturing",
        group: "Manufacturing",
        sicCode: "2426",
        naicsCode: "337215"
    },
    {
        id: "125",
        name:"Siding Contractors",
        group: "Construction",
        sicCode: "1761",
        naicsCode: "238170"
    },
    {
        id: "491",
        name:"Sign Manufacturing",
        group: "Manufacturing",
        sicCode: "3993",
        naicsCode: "339950"
    },
    {
        id: "72",
        name:"Silver Ore Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1044",
        naicsCode: "212222"
    },
    {
        id: "136",
        name:"Site Preparation Contractors",
        group: "Construction",
        sicCode: "1081",
        naicsCode: "238910"
    },
    {
        id: "960",
        name:"Skiing Facilities",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7999",
        naicsCode: "713920"
    },
    {
        id: "357",
        name:"Small Arms Ammunition Manufacturing",
        group: "Manufacturing",
        sicCode: "3482",
        naicsCode: "332992"
    },
    {
        id: "359",
        name:"Small Arms, Ordnance, and Ordnance Accessories Manufacturing",
        group: "Manufacturing",
        sicCode: "3484",
        naicsCode: "332994"
    },
    {
        id: "429",
        name:"Small Electrical Appliance Manufacturing",
        group: "Manufacturing",
        sicCode: "3634",
        naicsCode: "335210"
    },
    {
        id: "979",
        name:"Snack and Nonalcoholic Beverage Bars",
        group: "Accommodation and Food Services",
        sicCode: "5461",
        naicsCode: "722515"
    },
    {
        id: "265",
        name:"Soap and Other Detergent Manufacturing",
        group: "Manufacturing",
        sicCode: "2841",
        naicsCode: "325611"
    },
    {
        id: "181",
        name:"Soft Drink Manufacturing",
        group: "Manufacturing",
        sicCode: "2086",
        naicsCode: "312111"
    },
    {
        id: "424",
        name:"Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing",
        group: "#N/A",
        sicCode: "3652",
        naicsCode: "334614"
    },
    {
        id: "698",
        name:"Software Publishers",
        group: "Information",
        sicCode: "7372",
        naicsCode: "511210"
    },
    {
        id: "214",
        name:"Softwood Veneer and Plywood Manufacturing",
        group: "Manufacturing",
        sicCode: "2436",
        naicsCode: "321212"
    },
    {
        id: "58",
        name:"Soil Preparation, Planting, and Cultivating",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "711",
        naicsCode: "115112"
    },
    {
        id: "96",
        name:"Solar Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221114"
    },
    {
        id: "873",
        name:"Solid Waste Collection",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4212",
        naicsCode: "562111"
    },
    {
        id: "878",
        name:"Solid Waste Combustors and Incinerators",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4953",
        naicsCode: "562213"
    },
    {
        id: "877",
        name:"Solid Waste Landfill",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4953",
        naicsCode: "562212"
    },
    {
        id: "706",
        name:"Sound Recording Studios",
        group: "Information",
        sicCode: "7389",
        naicsCode: "512240"
    },
    {
        id: "144",
        name:"Soybean and Other Oilseed Processing",
        group: "#N/A",
        sicCode: "2074",
        naicsCode: "311224"
    },
    {
        id: "1",
        name:"Soybean Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "116",
        naicsCode: "111110"
    },
    {
        id: "1055",
        name:"Space Research and Technology",
        group: "Public Administration",
        sicCode: "9661",
        naicsCode: "927110"
    },
    {
        id: "379",
        name:"Special Die and Tool, Die Set, Jig, and Fixture Manufacturing",
        group: "Manufacturing",
        sicCode: "3544",
        naicsCode: "333514"
    },
    {
        id: "663",
        name:"Special Needs Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4119",
        naicsCode: "485991"
    },
    {
        id: "652",
        name:"Specialized Freight (except Used Goods) Trucking, Local",
        group: "#N/A",
        sicCode: "4212",
        naicsCode: "484220"
    },
    {
        id: "653",
        name:"Specialized Freight (except Used Goods) Trucking, Long-Distance",
        group: "#N/A",
        sicCode: "4213",
        naicsCode: "484230"
    },
    {
        id: "924",
        name:"Specialty (except Psychiatric and Substance Abuse) Hospitals",
        group: "Health Care and Social Assistance",
        sicCode: "8069",
        naicsCode: "622310"
    },
    {
        id: "155",
        name:"Specialty Canning",
        group: "#N/A",
        sicCode: "2032",
        naicsCode: "311422"
    },
    {
        id: "384",
        name:"Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing",
        group: "Manufacturing",
        sicCode: "3566",
        naicsCode: "333612"
    },
    {
        id: "178",
        name:"Spice and Extract Manufacturing",
        group: "Manufacturing",
        sicCode: "2082",
        naicsCode: "311942"
    },
    {
        id: "488",
        name:"Sporting and Athletic Goods Manufacturing",
        group: "Manufacturing",
        sicCode: "3069",
        naicsCode: "339920"
    },
    {
        id: "530",
        name:"Sporting and Recreational Goods and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5091",
        naicsCode: "423910"
    },
    {
        id: "613",
        name:"Sporting Goods Stores",
        group: "#N/A",
        sicCode: "5091",
        naicsCode: "451110"
    },
    {
        id: "895",
        name:"Sports and Recreation Instruction",
        group: "Educational Services",
        sicCode: "7999",
        naicsCode: "611620"
    },
    {
        id: "944",
        name:"Sports Teams and Clubs",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "7941",
        naicsCode: "711211"
    },
    {
        id: "344",
        name:"Spring Manufacturing",
        group: "Manufacturing",
        sicCode: "3493",
        naicsCode: "332613"
    },
    {
        id: "536",
        name:"Stationery and Office Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5112",
        naicsCode: "424120"
    },
    {
        id: "233",
        name:"Stationery Product Manufacturing",
        group: "Manufacturing",
        sicCode: "2675",
        naicsCode: "322230"
    },
    {
        id: "106",
        name:"Steam and Air-Conditioning Supply",
        group: "Utilities",
        sicCode: "4961",
        naicsCode: "221330"
    },
    {
        id: "322",
        name:"Steel Foundries (except Investment)",
        group: "#N/A",
        sicCode: "3325",
        naicsCode: "331513"
    },
    {
        id: "321",
        name:"Steel Investment Foundries",
        group: "#N/A",
        sicCode: "3324",
        naicsCode: "331512"
    },
    {
        id: "311",
        name:"Steel Wire Drawing",
        group: "#N/A",
        sicCode: "3315",
        naicsCode: "331222"
    },
    {
        id: "435",
        name:"Storage Battery Manufacturing",
        group: "Manufacturing",
        sicCode: "3691",
        naicsCode: "335911"
    },
    {
        id: "15",
        name:"Strawberry Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "171",
        naicsCode: "111333"
    },
    {
        id: "120",
        name:"Structural Steel and Precast Concrete Contractors",
        group: "Construction",
        sicCode: "1791",
        naicsCode: "238120"
    },
    {
        id: "28",
        name:"Sugar Beet Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "133",
        naicsCode: "111991"
    },
    {
        id: "26",
        name:"Sugarcane Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "133",
        naicsCode: "111930"
    },
    {
        id: "588",
        name:"Supermarkets and Other Grocery (except Convenience) Stores",
        group: "#N/A",
        sicCode: "5141",
        naicsCode: "445110"
    },
    {
        id: "63",
        name:"Support Activities for Animal Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "751",
        naicsCode: "115210"
    },
    {
        id: "90",
        name:"Support Activities for Coal Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1241",
        naicsCode: "213113"
    },
    {
        id: "64",
        name:"Support Activities for Forestry",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "851",
        naicsCode: "115310"
    },
    {
        id: "91",
        name:"Support Activities for Metal Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1081",
        naicsCode: "213114"
    },
    {
        id: "92",
        name:"Support Activities for Nonmetallic Minerals (except Fuels) Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1481",
        naicsCode: "213115"
    },
    {
        id: "89",
        name:"Support Activities for Oil and Gas Operations",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1382",
        naicsCode: "213112"
    },
    {
        id: "239",
        name:"Support Activities for Printing",
        group: "#N/A",
        sicCode: "2789",
        naicsCode: "323120"
    },
    {
        id: "675",
        name:"Support Activities for Rail Transportation",
        group: "Transportation and Warehousing",
        sicCode: "4013",
        naicsCode: "488210"
    },
    {
        id: "267",
        name:"Surface Active Agent Manufacturing",
        group: "Manufacturing",
        sicCode: "2843",
        naicsCode: "325613"
    },
    {
        id: "482",
        name:"Surgical and Medical Instrument Manufacturing",
        group: "Manufacturing",
        sicCode: "3829",
        naicsCode: "339112"
    },
    {
        id: "483",
        name:"Surgical Appliance and Supplies Manufacturing",
        group: "Manufacturing",
        sicCode: "2499",
        naicsCode: "339113"
    },
    {
        id: "802",
        name:"Surveying and Mapping (except Geophysical) Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541370"
    },
    {
        id: "433",
        name:"Switchgear and Switchboard Apparatus Manufacturing",
        group: "Manufacturing",
        sicCode: "3613",
        naicsCode: "335313"
    },
    {
        id: "247",
        name:"Synthetic Dye and Pigment Manufacturing",
        group: "Manufacturing",
        sicCode: "2816",
        naicsCode: "325130"
    },
    {
        id: "253",
        name:"Synthetic Rubber Manufacturing",
        group: "Manufacturing",
        sicCode: "2822",
        naicsCode: "325212"
    },
    {
        id: "793",
        name:"Tax Preparation Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7291",
        naicsCode: "541213"
    },
    {
        id: "659",
        name:"Taxi Service",
        group: "#N/A",
        sicCode: "4121",
        naicsCode: "485310"
    },
    {
        id: "716",
        name:"Telecommunications Resellers",
        group: "Information",
        sicCode: "4812",
        naicsCode: "517911"
    },
    {
        id: "848",
        name:"Telemarketing Bureaus and Other Contact Centers",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561422"
    },
    {
        id: "847",
        name:"Telephone Answering Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7389",
        naicsCode: "561421"
    },
    {
        id: "404",
        name:"Telephone Apparatus Manufacturing",
        group: "Manufacturing",
        sicCode: "3661",
        naicsCode: "334210"
    },
    {
        id: "703",
        name:"Teleproduction and Other Postproduction Services",
        group: "Information",
        sicCode: "7819",
        naicsCode: "512191"
    },
    {
        id: "711",
        name:"Television Broadcasting",
        group: "Information",
        sicCode: "4833",
        naicsCode: "515120"
    },
    {
        id: "844",
        name:"Temporary Help Services",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "7363",
        naicsCode: "561320"
    },
    {
        id: "935",
        name:"Temporary Shelters",
        group: "Health Care and Social Assistance",
        sicCode: "8322",
        naicsCode: "624221"
    },
    {
        id: "803",
        name:"Testing Laboratories",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "8734",
        naicsCode: "541380"
    },
    {
        id: "193",
        name:"Textile and Fabric Finishing Mills",
        group: "#N/A",
        sicCode: "2231",
        naicsCode: "313310"
    },
    {
        id: "197",
        name:"Textile Bag and Canvas Mills",
        group: "#N/A",
        sicCode: "2392",
        naicsCode: "314910"
    },
    {
        id: "940",
        name:"Theater Companies and Dinner Theaters",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "5812",
        naicsCode: "711110"
    },
    {
        id: "756",
        name:"Third Party Administration of Insurance and Pension Funds",
        group: "Finance and Insurance",
        sicCode: "6371",
        naicsCode: "524292"
    },
    {
        id: "133",
        name:"Tile and Terrazzo Contractors",
        group: "Construction",
        sicCode: "1743",
        naicsCode: "238340"
    },
    {
        id: "50",
        name:"Timber Tract Operations",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "811",
        naicsCode: "113110"
    },
    {
        id: "500",
        name:"Tire and Tube Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5014",
        naicsCode: "423130"
    },
    {
        id: "575",
        name:"Tire Dealers",
        group: "#N/A",
        sicCode: "5014",
        naicsCode: "441320"
    },
    {
        id: "285",
        name:"Tire Manufacturing (except Retreading)",
        group: "Manufacturing",
        sicCode: "3011",
        naicsCode: "326211"
    },
    {
        id: "286",
        name:"Tire Retreading",
        group: "#N/A",
        sicCode: "7534",
        naicsCode: "326212"
    },
    {
        id: "790",
        name:"Title Abstract and Settlement Offices",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "6541",
        naicsCode: "541191"
    },
    {
        id: "564",
        name:"Tobacco and Tobacco Product Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5194",
        naicsCode: "424940"
    },
    {
        id: "24",
        name:"Tobacco Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "132",
        naicsCode: "111910"
    },
    {
        id: "187",
        name:"Tobacco Manufacturing",
        group: "Manufacturing",
        sicCode: "2111",
        naicsCode: "312230"
    },
    {
        id: "629",
        name:"Tobacco Stores",
        group: "#N/A",
        sicCode: "5194",
        naicsCode: "453991"
    },
    {
        id: "268",
        name:"Toilet Preparation Manufacturing",
        group: "Manufacturing",
        sicCode: "2844",
        naicsCode: "325620"
    },
    {
        id: "172",
        name:"Tortilla Manufacturing",
        group: "Manufacturing",
        sicCode: "2099",
        naicsCode: "311830"
    },
    {
        id: "418",
        name:"Totalizing Fluid Meter and Counting Device Manufacturing",
        group: "Manufacturing",
        sicCode: "3824",
        naicsCode: "334514"
    },
    {
        id: "857",
        name:"Tour Operators",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4725",
        naicsCode: "561520"
    },
    {
        id: "531",
        name:"Toy and Hobby Goods and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5092",
        naicsCode: "423920"
    },
    {
        id: "834",
        name:"Translation and Interpretation Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "7389",
        naicsCode: "541930"
    },
    {
        id: "529",
        name:"Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5088",
        naicsCode: "423860"
    },
    {
        id: "856",
        name:"Travel Agencies",
        group: "Administrative and Support and Waste Management and Remediation Services",
        sicCode: "4724",
        naicsCode: "561510"
    },
    {
        id: "449",
        name:"Travel Trailer and Camper Manufacturing",
        group: "Manufacturing",
        sicCode: "3792",
        naicsCode: "336214"
    },
    {
        id: "17",
        name:"Tree Nut Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "173",
        naicsCode: "111335"
    },
    {
        id: "447",
        name:"Truck Trailer Manufacturing",
        group: "Manufacturing",
        sicCode: "3715",
        naicsCode: "336212"
    },
    {
        id: "775",
        name:"Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7513",
        naicsCode: "532120"
    },
    {
        id: "216",
        name:"Truss Manufacturing",
        group: "Manufacturing",
        sicCode: "2439",
        naicsCode: "321214"
    },
    {
        id: "746",
        name:"Trust, Fiduciary, and Custody Activities",
        group: "Finance and Insurance",
        sicCode: "6091",
        naicsCode: "523991"
    },
    {
        id: "762",
        name:"Trusts, Estates, and Agency Accounts",
        group: "Finance and Insurance",
        sicCode: "6733",
        naicsCode: "525920"
    },
    {
        id: "383",
        name:"Turbine and Turbine Generator Set Units Manufacturing",
        group: "Manufacturing",
        sicCode: "3511",
        naicsCode: "333611"
    },
    {
        id: "38",
        name:"Turkey Production",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "253",
        naicsCode: "112330"
    },
    {
        id: "276",
        name:"Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing",
        group: "Manufacturing",
        sicCode: "3081",
        naicsCode: "326113"
    },
    {
        id: "277",
        name:"Unlaminated Plastics Profile Shape Manufacturing",
        group: "Manufacturing",
        sicCode: "3082",
        naicsCode: "326121"
    },
    {
        id: "471",
        name:"Upholstered Household Furniture Manufacturing",
        group: "Manufacturing",
        sicCode: "2512",
        naicsCode: "337121"
    },
    {
        id: "74",
        name:"Uranium-Radium-Vanadium Ore Mining",
        group: "Mining, Quarrying, and Oil and Gas Extraction",
        sicCode: "1094",
        naicsCode: "212291"
    },
    {
        id: "281",
        name:"Urethane and Other Foam Product (except Polystyrene) Manufacturing",
        group: "Manufacturing",
        sicCode: "3086",
        naicsCode: "326150"
    },
    {
        id: "570",
        name:"Used Car Dealers",
        group: "#N/A",
        sicCode: "5521",
        naicsCode: "441120"
    },
    {
        id: "651",
        name:"Used Household and Office Goods Moving",
        group: "#N/A",
        sicCode: "4212",
        naicsCode: "484210"
    },
    {
        id: "625",
        name:"Used Merchandise Stores",
        group: "#N/A",
        sicCode: "5932",
        naicsCode: "453310"
    },
    {
        id: "632",
        name:"Vending Machine Operators",
        group: "#N/A",
        sicCode: "5962",
        naicsCode: "454210"
    },
    {
        id: "835",
        name:"Veterinary Services",
        group: "Professional, Scientific, and Technical Services",
        sicCode: "741",
        naicsCode: "541940"
    },
    {
        id: "778",
        name:"Video Tape and Disc Rental",
        group: "Real Estate and Rental and Leasing",
        sicCode: "7841",
        naicsCode: "532282"
    },
    {
        id: "938",
        name:"Vocational Rehabilitation Services",
        group: "Health Care and Social Assistance",
        sicCode: "8331",
        naicsCode: "624310"
    },
    {
        id: "1017",
        name:"Voluntary Health Organizations",
        group: "Other Services (except Public Administration)",
        sicCode: "8399",
        naicsCode: "813212"
    },
    {
        id: "620",
        name:"Warehouse Clubs and Supercenters",
        group: "Transportation and Warehousing",
        sicCode: "5311",
        naicsCode: "452311"
    },
    {
        id: "522",
        name:"Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5075",
        naicsCode: "423730"
    },
    {
        id: "113",
        name:"Water and Sewer Line and Related Structures Construction",
        group: "Construction",
        sicCode: "1623",
        naicsCode: "237110"
    },
    {
        id: "104",
        name:"Water Supply and Irrigation Systems",
        group: "Utilities",
        sicCode: "4941",
        naicsCode: "221310"
    },
    {
        id: "394",
        name:"Welding and Soldering Equipment Manufacturing",
        group: "Manufacturing",
        sicCode: "3548",
        naicsCode: "333992"
    },
    {
        id: "143",
        name:"Wet Corn Milling",
        group: "#N/A",
        sicCode: "2046",
        naicsCode: "311221"
    },
    {
        id: "4",
        name:"Wheat Farming",
        group: "Agriculture, Forestry, Fishing and Hunting",
        sicCode: "111",
        naicsCode: "111140"
    },
    {
        id: "568",
        name:"Wholesale Trade Agents and Brokers",
        group: "Wholesale Trade",
        sicCode: "5012",
        naicsCode: "425120"
    },
    {
        id: "97",
        name:"Wind Electric Power Generation",
        group: "Utilities",
        sicCode: "4911",
        naicsCode: "221115"
    },
    {
        id: "578",
        name:"Window Treatment Stores",
        group: "#N/A",
        sicCode: "5714",
        naicsCode: "442291"
    },
    {
        id: "560",
        name:"Wine and Distilled Alcoholic Beverage Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5182",
        naicsCode: "424820"
    },
    {
        id: "185",
        name:"Wineries",
        group: "#N/A",
        sicCode: "2084",
        naicsCode: "312130"
    },
    {
        id: "713",
        name:"Wired Telecommunications Carriers",
        group: "Information",
        sicCode: "4813",
        naicsCode: "517311"
    },
    {
        id: "714",
        name:"Wireless Telecommunications Carriers (except Satellite)",
        group: "Information",
        sicCode: "4812",
        naicsCode: "517312"
    },
    {
        id: "605",
        name:"Women's Clothing Stores",
        group: "#N/A",
        sicCode: "5137",
        naicsCode: "448120"
    },
    {
        id: "209",
        name:"Women's Handbag and Purse Manufacturing",
        group: "Manufacturing",
        sicCode: "3171",
        naicsCode: "316992"
    },
    {
        id: "541",
        name:"Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers",
        group: "Wholesale Trade",
        sicCode: "5137",
        naicsCode: "424330"
    },
    {
        id: "204",
        name:"Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing",
        group: "Manufacturing",
        sicCode: "2331",
        naicsCode: "315240"
    },
    {
        id: "221",
        name:"Wood Container and Pallet Manufacturing",
        group: "Manufacturing",
        sicCode: "2421",
        naicsCode: "321920"
    },
    {
        id: "470",
        name:"Wood Kitchen Cabinet and Countertop Manufacturing",
        group: "Manufacturing",
        sicCode: "2434",
        naicsCode: "337110"
    },
    {
        id: "476",
        name:"Wood Office Furniture Manufacturing",
        group: "Manufacturing",
        sicCode: "2521",
        naicsCode: "337211"
    },
    {
        id: "212",
        name:"Wood Preservation",
        group: "#N/A",
        sicCode: "2491",
        naicsCode: "321114"
    },
    {
        id: "218",
        name:"Wood Window and Door Manufacturing",
        group: "Manufacturing",
        sicCode: "2431",
        naicsCode: "321911"
    },
    {
        id: "953",
        name:"Zoos and Botanical Gardens",
        group: "Arts, Entertainment, and Recreation",
        sicCode: "8422",
        naicsCode: "712130"
    }
];