import React from "react";
import { Typography, Grid, Theme, createStyles, withTheme, withStyles, WithStyles, Button, LinearProgress, FormControlLabel, Checkbox, FormGroup } from "@material-ui/core";
import { InputData } from "../../../util/ui/form/InputData";
import { AbstractInfo } from "./AbstractInfo";
import { FindBusinessResult } from "./business/FindBusinessResult";
import { GuessConfirmed } from "./GuessConfirmedEnum";
import { FormInputPropertiesInterface } from "../../../util/ui/form/FormInputProperties";
import BasicInput from "../../../util/ui/form/inputs/BasicInput";
import { InputInfo } from "../../../util/ui/form/AbstractBasicInput";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import _ from 'lodash';
import validator from "validator";
import httpClient, { HttpClientResponse } from "../../../util/HttpClient";


import OtherSelect from "../../../util/ui/form/OtherSelect";
import { entityTypeOptionsMerchantForm, IndustryTypeNaics, lengthOfCurrentOwnershipList, naicsList, useOfProceedsOptions, vendorEquipmentType, vendorTypeOptions } from "../../../util/SelectOptions";

import BasicSelect from "../../../util/ui/form/BasicSelect";
import CopyRecommendedInput from "../../../util/ui/form/inputs/CopyRecommendedInput";
import SmartyAddressInput from "../../../util/ui/form/inputs/SmartyAddressInput";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import { FORMAPI_BASE } from "../BaseUtil";
import CurrencyInput from "../../../util/ui/form/inputs/CurrencyInput";
import PhoneInput from "../../../util/ui/form/inputs/PhoneInput";
import CopyRecommendedInputTaxId from "../../../util/ui/form/inputs/CopyRecommendedInputTaxId";
import YearMonthPicker from "../../../util/ui/form/inputs/YearMonthPicker";
import StringUtils from "../../../util/StringUtils";
import { isValid, parse } from "date-fns";
import { enUS } from "date-fns/locale";
import IndustryTypeNaicsSelect from "../../../util/ui/form/IndustryTypeNaicsSelect";


export enum VendorInputFormNames {
    vendorName = "vendorName",
    vendorPhone = "vendorPhone",
    vendorAddress = "vendorAddress",
    vendorCity = "vendorCity",
    vendorState = "vendorState",
    vendorZip = "vendorZip",
    vendorEquipmentDescription = "vendorEquipmentDescription",
    vendorEquipmentAmount = "vendorEquipmentAmount",
    vendorTermsRequested = "vendorTermsRequested",
    vendorTitled = "vendorTitled",
    vendorType = "vendorType",
}

export const FORM_PROPERTY_LIST: {
    [n: string]: FormInputPropertiesInterface
} = {
    'vendorName' : {
        name: VendorInputFormNames.vendorName,
        label: 'Vendor Name',
        autofill: VendorInputFormNames.vendorName,
        placeholder: 'Enter name of vendor',
        required: true,
        type: 'text'
    },
    'vendorPhone': {
        name: VendorInputFormNames.vendorPhone,
        label: 'Phone #',
        autofill: VendorInputFormNames.vendorPhone,
        placeholder: '(999) 999-9999',
        required: true,
        type: 'phone',
        inputMode: 'numeric'
    },
    'vendorAddress': {
        name: VendorInputFormNames.vendorAddress,
        label: 'Address',
        autofill: VendorInputFormNames.vendorAddress,
        placeholder: 'Enter address of vendor',
        required: true,
        type: 'text'
    },
    'vendorCity': {
        name: VendorInputFormNames.vendorCity,
        label: 'City',
        autofill: VendorInputFormNames.vendorCity,
        placeholder: 'Enter city of vendor',
        required: true,
        type: 'text'
    },
    'vendorState': {
        name: VendorInputFormNames.vendorState,
        label: 'State',
        autofill: VendorInputFormNames.vendorState,
        placeholder: 'Enter state of vendor',
        required: true,
        type: 'text'
    },
    'vendorZip': {
        name: VendorInputFormNames.vendorZip,
        label: 'Zip',
        autofill: VendorInputFormNames.vendorZip,
        placeholder: '10001',
        required: true,
        type: 'phone',
        inputMode: 'numeric',
        maxLength: 5
    },
    'vendorEquipmentDescription': {
        name: VendorInputFormNames.vendorEquipmentDescription,
        label: 'Equipment Description',
        autofill: VendorInputFormNames.vendorEquipmentDescription,
        placeholder: 'Enter equipment description',
        required: true,
        type: 'text'
    },
    'vendorEquipmentAmount': {
        name: VendorInputFormNames.vendorEquipmentAmount,
        label: 'Equipment Amount: $',
        autofill: VendorInputFormNames.vendorEquipmentAmount,
        placeholder: 'Enter equipment amount',
        required: true,
        type: 'number'
    },
    'vendorTermsRequested': {
        name: VendorInputFormNames.vendorTermsRequested,
        label: 'Terms Requested (# of months) ',
        autofill: VendorInputFormNames.vendorTermsRequested,
        placeholder: 'Enter terms requested',
        required: true,
        type: 'number'
    },
    'vendorTitled': {
        name: VendorInputFormNames.vendorTitled,
        label: 'Titled/Non-Titled',
        autofill: VendorInputFormNames.vendorTitled,
        placeholder: 'Enter titled/non-titled',
        required: true,
        type: 'text'
    },
    'vendorType': {
        name: VendorInputFormNames.vendorType,
        label: 'Type',
        autofill: VendorInputFormNames.vendorType,
        placeholder: '',
        required: true,
        type: 'custom',
    }
};

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    },
    gridMarginTopSmall: {
        marginTop: theme.spacing(2),
        Border: '1px solid #ccc',
    },
    gridMarginTop: {
        marginTop: theme.spacing(2),
       //borderBottom: '1px solid #bdbdbd',
        display: 'inline-flex',
    },
    vendorBorder: {
        borderBottom: '1px solid #bdbdbd',
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    AddMoreButton: {
        marginLeft: 0,
        margin: theme.spacing(1),
        backgroundColor: '#e0e0e0',
        color: '#556CD4',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            color: '#556CD4',
        },
    },
});


export class VendorInfo extends AbstractInfo {

}

interface VendorInfoFormProps extends WithStyles<typeof styles> {
    vendorInfo: VendorInfo;
    onVendorInfoChange: (vendorInfo: VendorInfo, index: number) => void;
    onVendorDeleteClick: (index: number) => void;
    errorFields: Map<string, ErrorInfo>;
    index: number;
}

class VendorInfoFormState {
}

class VendorInfoForm extends React.Component<VendorInfoFormProps, VendorInfoFormState> {

    protected vendorInfo: VendorInfo = new VendorInfo([]);
    private errorFields: Map<string, ErrorInfo> = new Map();

    constructor(props: VendorInfoFormProps) {
        super(props);
        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
    }

    handleBasicInputChange(input: InputInfo, event?: React.ChangeEvent<HTMLInputElement>) {

        let vendorInfo = _.cloneDeep(this.props.vendorInfo);

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        vendorInfo.setDataByName(inputData);

        this.props.onVendorInfoChange(vendorInfo, this.props.index);
    }

    render() {

        return this.getJsx();

    }


    getJsx(): JSX.Element {

        let fields = [
            VendorInputFormNames.vendorName,
            VendorInputFormNames.vendorPhone,
            VendorInputFormNames.vendorAddress,
            VendorInputFormNames.vendorCity,
            VendorInputFormNames.vendorState,
            VendorInputFormNames.vendorZip,
            VendorInputFormNames.vendorEquipmentDescription,
            VendorInputFormNames.vendorEquipmentAmount,
            VendorInputFormNames.vendorTermsRequested,
            VendorInputFormNames.vendorTitled,
            VendorInputFormNames.vendorType
        ];

        let gridItems: {
            elm: JSX.Element,
            width?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
        }[] = [];
        let o = 0;
        for (var i in fields) {

            let field = fields[i];

            o++;
            switch (field) {

                case VendorInputFormNames.vendorType:

                    gridItems.push(
                        {
                            elm: <BasicSelect
                                inputProps={FORM_PROPERTY_LIST[field]}
                                // error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.props.vendorInfo.getDataByName(field)?.value}
                                options={vendorEquipmentType}>
                            </BasicSelect>
                        }
                    );

                    break;

                case VendorInputFormNames.vendorEquipmentAmount:

                    gridItems.push({
                        elm: <CurrencyInput
                        inputProps={FORM_PROPERTY_LIST[field]}
                        // error={this.state.errorFields.get(field)?.error}
                        onInputChange={this.handleBasicInputChange}
                        value={this.props.vendorInfo.getDataByName(field)?.value}>
                    </CurrencyInput>
                    })

                    break;

                case VendorInputFormNames.vendorPhone:

                    gridItems.push(
                        {
                            elm: <PhoneInput
                                inputProps={FORM_PROPERTY_LIST[field]}
                                // error={this.state.errorFields.get(field)?.error}
                                onInputChange={this.handleBasicInputChange}
                                value={this.props.vendorInfo.getDataByName(field)?.value}>
                            </PhoneInput>
                        }
                    );

                    break;

                default:

                    gridItems.push({
                        elm: <BasicInput
                            inputProps={FORM_PROPERTY_LIST[field]}
                            // error={this.state.errorFields.get(field)?.error}
                            onInputChange={this.handleBasicInputChange}
                            value={this.props.vendorInfo.getDataByName(field)?.value}
                            formOrder={o}
                        />
                    });
                    break;
            }
        }

        return <React.Fragment>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    {/* show "Vendor Information" as title and next to it add a delete icon if index > 1 */}

                    <Typography variant="h6" gutterBottom>
                        Vendor #{this.props.index+1} Information
                    </Typography>

                    {this.props.index > 0 &&
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            startIcon={<i className="fas fa-trash"></i>}
                            onClick={() => { this.props.onVendorDeleteClick(this.props.index); }}
                        >
                            Delete Vendor #{this.props.index+1}
                        </Button>
                    }

                </Grid>

                <Grid item xs={12} >
                    <Grid container spacing={2}>

                        {gridItems.map((it, index) => {
                            let itemJSX = it.elm;
                            let width = it.width ? it.width : 6;
                            return <Grid key={index} item xs={12} sm={width} >
                                {itemJSX}
                            </Grid>
                        })}
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>;
    }
}

export default withTheme(withStyles(styles)(VendorInfoForm));