import React from "react";
import { WithStyles, Theme, createStyles, withStyles, withTheme, LinearProgress } from "@material-ui/core";
import EachOwnerForm, { OwnerFormInputNames, EachOwnerInternalSteps, FORM_PROPERTY_LIST } from "./owner/EachOwnerForm";
import { InputData } from "../../../util/ui/form/InputData";
import httpClient, { HttpClientResponse } from "../../../util/HttpClient";

import { FindConsumerResult } from "./owner/FindConsumerResult";
import _ from 'lodash';
import { AbstractInfo } from "./AbstractInfo";
import { GuessConfirmed } from "./GuessConfirmedEnum";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import { FORMAPI_BASE } from "../BaseUtil";
import { ClientAppConfig } from "../../../util/AppUtil";
import WindowUtils from "../../../util/WindowUtils";

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    interstitialContainer: {
        justifyContent: 'center',
    },
    headerWarningGridItem: {
        textAlign: 'right'
    }
});


export class OwnerInfo extends AbstractInfo {

}

enum CurrentScreen {
    Initial = -1, Owner = 0, Interstitial = 1
}

export class OwnerInfoFormData {
    amountRequested: InputData = new InputData();
    ownerInfos: EachOwnerFormInfo[] = [];
    activeOwnerIndex: number = 0;
    currentScreen: number = CurrentScreen.Owner;
}

class OwnerInfoFormState {
    amountRequested: InputData = new InputData();
    useOfProceeds: InputData = new InputData();
    ownerInfos: EachOwnerFormInfo[] = [];
    activeOwnerIndex: number = 0;
    currentScreen: number = CurrentScreen.Initial;
    fullEdit: boolean = false;
}

export class EachOwnerFirstGuess {
    performed: boolean = false;
    found: boolean = false;
    confirmed: number = 0;
    resultInfo: FindConsumerResult = new FindConsumerResult();
}

export class EachOwnerFormInfo {

    firstGuess: EachOwnerFirstGuess = new EachOwnerFirstGuess();
    ownerInfo: OwnerInfo = new OwnerInfo();
    amountRequested: InputData = new InputData();
    useOfProceeds: InputData = new InputData();
    permissionToContactTerms: {
        [s: number]: boolean
    } = {};
    permissionToContactTermsArray: string[] = [];

    getCurrentStep(): number {

        if (!this.firstGuess.performed) {
            return EachOwnerInternalSteps.Start;
        }

        if (this.firstGuess.found && this.firstGuess.confirmed === GuessConfirmed.Yes) {
            return EachOwnerInternalSteps.Followup;
        }

        if (this.firstGuess.found && this.firstGuess.confirmed === GuessConfirmed.No) {
            return EachOwnerInternalSteps.Followup;
        }

        if (this.firstGuess.performed && !this.firstGuess.found) {
            return EachOwnerInternalSteps.Followup;
        }

        if (this.firstGuess.found && this.firstGuess.confirmed === GuessConfirmed.Unknown) {
            return EachOwnerInternalSteps.ConfirmPersonalInfoGuess;
        }

        return EachOwnerInternalSteps.Start;
    }
}

interface OwnerInfoFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    onAmountRequestedChange(amountRequested: InputData): void;
    onUseOfProceedsChange(useOfProceeds: InputData): void;
    onFormTokenUpdate(token: string): void;
    amountRequested: InputData;
    useOfProceeds: InputData;
    formToken: string;
    activeOwnerIndex: number;
    clientAppConfig: ClientAppConfig;
}


class OwnerInfoForm extends React.Component<OwnerInfoFormProps, OwnerInfoFormState> {

    constructor(props: OwnerInfoFormProps) {
        super(props);

        this.handleBasicInputChange = this.handleBasicInputChange.bind(this);
        this.handleAmountRequestedChange = this.handleAmountRequestedChange.bind(this);

        this.handleFormTokenUpdate = this.handleFormTokenUpdate.bind(this);

        this.handleFirstGuessConfirmClickYes = this.handleFirstGuessConfirmClickYes.bind(this);
        this.handleFirstGuessConfirmClickNo = this.handleFirstGuessConfirmClickNo.bind(this);

        this.handleEachOwnerContinueClick = this.handleEachOwnerContinueClick.bind(this);
        this.handleEachOwnerBackClick = this.handleEachOwnerBackClick.bind(this);
        this.handlePermissionToContactTermsChange = this.handlePermissionToContactTermsChange.bind(this);
        this.handleUseOfProceedsChange = this.handleUseOfProceedsChange.bind(this);
        let state = new OwnerInfoFormState();

        let eachOwnerFormInfos: EachOwnerFormInfo[] = [];
        let ownerInfos = new OwnerInfo();


        let email = WindowUtils.getParam('emailAddress');
        if (email) {
            let emailInput = new InputData();
            emailInput.label = 'Email Address';
            emailInput.name = 'emailAddress';
            emailInput.value = email;
            ownerInfos.setDataByName(emailInput);

        }


        let homeZip = WindowUtils.getParam('zipCode');

        if (homeZip) {
            let zipCodeInput = new InputData();
            zipCodeInput.label = 'Zip Code';
            zipCodeInput.name = 'homeZip';
            zipCodeInput.value = homeZip;
            ownerInfos.setDataByName(zipCodeInput);
        }


        let mobilePhone = WindowUtils.getParam('mobilePhone');
        if (mobilePhone) {
            let mobilePhoneInput = new InputData();
            mobilePhoneInput.label = 'Mobile Phone';
            mobilePhoneInput.name = 'mobilePhone';
            mobilePhoneInput.value = mobilePhone;
            ownerInfos.setDataByName(mobilePhoneInput);
        }


        let amountRequested = WindowUtils.getParam('amountRequested');
        let amountRequestedInput = new InputData();
        amountRequestedInput.label = 'Amount Requested';
        amountRequestedInput.name = 'amountRequested';
        if (amountRequested) {

            amountRequestedInput.value = amountRequested;
            state.amountRequested = amountRequestedInput;
        }

        let useOfFunds = WindowUtils.getParam('useOfFunds');
        let useOfFundsInput = new InputData();
        useOfFundsInput.label = 'Use of Funds';
        useOfFundsInput.name = 'useOfProceeds';
        if (useOfFunds) {

            useOfFundsInput.value = useOfFunds;
            state.useOfProceeds = useOfFundsInput;
        }

        let eo = new EachOwnerFormInfo();
        eo.ownerInfo = ownerInfos;

        eachOwnerFormInfos.push(eo);
        state.activeOwnerIndex = this.props.activeOwnerIndex;


        state.ownerInfos = eachOwnerFormInfos;

        for (let i in props.clientAppConfig.permissionToContactTerms) {
            state.ownerInfos[0].permissionToContactTerms[i] = false;
        }

        state.ownerInfos[0].amountRequested = amountRequestedInput;
        state.ownerInfos[0].useOfProceeds = useOfFundsInput;

        this.state = state;

        this.props.onAmountRequestedChange(amountRequestedInput);
        this.props.onUseOfProceedsChange(useOfFundsInput);

    }

    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        if (this.props.formToken === 'new') {
            this.setState({
                currentScreen: CurrentScreen.Owner
            });
            return;
        }

        openLoader(true);

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/owner')
            .then((r) => {

                let ownerInfosAsData = r.data.data.ownerInfos;
                let currentScreen = r.data.data.currentScreen;

                this.refreshFormData(ownerInfosAsData, currentScreen);

            }).finally(() => {
                openLoader(false);
            });

    }

    refreshFormData(ownerInfosAsData: any, currentScreen: any): void {

        let ownerInfos: EachOwnerFormInfo[] = [];

        for (var i in ownerInfosAsData) {

            let eo = new EachOwnerFormInfo();
            eo.firstGuess = ownerInfosAsData[i].firstGuess;
            eo.amountRequested = ownerInfosAsData[i].amountRequested;
            eo.ownerInfo = new OwnerInfo(ownerInfosAsData[i].ownerInfo.inputs);
            eo.useOfProceeds = ownerInfosAsData[i].useOfProceeds;

            for (var j in this.props.clientAppConfig.permissionToContactTerms) {
                eo.permissionToContactTerms[j] = false;
            }

            ownerInfos[Number(i)] = eo;
        }


        this.setState({
            ownerInfos: ownerInfos,
            // currentScreen: !currentScreen ? CurrentScreen.Owner : currentScreen,
            currentScreen: CurrentScreen.Owner,
            amountRequested: ownerInfos[0].amountRequested,
            useOfProceeds: ownerInfos[0].useOfProceeds
        });
    }

    storeOwnerInfo(data: EachOwnerFormInfo): Promise<HttpClientResponse> {

        let s = _.clone(data);
        s.permissionToContactTermsArray = this.props.clientAppConfig.permissionToContactTerms;

        return httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/owner', s);
    }

    handlePermissionToContactTermsChange(e: React.ChangeEvent<HTMLInputElement>) {

        let ownerInfos = _.clone(this.state.ownerInfos);
        ownerInfos[this.state.activeOwnerIndex].permissionToContactTerms[parseInt(e.currentTarget.getAttribute('data-permission-to-contact-index') || '0')]
            = e.currentTarget.checked;

        this.setState({
            ownerInfos: ownerInfos
        });
    }

    handleEachOwnerContinueClick() {

        openLoader(true);

        switch (this.getCurrentStep()) {
            case EachOwnerInternalSteps.Start:


                this.storeOwnerInfo(this.state.ownerInfos[this.state.activeOwnerIndex])
                    .then((r) => {
                        let token = r.data.token;
                        let currentScreen = r.data.data.ownerInfoFormData.currentScreen;
                        if (this.props.formToken === 'new') {
                            currentScreen = CurrentScreen.Initial
                        }
                        this.props.onFormTokenUpdate(token);
                        let ownerInfosAsData = r.data.data.ownerInfoFormData.ownerInfos;


                        this.refreshFormData(ownerInfosAsData, currentScreen);
                    })
                    .catch((r) => {

                    }).finally(() => {
                        openLoader(false);
                    });
                break;
            case EachOwnerInternalSteps.Followup:

                this.storeOwnerInfo(this.state.ownerInfos[this.state.activeOwnerIndex])
                    .then((r) => {

                        // httpClient.post(FORMAPI_BASE + '/' + this.props.formToken + '/owner-form-screen?currentScreen=' + CurrentScreen.Owner);

                        // let totalPercent = this.getTotalPercent();

                        this.props.onCompleteForm();
                        return;

                        // if (totalPercent >= 51) {
                        //     this.props.onCompleteForm();
                        //     return;
                        // }

                        // this.setState({
                        //     currentScreen: CurrentScreen.Interstitial
                        // })
                    })
                    .catch((r) => {

                    })
                    .finally(() => {
                        openLoader(false);
                    });
                break;
        }
    }

    handleAmountRequestedChange(amountRequested: InputData) {

        let ownerInfos = _.clone(this.state.ownerInfos);
        ownerInfos[this.state.activeOwnerIndex].amountRequested = amountRequested;

        this.setState({
            amountRequested: amountRequested,
            ownerInfos: ownerInfos
        });
        this.props.onAmountRequestedChange(amountRequested);
    }

    handleUseOfProceedsChange(useOfProceeds: InputData) {

        let ownerInfos = _.clone(this.state.ownerInfos);
        ownerInfos[this.state.activeOwnerIndex].useOfProceeds = useOfProceeds;

        this.setState({
            useOfProceeds: useOfProceeds,
            ownerInfos: ownerInfos
        });
        this.props.onUseOfProceedsChange(useOfProceeds);
    }

    handleBasicInputChange(input: InputData): void {

        let ownerInfos2 = _.clone(this.state.ownerInfos);
        ownerInfos2[this.state.activeOwnerIndex].ownerInfo.setDataByName(input);

        this.setState({
            ownerInfos: ownerInfos2
        });

    }

    handleFormTokenUpdate(token: string): void {
        this.props.onFormTokenUpdate(token);
    }

    getCurrentStep(): number {
        let currentEO = this.state.ownerInfos[this.state.activeOwnerIndex];
        return currentEO.getCurrentStep();
    }

    handleEachOwnerBackClick(): void {

        let ownerInfos = _.clone(this.state.ownerInfos);
        let currentEO = this.state.ownerInfos[this.state.activeOwnerIndex];

        switch (currentEO.getCurrentStep()) {
            case EachOwnerInternalSteps.Start:

                break;
            case EachOwnerInternalSteps.Followup:

                let fg = ownerInfos[this.state.activeOwnerIndex].firstGuess;
                ownerInfos[this.state.activeOwnerIndex].firstGuess.confirmed = GuessConfirmed.Unknown;
                if (!fg.found) {
                    ownerInfos[this.state.activeOwnerIndex].firstGuess.performed = false;
                }

                this.setState({
                    ownerInfos: ownerInfos
                });

                break;

            default:
                return;
        }
    }

    private getInputData(name: string, value: string): InputData {
        return InputData.newObj(name, value, FORM_PROPERTY_LIST[name].label);
    }

    getFoundAsInputData(firstGuess: EachOwnerFirstGuess): {
        [s: string]: InputData
    } {

        let ret: {
            [s: string]: InputData
        } = {};

        ret[OwnerFormInputNames.firstName] = this.getInputData(OwnerFormInputNames.firstName, firstGuess.resultInfo.firstName);
        ret[OwnerFormInputNames.lastName] = this.getInputData(OwnerFormInputNames.lastName, firstGuess.resultInfo.lastName);

        let addressStr = [];
        if (firstGuess.resultInfo.streetLine1 !== null && firstGuess.resultInfo.streetLine1.trim() !== '') {
            addressStr.push(firstGuess.resultInfo.streetLine1);
        }
        if (firstGuess.resultInfo.streetLine2 !== null && firstGuess.resultInfo.streetLine2.trim() !== '') {
            addressStr.push(firstGuess.resultInfo.streetLine2);
        }
        ret[OwnerFormInputNames.address] = this.getInputData(OwnerFormInputNames.address, addressStr.join(", "));

        return ret;
    }

    clearFoundAsInputData(): {
        [s: string]: InputData
    } {

        let ret: {
            [s: string]: InputData
        } = {};

        ret[OwnerFormInputNames.firstName] = this.getInputData(OwnerFormInputNames.firstName, '');
        ret[OwnerFormInputNames.lastName] = this.getInputData(OwnerFormInputNames.lastName, '');
        ret[OwnerFormInputNames.address] = this.getInputData(OwnerFormInputNames.address, '');

        return ret;
    }

    handleFirstGuessConfirmClickYes() {

        let ownerInfos = _.clone(this.state.ownerInfos);

        ownerInfos[this.state.activeOwnerIndex].firstGuess.confirmed = GuessConfirmed.Yes;

        let inputDatas = this.getFoundAsInputData(ownerInfos[this.state.activeOwnerIndex].firstGuess);

        for (var i in inputDatas) {
            let inputData = inputDatas[i];
            ownerInfos[this.state.activeOwnerIndex].ownerInfo.setDataByName(inputData);
        }

        openLoader(true);

        this.storeOwnerInfo(ownerInfos[this.state.activeOwnerIndex])
            .then((r) => {
                let ownerInfosAsData = r.data.data.ownerInfoFormData.ownerInfos;
                let currentScreen = r.data.data.ownerInfoFormData.currentScreen;

                this.refreshFormData(ownerInfosAsData, currentScreen);
                this.setState({
                    fullEdit: false
                });
            })
            .finally(() => {
                openLoader(false);
            });

    }

    handleFirstGuessConfirmClickNo() {

        let ownerInfos = _.clone(this.state.ownerInfos);

        ownerInfos[this.state.activeOwnerIndex].firstGuess.confirmed = GuessConfirmed.No;

        let inputDatas = this.clearFoundAsInputData();

        for (var i in inputDatas) {
            let inputData = inputDatas[i];
            ownerInfos[this.state.activeOwnerIndex].ownerInfo.setDataByName(inputData);
        }

        this.storeOwnerInfo(ownerInfos[this.state.activeOwnerIndex])
            .then((r) => {
                let ownerInfosAsData = r.data.data.ownerInfoFormData.ownerInfos;
                let currentScreen = r.data.data.ownerInfoFormData.currentScreen;

                this.refreshFormData(ownerInfosAsData, currentScreen);
                this.setState({
                    fullEdit: false
                });
            })
            .finally(() => {
                openLoader(false);
            });

    }



    getTotalPercent(): number {

        let totalPercent = 0;

        for (let i in this.state.ownerInfos) {

            let ownerInfo = this.state.ownerInfos[i].ownerInfo;

            totalPercent += parseFloat(ownerInfo.getDataByName(OwnerFormInputNames.ownershipPercent).value);

        }

        return totalPercent;
    }

    render() {

        switch (this.state.currentScreen) {

            case CurrentScreen.Initial:
                return <React.Fragment>
                    <LinearProgress></LinearProgress>
                </React.Fragment>;

            case CurrentScreen.Owner:
                return (
                    <EachOwnerForm
                        key={this.state.activeOwnerIndex}
                        ownerIndex={this.state.activeOwnerIndex}
                        onBasicInputChange={this.handleBasicInputChange}
                        onAmountRequestedChange={this.handleAmountRequestedChange}
                        onUseOfProceedsChange={this.handleUseOfProceedsChange}
                        onBackClick={this.handleEachOwnerBackClick}
                        formToken={this.props.formToken}
                        eachOwnerFormInfo={this.state.ownerInfos[this.state.activeOwnerIndex]}
                        onFirstGuessConfirmClickYes={this.handleFirstGuessConfirmClickYes}
                        onFirstGuessConfirmClickNo={this.handleFirstGuessConfirmClickNo}
                        onContinueClick={this.handleEachOwnerContinueClick}
                        fullEdit={this.state.fullEdit}
                        clientAppConfig={this.props.clientAppConfig}
                        handlePermissionToContactTermsChange={this.handlePermissionToContactTermsChange}
                    ></EachOwnerForm>
                );


            default:
                return (
                    <React.Fragment>
                        Error
                    </React.Fragment>
                );
        }


    }

}

export default withTheme(withStyles(styles)(OwnerInfoForm));