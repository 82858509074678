import React from "react";
import { WithStyles, Theme, createStyles, withStyles, withTheme, Typography, Button, Grid, LinearProgress, Card, CardHeader, CardContent } from "@material-ui/core";
import { PersonAdd, Add } from '@material-ui/icons';
import EachOwnerForm, { EachOwnerInternalSteps, OWNER_FORM_PROPERTY_ARRAY, OwnerFormInputNames } from "./owner/EachOwnerForm";
import { InputData } from "../../../util/ui/form/InputData";
import httpClient from "../../../util/HttpClient";

import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { AbstractInfo } from "./AbstractInfo";
import { openLoader } from "../../../util/ui/dialog/loader/Loader";
import { ErrorInfo } from "../../../util/ui/form/ErrorInfo";
import validator from "validator";
import Questions, { Answers, QUESTION_LIST } from "./declarations/Questions";
import { AdditionalBusinessInfo, ADDENDUMA_FORM_PROPERTY_ARRAY, AddendumAItem } from "./addendums/AddendumAItem";
import { AddendumBInfo, valueNameAddendumB, AddendumB } from "./addendums/AddendumB";
import { FORMAPI_BASE } from "../BaseUtils";

const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    interstitialContainer: {
        justifyContent: 'center',
    },
    headerWarningGridItem: {
        textAlign: 'right'
    }
});


export class OwnerInfo extends AbstractInfo {

}

enum CurrentScreen {
    Initial = -1, Owner = 0
}

class OwnerInfoFormState {


    ownerInfos: EachOwnerFormInfo[] = [];
    currentScreen: number = CurrentScreen.Initial;
    answerInfo: AnswerInfo = new AnswerInfo();

    addendumBInfoErrorFields: Map<string, ErrorInfo> = new Map();
    addendumAInfo: AddendumAInfo = new AddendumAInfo();
    addendumBInfo: AddendumBInfo = new AddendumBInfo();
}

export class AnswerInfo {
    answers: Answers = new Answers();
    errorFields: Map<string, ErrorInfo> = new Map();
}

export class EachOwnerFormInfo {

    ownerInfo: OwnerInfo = new OwnerInfo();
    errorFields: Map<string, ErrorInfo> = new Map();

    getCurrentStep(): number {

        return EachOwnerInternalSteps.Start;
    }
}


class EachAddendumAInfo {
    businessInfo: AdditionalBusinessInfo = new AdditionalBusinessInfo();
    errorFields: Map<string, ErrorInfo> = new Map();
}

class AddendumAInfo {
    addendumItems: EachAddendumAInfo[] = [];
}


interface OwnerInfoFormProps extends WithStyles<typeof styles> {
    onCompleteForm(): void;
    onGoBack(): void;
    formToken: string;
}


class OwnerInfoForm extends React.Component<OwnerInfoFormProps, OwnerInfoFormState> {

    protected amountRequested: InputData = new InputData();

    constructor(props: OwnerInfoFormProps) {
        super(props);

        this.handleAddOwnerClick = this.handleAddOwnerClick.bind(this);
        this.handleOwnerRemove = this.handleOwnerRemove.bind(this);

        this.handleOwnerInputChange = this.handleOwnerInputChange.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);

        this.handleAddendumAChangeLine = this.handleAddendumAChangeLine.bind(this);
        this.handleAddendumAAddLine = this.handleAddendumAAddLine.bind(this);
        this.handleAddendumARemoveLine = this.handleAddendumARemoveLine.bind(this);
        this.handleAddendumBInfoChange = this.handleAddendumBInfoChange.bind(this);

        let state = new OwnerInfoFormState();

        let eachOwnerFormInfos: EachOwnerFormInfo[] = [];
        eachOwnerFormInfos.push(new EachOwnerFormInfo());

        state.ownerInfos = eachOwnerFormInfos;

        this.state = state;

    }

    componentDidMount() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        openLoader(true);

        httpClient.get(FORMAPI_BASE + '/' + this.props.formToken + '/owner')
            .then((r) => {


                let a = null;
                if (r.data.data.answerInfo === null) {
                    a = new AnswerInfo();
                } else {
                    a = r.data.data.answerInfo;
                }
                this.refreshFormData(r.data.data.ownerInfos, a, r.data.data.addendumAInfo?.addendumItems, r.data.data.addendumBInfo);

            }).finally(() => {
                openLoader(false);
            });

        this.setState({
            currentScreen: CurrentScreen.Owner
        })
    }


    handleAddendumBInfoChange(input: InputData) {
        let errorFields = _.clone(this.state.addendumBInfoErrorFields);

        if (errorFields.has(input.name)) {
            errorFields.delete(input.name);
            this.setState({
                addendumBInfoErrorFields: errorFields
            });
        }

        let addB = _.clone(this.state.addendumBInfo);
        addB.setDataByName(input);
        this.setState({
            addendumBInfo: addB
        });
    }

    handleAddendumAChangeLine(index: number, inputData: InputData) {

        let ae = _.clone(this.state.addendumAInfo);

        if (ae.addendumItems[index]?.errorFields?.has(inputData.name)) {
            ae.addendumItems[index]?.errorFields.delete(inputData.name);
            this.setState({
                addendumAInfo: ae
            });
        }

        let addA = ae;
        addA.addendumItems[index].businessInfo.setDataByName(inputData);
        this.setState({
            addendumAInfo: addA
        });
    }


    handleAddendumAAddLine() {
        let addA = _.clone(this.state.addendumAInfo);
        addA.addendumItems.push(new EachAddendumAInfo());
        this.setState({
            addendumAInfo: addA
        });
    }

    handleAddendumARemoveLine(index: number) {
        let addA = _.clone(this.state.addendumAInfo);
        addA.addendumItems.splice(index, 1);
        this.setState({
            addendumAInfo: addA
        });
    }

    refreshFormData(ownerInfosAsData: any, a: any, addendumAInfo1: any, addendumBInfo: any): void {

        let answers = a.answers;

        let ownerInfos: EachOwnerFormInfo[] = [];

        for (var i in ownerInfosAsData) {

            let eo = new EachOwnerFormInfo();

            let inputs = [];
            for (var j in ownerInfosAsData[i].ownerInfo.inputs) {
                inputs.push(ownerInfosAsData[i].ownerInfo.inputs[j]);
            }

            eo.ownerInfo = new OwnerInfo(inputs);

            ownerInfos.push(eo);
        }

        if (answers === null) {
            answers = new Answers();
        }

        let inputs = [];
        for (var jj in answers.inputs) {
            inputs.push(answers.inputs[jj]);
        }

        if (ownerInfos.length === 0) {
            ownerInfos.push(new EachOwnerFormInfo());
        }

        let answerInfo = new AnswerInfo();
        answerInfo.answers = new Answers(inputs);

        let addendumAInfo = new AddendumAInfo();
        if (typeof addendumAInfo1 === 'undefined' || addendumAInfo1 === null) {
            addendumAInfo.addendumItems.push(new EachAddendumAInfo());
        } else {
            for (var ii in addendumAInfo1) {
                let ea = new EachAddendumAInfo();
                ea.businessInfo = new AdditionalBusinessInfo(addendumAInfo1[ii].businessInfo.inputs);
                addendumAInfo.addendumItems.push(ea);
            }
        }

        this.setState({
            ownerInfos: ownerInfos,
            answerInfo: answerInfo,
            addendumAInfo: addendumAInfo,
            addendumBInfo: new AddendumBInfo(addendumBInfo ? addendumBInfo.inputs : [])
        });
    }


    validateAddendumA() {


        let requiredAddendumA: boolean = this.state.answerInfo.answers?.getDataValueByName('question-3') === 'Yes';

        if (!requiredAddendumA) {
            return true;
        }

        let singleAddendumFailed: boolean = false;

        let addendumInfo = _.cloneDeep(this.state.addendumAInfo);

        for (var k in addendumInfo.addendumItems) {

            let businessInfo = addendumInfo.addendumItems[k].businessInfo;

            let errors = new Map<string, ErrorInfo>();

            for (var i in ADDENDUMA_FORM_PROPERTY_ARRAY) {

                let name = ADDENDUMA_FORM_PROPERTY_ARRAY[i].name;
                let value = businessInfo.getDataByName(name)?.value || '';
                let required = ADDENDUMA_FORM_PROPERTY_ARRAY[i].required;

                if (required && value === '') {
                    errors.set(name, new ErrorInfo(true));
                }

            }

            addendumInfo.addendumItems[k].errorFields = errors;
            if (errors.size > 0) {
                singleAddendumFailed = true;
            }
        }

        this.setState({
            addendumAInfo: addendumInfo
        })

        return !singleAddendumFailed;
    }

    validateAddendumB(): boolean {

        if (this.state.addendumBInfo?.getDataByName(valueNameAddendumB) == null || this.state.addendumBInfo?.getDataByName(valueNameAddendumB)?.value === '') {
            let errors = new Map<string, ErrorInfo>();
            errors.set(valueNameAddendumB, new ErrorInfo(true));
            this.setState({
                addendumBInfoErrorFields: errors
            });
            return false;
        }

        return true;
    }

    handleBackClick(): void {

        this.props.onGoBack();

    }

    validateAll(): boolean {
        return this.validateOwnerForms() && this.validateQuestionForm() && this.validateAddendumA() && this.validateAddendumB();
    }

    handleNextClick(): void {


        if (!this.validateAll()) {
            return;
        }

        openLoader(true);

        httpClient.post(FORMAPI_BASE + '/' + this.props.formToken
            + '/all-owners', this.state)
            .then((r) => {

                this.props.onCompleteForm();
            })
            .catch((r) => {

            }).finally(() => {
                openLoader(false);
            })

    }


    validateOwnerForms(): boolean {

        let singleOwnerFailed: boolean = false;

        let ownerInfos = _.cloneDeep(this.state.ownerInfos);

        for (var k in ownerInfos) {

            let ownerInfo = ownerInfos[k].ownerInfo;

            let errors = new Map<string, ErrorInfo>();

            for (var i in OWNER_FORM_PROPERTY_ARRAY) {

                let name = OWNER_FORM_PROPERTY_ARRAY[i].name;
                let value = ownerInfo.getDataByName(name)?.value || '';
                let required = OWNER_FORM_PROPERTY_ARRAY[i].required;

                if (required && value === '') {
                    errors.set(name, new ErrorInfo(true));
                }

                switch (name) {

                    case OwnerFormInputNames.homeZip:
                        if (!validator.isPostalCode(value, 'US')) {
                            errors.set(name, new ErrorInfo(true));
                        }
                        break;
                    case OwnerFormInputNames.ownershipPercent:
                        if (!validator.isNumeric(value)) {
                            errors.set(name, new ErrorInfo(true));
                        } else if (parseInt(value) > 100) {
                            errors.set(name, new ErrorInfo(true));
                        }
                        break;

                }
            }

            ownerInfos[k].errorFields = errors;
            if (errors.size > 0) {
                singleOwnerFailed = true;
            }
        }

        this.setState({
            ownerInfos: ownerInfos
        })

        return !singleOwnerFailed;

    }

    handleAddOwnerClick(): void {

        let ownerInfos = _.clone(this.state.ownerInfos);
        ownerInfos.push(new EachOwnerFormInfo());

        this.setState({
            ownerInfos: ownerInfos
        });

    }


    handleOwnerInputChange(inputInfo: InputData, index: number): void {
        let ownInfos = _.clone(this.state.ownerInfos);
        ownInfos[index].ownerInfo.setDataByName(inputInfo);
        ownInfos[index].errorFields.set(inputInfo.name, new ErrorInfo(false));
        this.setState({
            ownerInfos: ownInfos
        });
    }

    handleOwnerRemove(index: number): void {
        let ownInfos = _.clone(this.state.ownerInfos);
        ownInfos.splice(index, 1);
        this.setState({
            ownerInfos: ownInfos
        });
    }

    handleAnswerChange(input: InputData) {

        let answerInfo = _.clone(this.state.answerInfo);
        if (answerInfo.errorFields.has(input.name)) {
            answerInfo.errorFields.delete(input.name);
        }

        let inputData = new InputData();
        inputData.name = input.name;
        inputData.label = input.label;
        inputData.value = input.value;

        answerInfo.answers.setDataByName(inputData);

        this.setState({
            answerInfo: answerInfo
        });
    }


    validateQuestionForm(): boolean {

        let errors = new Map<string, ErrorInfo>();

        for (var i in QUESTION_LIST) {

            let name = QUESTION_LIST[i].name;
            let value = this.state.answerInfo.answers.getDataByName(name)?.value || '';

            if (QUESTION_LIST[i].required && value === '') {
                errors.set(name, new ErrorInfo(true));
            }

        }

        let answerInfo = _.clone(this.state.answerInfo);
        answerInfo.errorFields = errors;

        this.setState({
            answerInfo: answerInfo
        });

        return !(errors.size > 0);
    }

    render() {

        let requiredAddendumA: boolean = this.state.answerInfo.answers?.getDataValueByName('question-3') === 'Yes';
        let requiredAddendumB: boolean = this.state.answerInfo.answers?.getDataValueByName('question-4') === 'Yes';

        switch (this.state.currentScreen) {

            case CurrentScreen.Initial:
                return <React.Fragment>
                    <LinearProgress></LinearProgress>
                </React.Fragment>;

            case CurrentScreen.Owner:

                return (
                    <React.Fragment>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Business Owners Information
                                </Typography>

                                <Alert severity='info' >
                                    List all owners of 20% or more of the equity of the Applicant.
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>

                                {this.state.ownerInfos.map((owner, index) => {

                                    return <React.Fragment key={index}>
                                        <EachOwnerForm
                                            index={index}
                                            isDeletable={this.state.ownerInfos.length > 1}
                                            onBasicInputChange={this.handleOwnerInputChange}
                                            errorFields={owner.errorFields}
                                            eachOwnerFormInfo={this.state.ownerInfos[index]}
                                            onRemoveOwner={this.handleOwnerRemove} >
                                        </EachOwnerForm>
                                        {index !== (this.state.ownerInfos.length - 1) && <br />}
                                    </React.Fragment>;

                                })}

                            </Grid>

                            <Grid item xs={12} >
                                <Button fullWidth variant='contained' color='secondary' onClick={this.handleAddOwnerClick}>
                                    <PersonAdd></PersonAdd> &nbsp;Add Additional Owner
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item>
                                <Questions
                                    data={this.state.answerInfo.answers}
                                    errorFields={this.state.answerInfo.errorFields}
                                    onBasicInputChange={this.handleAnswerChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {
                                requiredAddendumA &&
                                <React.Fragment>
                                    <Grid item xs={12}>

                                        <Card variant='elevation' elevation={0}>
                                            <CardHeader style={{ paddingBottom: '0' }} title="Addendum A - Additional Businesses" subheader='List all business where Applicant or any owner of the Applicant is an owner, or has common management'></CardHeader>
                                            <CardContent>

                                                <Grid container spacing={2}>
                                                    {this.state.addendumAInfo.addendumItems.map((ea, index) => {

                                                        return <React.Fragment key={index}>
                                                            <Grid item xs={12}>
                                                                <AddendumAItem
                                                                    isDeletable={index > 0}
                                                                    index={index}
                                                                    businessInfo={ea.businessInfo}
                                                                    errorFields={ea.errorFields}
                                                                    onChange={this.handleAddendumAChangeLine}
                                                                    onRemoveAddendumItem={this.handleAddendumARemoveLine}
                                                                />
                                                            </Grid>
                                                        </React.Fragment>;
                                                    })}

                                                    <Grid item xs={12} >
                                                        <Button fullWidth variant='contained' color='secondary' onClick={this.handleAddendumAAddLine}>
                                                            <Add></Add> &nbsp; Additional Business
                                                    </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                    </Grid>

                                </React.Fragment>

                            }
                            {
                                requiredAddendumB &&
                                <React.Fragment>
                                    <Grid item xs={12}>

                                        <Card variant='elevation' elevation={0}>
                                            <CardHeader style={{ paddingBottom: '0', paddingTop: '0' }} title="Addendum B - Additional Businesses"
                                                subheader='Provide details of the SBA Economic Injury Disaster Loan received between January 31, 2020 and April 3, 2020 '></CardHeader>
                                            <CardContent>

                                                <AddendumB
                                                    errorFields={this.state.addendumBInfoErrorFields}
                                                    addendumBInfo={this.state.addendumBInfo}
                                                    onChange={this.handleAddendumBInfoChange}
                                                />
                                            </CardContent>
                                        </Card>

                                    </Grid>

                                </React.Fragment>
                            }
                        </Grid>
                        {
                            !(requiredAddendumA || requiredAddendumB) &&
                            <React.Fragment>
                                <br /><br />
                            </React.Fragment>
                        }
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                <Button variant='contained' color='default' onClick={this.handleBackClick} fullWidth>
                                    Back
                                </Button>
                            </Grid>

                            <Grid item xs={6} >
                                <Button variant='contained' color='primary' onClick={this.handleNextClick} fullWidth>
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );

            default:
                return (
                    <React.Fragment>
                        Error
                    </React.Fragment>
                );
        }


    }

}

export default withTheme(withStyles(styles)(OwnerInfoForm));