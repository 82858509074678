import React from 'react';
import { EachOwnerFormInfo } from '../OwnerInfoForm';
import { BusinessInfo, BusinessInputFormNames } from '../BusinessInfoForm';

import { CategoryUpload, UploadSection, uploadSectionsERC } from '../../../../util/DocumentUtils';
import { Connection } from '../BankInfoForm';
import { CardHeader, Card, CardContent, Button, Theme, createStyles, WithStyles, withTheme, withStyles, Typography, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import StringUtils from '../../../../util/StringUtils';

import { OwnerFormInputNames } from '../owner/EachOwnerForm';
import UploadInput from '../../../../util/ui/form/UploadInput';
import { blue } from '@material-ui/core/colors';

export class ReviewApplicationInfo {

    ownerInfo: EachOwnerFormInfo = new EachOwnerFormInfo();
    businessInfo: BusinessInfo = new BusinessInfo();
    bankConnect: Connection[] = [];
    uploadedFiles: {
        [category: string]: CategoryUpload
    } = {};
}


const styles = (theme: Theme) => createStyles({

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        // display: 'none'
    },
    summaryList: {
        // width: '100%',
        // maxWidth: 360,
        // margin: 'auto'
    },
    continueAppButtonGridItem: {
        textAlign: 'right'
    },
    headerWarningGridItem: {
        textAlign: 'right'
    },
    firstGuessConfirmButton: {
        marginLeft: 0,
        margin: theme.spacing(2),
    },
    firstGuess: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    guessConfirmationContainer: {
        justifyContent: 'center'
    },
    sectionCardHeader: {
        // paddingTop: '10px',
        // paddingBottom: '0px'
        '&:hover': {
            background: blue[50],
            cursor: 'pointer'
        },
    },
    borderBottomWithSpace: {
        borderBottom: '1px solid #ccc',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    withoutBorderBottom: {
        borderBottom: 'none',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
});

interface ReviewApplicationProps extends WithStyles<typeof styles> {

    data: ReviewApplicationInfo;
    formToken: string;
    ownerIndex: number;
    urlToken: string;

    onEditOwner(ownerIndex: number): void;
    onEditBusiness(): void;
    onEditDocuments(): void;
}

class BasicDisplay {
    name: string = '';
    width: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined = 6;

    static fromName(name: string, width: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined = 6): BasicDisplay {
        let o = new BasicDisplay();
        o.name = name;
        o.width = width;
        return o;
    }
}

let businessInfoDisplay: BasicDisplay[] =
    [
        BasicDisplay.fromName(BusinessInputFormNames.entityName),
        BasicDisplay.fromName(BusinessInputFormNames.amountRequested, 3),
        BasicDisplay.fromName(BusinessInputFormNames.useOfProceeds, 3),
        BasicDisplay.fromName(BusinessInputFormNames.entityDba),
        BasicDisplay.fromName(BusinessInputFormNames.phone, 3),
        //BasicDisplay.fromName(BusinessInputFormNames.totalErcClaimedRefund, 3),


        BasicDisplay.fromName(BusinessInputFormNames.address, 3),

        BasicDisplay.fromName(BusinessInputFormNames.stateOfIncorporation),

        BasicDisplay.fromName(BusinessInputFormNames.startDate, 3),

        BasicDisplay.fromName(BusinessInputFormNames.mailingAddress, 3),

        //BasicDisplay.fromName(BusinessInputFormNames.industryType, 6),
        //BasicDisplay.fromName(BusinessInputFormNames.sos, 6),
        BasicDisplay.fromName(BusinessInputFormNames.taxId),

        //BasicDisplay.fromName(BusinessInputFormNames.lengthOfCurrentOwnership, 3),
        //BasicDisplay.fromName(BusinessInputFormNames.entityType, 6),
        BasicDisplay.fromName(BusinessInputFormNames.website, 3)
    ];

let ownerInfoDisplay: BasicDisplay[] =
    [
        BasicDisplay.fromName(OwnerFormInputNames.firstName, 6),
        BasicDisplay.fromName(OwnerFormInputNames.businessTitle, 3),
        BasicDisplay.fromName(OwnerFormInputNames.ownershipPercent, 3),
        BasicDisplay.fromName(OwnerFormInputNames.address, 6),
        BasicDisplay.fromName(OwnerFormInputNames.countryResidence, 3),
        BasicDisplay.fromName(OwnerFormInputNames.mobilePhone, 3),
        BasicDisplay.fromName(OwnerFormInputNames.socialSecNum, 6),
        BasicDisplay.fromName(OwnerFormInputNames.countryCitizenship, 3),
        BasicDisplay.fromName(OwnerFormInputNames.dob, 3),
        BasicDisplay.fromName(OwnerFormInputNames.idType, 6),
        BasicDisplay.fromName(OwnerFormInputNames.idNumber, 3),
        BasicDisplay.fromName(OwnerFormInputNames.idIssueDate, 3),
        BasicDisplay.fromName(OwnerFormInputNames.idExpiryDate, 6),
        BasicDisplay.fromName(OwnerFormInputNames.bankAuthorized, 3),
    ];

let vendorInfoDisplay: BasicDisplay[] = [
    BasicDisplay.fromName(BusinessInputFormNames.vendorName, 6),
    BasicDisplay.fromName(BusinessInputFormNames.vendorPhone, 3),
    BasicDisplay.fromName(BusinessInputFormNames.vendorAddress, 3),
    BasicDisplay.fromName(BusinessInputFormNames.vendorCity, 6),
    BasicDisplay.fromName(BusinessInputFormNames.vendorState, 3),
    BasicDisplay.fromName(BusinessInputFormNames.vendorZip, 3),
    BasicDisplay.fromName(BusinessInputFormNames.vendorEquipmentDescription, 6),
    BasicDisplay.fromName(BusinessInputFormNames.vendorEquipmentAmount, 3),
    BasicDisplay.fromName(BusinessInputFormNames.vendorTermsRequested, 3),
    BasicDisplay.fromName(BusinessInputFormNames.vendorTitled, 6),
    BasicDisplay.fromName(BusinessInputFormNames.vendorType, 3)
];

export class ReviewApplication extends React.Component<ReviewApplicationProps> {

    constructor(props: ReviewApplicationProps) {
        super(props);
        this.handleOwnerClick = this.handleOwnerClick.bind(this);
    }

    handleOwnerClick(event: React.MouseEvent<HTMLButtonElement>): void {
        this.props.onEditOwner(parseInt(event.currentTarget.getAttribute('data-owner-index') || '0'));
    }

    render() {

        let uploadSections2: UploadSection[] = [];

        for (var i in uploadSectionsERC) {
            if (typeof this.props.data.uploadedFiles[uploadSectionsERC[i].category] !== 'undefined') {
                uploadSections2.push(uploadSectionsERC[i]);
            }
        }

        let data = this.props.data.ownerInfo.ownerInfo;

        return <React.Fragment>
            <Card elevation={0} variant='outlined'>

                {this.props.ownerIndex === 0 ?
                    <CardHeader
                        onClick={this.props.onEditBusiness}
                        title="Business"
                        action={<React.Fragment>

                            <Button color='primary' onClick={this.props.onEditBusiness}>
                                <Edit />
                            </Button>

                        </React.Fragment>}
                        className={this.props.classes.sectionCardHeader}></CardHeader>
                    :
                    <CardHeader
                        title="Business"></CardHeader>
                }
                <CardContent>
                    <Grid container spacing={2}>

                        {businessInfoDisplay.map((display, i) => {

                            let data = this.props.data.businessInfo;
                            let val = '';
                            let label = data.getDataByName(display.name) ? data.getDataByName(display.name).label : "";
                            if(!label) {
                                return <React.Fragment key={i}>
                                    <Grid item xs={12} sm={display.width}></Grid>
                                </React.Fragment>;
                            }
                            switch (display.name) {
                                // case BusinessInputFormNames.openMcaBalance:
                                // case BusinessInputFormNames.avgDailyBankBalance:
                                case BusinessInputFormNames.totalErcClaimedRefund:
                                case BusinessInputFormNames.amountRequested:
                                    val = StringUtils.formatCurrency(data.getDataByName(display.name).value);
                                    break;
                                case BusinessInputFormNames.address:
                                    val = data.getDataByName(display.name).value
                                        + ', ' + data.getDataValueByName(BusinessInputFormNames.city)
                                        + ', ' + data.getDataValueByName(BusinessInputFormNames.state)
                                        + ' ' + data.getDataValueByName(BusinessInputFormNames.zip)
                                    break;
                                case BusinessInputFormNames.mailingAddress:
                                    val = data.getDataByName(display.name).value
                                        + ', ' + data.getDataValueByName(BusinessInputFormNames.mailingCity)
                                        + ', ' + data.getDataValueByName(BusinessInputFormNames.mailingState)
                                        + ' ' + data.getDataValueByName(BusinessInputFormNames.mailingZip)
                                    break;
                                case BusinessInputFormNames.phone:
                                    val = StringUtils.formatPhone(data.getDataByName(display.name).value);
                                    break;
                                case BusinessInputFormNames.taxId:
                                    val = StringUtils.formatFein(data.getDataByName(display.name).value);
                                    break;
                                default:
                                    val = data.getDataByName(display.name).value;
                                    break;
                            }


                            return <React.Fragment key={i}>

                                <Grid item xs={12} sm={display.width}>
                                    <Typography gutterBottom variant='caption'>
                                        {label}
                                    </Typography>
                                    <Typography gutterBottom variant='body1'>
                                        {val}
                                    </Typography>
                                </Grid>

                            </React.Fragment>

                        })}
                    </Grid>
                </CardContent>
            </Card>
            <br />

            {this.props.data.businessInfo.vendors.length > 0 &&
                this.props.data.businessInfo.vendors.map((v, i) => {

                    if(Object.keys(v.inputs).length === 0) {
                        return <React.Fragment key={i}></React.Fragment>;
                    }

                    let title = 'Vendor Information' + (i > 0 ? ' #' + (i + 1) : '');

            return <React.Fragment key={i}>
                <Card elevation={0} variant='outlined' >
                        <CardHeader onClick={this.props.onEditBusiness}
                            title={title}
                            action={<React.Fragment>
                                <Button color='primary' onClick={this.props.onEditBusiness}>
                                    <Edit />
                                </Button>
                            </React.Fragment>}
                            className={this.props.classes.sectionCardHeader}>
                        </CardHeader>
                        <CardContent>

                                {/* display using vendorInfoDisplay */}

                            <Grid container spacing={2}>

                                {vendorInfoDisplay.map((display, i) => {

                                    let val = '';
                                    switch (display.name) {
                                        case BusinessInputFormNames.vendorEquipmentAmount:
                                            val = StringUtils.formatCurrency(v.getDataByName(display.name).value);
                                            break;
                                        case BusinessInputFormNames.vendorPhone:
                                            val = StringUtils.formatPhone(v.getDataByName(display.name).value);
                                            break;
                                        case BusinessInputFormNames.vendorAddress:
                                            val = v.getDataByName(display.name).value
                                                + ', ' + v.getDataValueByName(BusinessInputFormNames.vendorCity)
                                                + ', ' + v.getDataValueByName(BusinessInputFormNames.vendorState)
                                                + ' ' + v.getDataValueByName(BusinessInputFormNames.vendorZip)
                                            break;
                                        default:
                                            val = v.getDataByName(display.name).value;
                                            break;
                                    }

                                    return <React.Fragment key={i}>

                                        <Grid item xs={12} sm={display.width}>
                                            <Typography gutterBottom variant='caption'>
                                                {v.getDataByName(display.name)?.label}
                                            </Typography>
                                            <Typography gutterBottom variant='body1'>
                                                {val}
                                            </Typography>
                                        </Grid>

                                    </React.Fragment>
                                })}

                            </Grid>

                        </CardContent>
                </Card>
                <br></br>
            </React.Fragment>
        })}

            <br/>
            <React.Fragment>
                <Card elevation={0} variant='outlined' >
                    <CardHeader
                        title='Owner Information'
                        action={<React.Fragment>
                            <Button color='primary' onClick={this.handleOwnerClick}>
                                <Edit />
                            </Button>
                        </React.Fragment>}
                        className={this.props.classes.sectionCardHeader}></CardHeader>
                    <CardContent>
                        <Grid container spacing={2}>

                            {ownerInfoDisplay.map((display, i) => {

                                let val = '';
                                switch (display.name) {
                                    case OwnerFormInputNames.firstName:
                                        val = data.getDataByName(display.name).value
                                            + ' ' + data.getDataByName(OwnerFormInputNames.lastName).value
                                        break;
                                    case OwnerFormInputNames.address:
                                        val = data.getDataByName(display.name).value
                                            + ', ' + data.getDataByName(OwnerFormInputNames.homeCity).value
                                            + ', ' + data.getDataByName(OwnerFormInputNames.homeState).value
                                            + ' ' + data.getDataByName(OwnerFormInputNames.homeZip).value
                                        break;
                                    case OwnerFormInputNames.mobilePhone:
                                        val = StringUtils.formatPhone(data.getDataByName(display.name).value);
                                        break;
                                    case OwnerFormInputNames.ownershipPercent:
                                        val = (data.getDataByName(display.name).value) + '%';
                                        break;
                                    case OwnerFormInputNames.socialSecNum:
                                        val = StringUtils.formatSSN(data.getDataByName(display.name).value);
                                        break;
                                    case OwnerFormInputNames.dob:
                                    case OwnerFormInputNames.idExpiryDate:
                                    case OwnerFormInputNames.idIssueDate:
                                        val = StringUtils.formatDateFromMySQLToAmerican(data.getDataValueByName(display.name));
                                        break;
                                    default:
                                        val = data.getDataValueByName(display.name);
                                        break;
                                }

                                return <React.Fragment key={i}>

                                    <Grid item xs={12} sm={display.width}>
                                        <Typography gutterBottom variant='caption'>
                                            {data.getDataByName(display.name)?.label}
                                        </Typography>
                                        <Typography gutterBottom variant='body1'>
                                            {val}
                                        </Typography>
                                    </Grid>

                                </React.Fragment>
                            })}
                        </Grid>
                    </CardContent>
                </Card>

            </React.Fragment>
            <br />

            {this.props.data.bankConnect?.length > 0 &&
                <React.Fragment>

                    <Card variant='outlined'>
                        <CardHeader title='Bank Statements' subheader='Bank Accounts Connected to fetch Bank Statements'></CardHeader>
                        <CardContent>

                            <List >
                                {this.props.data.bankConnect.map((c, i) => {
                                    return <ListItem key={i}>
                                        <ListItemAvatar>
                                            <Avatar src={c.logo}>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={c.bankName} secondary={c.status !== 'FAILED' ? 'Connection: Good' : 'Connection: Failed'} />
                                    </ListItem>
                                })}

                            </List>

                        </CardContent>
                    </Card>

                </React.Fragment>
            }
            {uploadSections2.length > 0
                && <React.Fragment>
                    <Card variant="outlined">
                        <CardHeader title="Documents Uploaded" ></CardHeader>
                        <CardContent >
                            {uploadSections2.map((u, i) => {

                                return <UploadInput
                                    key={i}
                                    category={u.category}
                                    label={u.label}
                                    required={u.required}
                                    fileListUploaded={this.props.data.uploadedFiles[u.category]?.getFilenames()}
                                    error={false}
                                    formToken={this.props.formToken}
                                    viewOwnly={true}
                                    urlToken={this.props.urlToken}
                                >

                                </UploadInput>
                            })}
                        </CardContent>
                    </Card>
                    <br />
                </React.Fragment>
            }

        </React.Fragment>
    }
}

export default withTheme(withStyles(styles)(ReviewApplication));